import { gql } from "@apollo/client";

const GET_IDLE_REPORTS = gql`
  query Get_Idle_reports($_id: ID, $role: String, $workspace: [ID],$period: String) {
    getIdle_reports(_id: $_id, role: $role, workspace: $workspace,period: $period) {
      _id
      address
      Model
      serialNumber
      duration
      conso
      createdAt
      updatedAt
      workspace {
        name
      }
    }
  }
`;
const GETIDLE_REPORTSBYPERIOD = gql`
  query GetIdle_reportsByPeriod($period: String) {
    getIdle_reportsByPeriod(period: $period) {
      _id
      address
      Model
      serialNumber
      duration
      conso
      createdAt
      updatedAt
      workspace {
        name
      }
    }
  }
`;
export { GET_IDLE_REPORTS, GETIDLE_REPORTSBYPERIOD };
