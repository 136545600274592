import { gql } from "@apollo/client";

const ADD_GEOFENCE = gql`
  mutation CreateGeofence(
    $name: String
    $coordinates: [[Float]]
    $radius: Float
    $vehicle: [ID]
    $user: [ID]
    $type: String
    $typeshape: String
    $company: ID
    $role: String
    $condition: String
    $createdBy:ID
  ) {
    createGeofence(
      name: $name
      coordinates: $coordinates
      vehicle: $vehicle
      type: $type
      typeshape: $typeshape
      radius: $radius
      company: $company
      role: $role
      user: $user
      createdBy: $createdBy
      condition:$condition
    ) {
      _id
    }
  }
`;
export {
  ADD_GEOFENCE
};
