
export function convertToHoursMins(time, format = '%d h %d min') {
    if (time < 1) {
        return '0 h 0 min';
    }
    const hours = Math.floor(time / 60);
    const minutes = Math.floor(time % 60);
    return format.replace('%d', hours).replace('%d', minutes);
}
export function convertToDaysHoursMins(time, format = '%d %s %d h %d min') {
  if (time < 1) {
    return '0 minute(s)';
  }

  const days = Math.floor(time / 1440);
  const hours = Math.floor((time - (days * 1440)) / 60);
  const minutes = Math.round(time % 60);
  const lang = localStorage.getItem('language')==="en" ? "d":"j";
  let result = format.replace('%d', days).replace('%s', lang).replace('%d', hours).replace('%d', minutes);

  return result;
}
export function convertSecondsToHoursMins(timeInSeconds, format = '%d h %d min') {
    if (timeInSeconds < 60) {
        return '0 h 0 min';
    }
    const hours = Math.floor(timeInSeconds / 3600);
    const remainingSeconds = timeInSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    return format.replace('%d', hours).replace('%d', minutes);
}

export function convertSecondsToDynamicTime(seconds) {
  const lang = localStorage.getItem('language')==="en";
  const timeUnits = {
    " w": 604800,  
    " d": 86400,   
    " h": 3600,    
    " min": 60,      
    " s": 1        
  };
  const timeUnits2 = {
    " sem": 604800,  
    " j": 86400,   
    " h": 3600,    
    " min": 60,      
    " s": 1        
  };

  const timeParts = [];

  for (const [unit, secs] of Object.entries(lang ? timeUnits:timeUnits2)) {
    if (seconds >= secs) {
      const value = Math.floor(seconds / secs);
      timeParts.push(`${value}${unit}`);
      seconds %= secs;
    }
  }

  return timeParts.length > 0 ? timeParts.join(" ") : "0s";
}
