import { gql } from "@apollo/client";

const ADD_MARQUE = gql`
  mutation CreateMarque(
    $name: String!
    $company: ID!
    $role: String
  ) {
    createMarque( name: $name company: $company role: $role ) {
      _id
      name
    }
  }
`;
const UPDATE_MARQUE = gql`
  mutation updateMarque(
    $name: String!
    $_id: ID
    $role: String
  ) {
    updateMarque( name: $name _id: $_id role: $role ) {
      _id
      name
    }
  }
`;
const DELETE_MARQUE = gql`
  mutation deleteMarque(
    $_id: ID!
    $role: String
  ) {
    deleteMarque( _id: $_id  role: $role ) {
      _id
      name
    }
  }
`;

export { ADD_MARQUE , UPDATE_MARQUE, DELETE_MARQUE};
