import { gql } from "@apollo/client";

const ADD_COMPANY = gql`
  mutation createCompany($name: String!, $description: String!, $role: String) {
    createCompany(name: $name, description: $description, role: $role) {
      _id
    }
  }
`;
const UPDATE_COMPANY = gql`
  mutation updateCompany($name: String, $description: String, $role: String) {
    updateCompany(name: $name, description: $description, role: $role) {
      _id
      name
    }
  }
`;
const DELETE_COMPANY = gql`
  mutation deleteCompany($_id: ID!, $role: String) {
    deleteCompany(_id: $_id, role: $role) {
      _id
      name
    }
  }
`;

export { ADD_COMPANY, UPDATE_COMPANY, DELETE_COMPANY };
