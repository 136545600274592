import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { setDefaultConfig } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import dayjs from "dayjs";
import "dayjs/locale/fr";
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import frLan from './constants/fr-Lan'

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  lng: localStorage.getItem('language'),
  fallbackLng: 'fr',
  debug: true,
  detection: {
    order: ['queryString', 'cookie'],
    cache: ['cookie']
  },
  interpolation: {
    escapeValue: false
  }
});
dayjs.locale(localStorage.getItem('language')||'fr');
if (localStorage.getItem('language')==='fr') {
  setDefaultConfig({
    locale: frLan,
  })
} else {
  setDefaultConfig({
    locale: enUS,
  })
}

export default i18n