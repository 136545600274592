import React, { useRef } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  useMap,
  LayerGroup,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import "../../css/App.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import "../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import { GET_VEHICLE } from "../../queries/mapQuery";
import { useQuery } from "@apollo/client";
import { EditControl } from "react-leaflet-draw";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
export default function GeofenceMap(props) {

  const mapRef = useRef(null);
  const { loadingVeh, errorVeh, dataVeh } = useQuery(GET_VEHICLE, { variables: { _id: "641442ac2c8675e7de070307" }, });
  if (loadingVeh) return <p>Loading...</p>;
  if (errorVeh) return <p>Error: {errorVeh.message}</p>;
  
  console.log(dataVeh);

  function SearchControl() {
    {
      const map = useMap();
      const searchControl = L.control({ position: "topleft" });

      searchControl.onAdd = function () {
        const provider = new OpenStreetMapProvider();
        const search = new GeoSearchControl({
          provider: provider,
          style: "button",
          autoCompleteDelay: 250,
          showMarker: false,
          showPopup: false,
        });
        return search.onAdd(map);
      };
      if (map._controlCorners.topleft.childElementCount < 2)
        searchControl.addTo(map);
    }

    return null;
  }
 

  
  const handleCreate = (e) => {
    console.log(e.layerType);
    switch (e.layerType) {
      case "circle":
        props.creteshape(e.layerType, [Object.values(e.layer._latlng)],Math.round(e.layer._mRadius));
        break;
      case "polygon":
      case "rectangle":
        const coordinates = e.layer._latlngs[0].map((ele) => {
          return Object.values(ele);
        });
        props.creteshape(e.layerType, coordinates);
        console.log(
          e.layer._latlngs[0].map((ele) => {
            return Object.values(ele);
          })
        );

        break;
      default:
        break;
    }
  };
  return (
    <>
      <MapContainer
        updateInterval={100}
        ref={mapRef}
        center={[33.80917715531987, 10.994576183517939]}
        zoom={13}
        scrollWheelZoom={true}
        style={{ height: "400px", width: "100wh" }}
      >
        <LayersControl position="topright">
          <LayerGroup>
            <LayersControl.BaseLayer checked name="Simple">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Google Hybrid">
              <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Google Streets">
              <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
            </LayersControl.BaseLayer>
          </LayerGroup>
        </LayersControl>

        <SearchControl />
        <FeatureGroup>
          <EditControl
            position="topright"
            onCreated={(e) => {
              handleCreate(e);
            }}
            onDeleted={(e) => console.log(e)}
            draw={{
              rectangle: false,
              circle: false,
              circlemarker: false,
              marker: false,
              polyline: false,
              polygon: true,
            }}
            edit={{
              edit: false,
              remove: true,
            }}
          />
        </FeatureGroup>
      </MapContainer>
    </>
  );
}
