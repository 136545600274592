import { gql } from "@apollo/client";

const GET_UTILISATION = gql`
  query Get_Utilisation($vehicle: ID!) {
    getUtilisations(vehicle: $vehicle) {
      date
      utilisation
      utilisationKM
    }
  }
`;
const GET_LASTUTILISATION = gql`
  query Get_Lastutilisation($_id: ID!) {
    getLastUtilisation(_id: $_id) {
      date
      utilisation
      utilisationKM
      productiv
      ralentie
    }
  }
`;
const GET_UTILISATIONBYPERIOD = gql`
  query Get_UtilisationByPeriod($vehicle: ID!, $period: String) {
    getUtilisationByPeriod(vehicle: $vehicle, period: $period) {
      date
      utilisation
      utilisationKM
      productiv
      ralentie
    }
  }
`;
const GET_RALENTIESTATISTICSFORCOMPANY = gql`
  query Get_RalentieStatisticsForCompany($company: ID, $period: String) {
    getRalentieStatisticsForCompany(company: $company, period: $period) {
      vehicle
      profileInfo {
        serialNumber
        Model
      }
      totalRalentie
      totalUtilisation
      ralentiePercentage
      totalProd
    }
  }
`;
const GET_RALENTIESTATISTICSFORCHARTS = gql`
  query Get_RalentieStatisticsForCharts($company: ID, $period: String) {
    getRalentieStatisticsForCharts(company: $company, period: $period) {
      date
      totalRalentie
      ralentiePercentage
    }
  }
`;

export {
  GET_LASTUTILISATION,
  GET_UTILISATION,
  GET_UTILISATIONBYPERIOD,
  GET_RALENTIESTATISTICSFORCOMPANY,
  GET_RALENTIESTATISTICSFORCHARTS,
};
