import React, { useRef ,useEffect,useState} from "react";
import { MapContainer,TileLayer, Marker,Tooltip,useMap, LayerGroup,LayersControl,Popup,Polyline} from "react-leaflet";
import L from "leaflet";
import { Typography, Row,Col } from "antd";
import { EnvironmentOutlined,ClockCircleOutlined,FieldTimeOutlined, DashboardOutlined }from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons'
import "../../../node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "../../css/App.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import "../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import dayjs from "dayjs";
import Status from "../Multiple/Status";
import { convertToHoursMins } from "../../constants/ConvertHora";
import { useTranslation } from "react-i18next";
require("leaflet-routing-machine");
const { Text } = Typography;
export default function HistoriqueMap(props) {
  const svgContent = `<?xml version="1.0" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
 "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt"  viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2387 4265 c-236 -45 -456 -176 -602 -358 -319 -398 -287 -966 74
-1327 127 -127 275 -212 459 -262 64 -18 104 -22 242 -22 138 0 178 4 242 22
418 115 704 448 747 869 19 186 -14 365 -99 538 -130 264 -368 455 -655 526
-107 27 -304 34 -408 14z m343 -496 c160 -26 259 -135 268 -295 4 -73 1 -88
-23 -143 -32 -70 -93 -132 -163 -164 -41 -19 -68 -22 -224 -25 l-178 -4 0
-224 0 -225 -25 -24 c-30 -30 -54 -31 -89 -4 l-26 20 0 528 c0 490 1 529 18
543 9 9 26 18 37 21 39 9 341 7 405 -4z"/>
<path d="M2410 3460 l0 -170 161 0 c128 0 168 3 195 16 95 45 122 180 51 261
-49 56 -76 63 -252 63 l-155 0 0 -170z"/>
<path d="M2330 1526 c0 -633 0 -646 20 -666 19 -19 33 -20 210 -20 177 0 191
1 210 20 20 20 20 33 20 666 0 632 0 645 -19 639 -43 -13 -217 -22 -307 -14
-52 4 -104 11 -115 14 -19 6 -19 -7 -19 -639z"/>
</g>
</svg>`;
  const { t } = useTranslation();
  let trips_data = props.trips_data;
  let bearingsarr1 = props.bearingsarr1;
  const showTrips= props.showTrips;
  const mapRef = useRef(null);
const [point1, setpoint1] = useState([]);
  const [point2, setpoint2] = useState([]);
let allpoint=[]
  useEffect(() => {
    if (showTrips.length > 0 && trips_data.length > 0) {
    allpoint=[]
    showTrips.map((ele) => {
      allpoint = [...trips_data[ele].allpoint]
    })
    const sortedShowtrips = showTrips;
    setpoint1([trips_data[sortedShowtrips[0]].trips_data[0].latitude,trips_data[sortedShowtrips[0]].trips_data[0].longitude]);
    // setpoint2([trips_data[sortedShowtrips[sortedShowtrips.length - 1]][trips_data[sortedShowtrips[sortedShowtrips.length - 1]].length - 1].latitude,
    //   trips_data[sortedShowtrips[sortedShowtrips.length - 1]][trips_data[sortedShowtrips[sortedShowtrips.length - 1]].length - 1].longitude]);
    } else {
    console.log("okk");
    
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTrips]);
  function FitBounds() {
    const map = useMap();
    if (allpoint.length > 0  ) {
       const bounds = L.latLngBounds(allpoint);
       try {
         map.fitBounds(bounds);
       } catch (error) {
        console.log(error);
       }
     }
     return null;
   }
  function SearchControl() {
    {
      const map = useMap();
      const searchControl = L.control({ position: "topleft" });

      searchControl.onAdd = function () {
        const provider = new OpenStreetMapProvider();
        const search = new GeoSearchControl({
          provider: provider,
          style: "button",
          autoCompleteDelay: 250,
          showMarker: false,
          showPopup: false,
        });
        return search.onAdd(map);
      };
      if (map._controlCorners.topleft.childElementCount < 2)
        searchControl.addTo(map);
    }

    return null;
  }
  return (
    <>
      <MapContainer
        updateInterval={100}
        ref={mapRef}
        center={[51.505, -0.09]}
        zoom={1.5}
        zoomControl={false}
        scrollWheelZoom={true}
        className="HistoryMap"
      >
        <LayersControl position="topright">
          {/* <LayerGroup>
            <LayersControl.BaseLayer checked name="Simple">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
            </LayersControl.BaseLayer>
          </LayerGroup> */}
          <LayersControl.BaseLayer checked name="Google Hybrid">
            <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Google Streets">
            <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
          </LayersControl.BaseLayer>
        </LayersControl>

        <SearchControl />
        <FitBounds />
        {trips_data.length > 0 &&
          showTrips.map((elem, j) => {
            return trips_data[elem].trips_data.map((ele, i) => {
              var clas = "arrow";
              var bg_color = "#03C988";
              if (ele.vehicleStatus === "Inactive") {
                bg_color = "#FF005C";
                clas = "custom-marker";
              }
              if (ele.vehicleStatus === "Active" && i === 0) {
                clas = "";
              }
              if (ele.vehicleStatus === "Active" && i === 0 && j === 0) {
                bg_color = "#03C988";
                clas = "custom-marker";
              }
              if (
                ele.vehicleStatus === "Inactive" &&
                j < showTrips.length - 1
              ) {
                bg_color =
                  "linear-gradient(-45deg, #03C988, #03C988 50%, white 50%, white 50%, #FF005C 50%)";
                clas = "custom-marker";
              }
              allpoint.push([ele.latitude, ele.longitude]);
              return (
                <Marker
                  position={[ele.latitude, ele.longitude]}
                  icon={L.divIcon({
                    className: "markerStyles",
                    html: `<div style="background-color:${bg_color};transform: rotate(${trips_data[elem].bearingsarr1[i]}deg);background:${bg_color}" class="${clas} "></div>`,
                    iconAnchor: [10, 10],
                  })}
                  // icon={customIcon}
                > 
                  {/* <Tooltip
                    className="custom-tooltip"
                    direction="bottom"
                    offset={[0, 10]}
                  >
                    <Row>
                      <Col span={24}>
                        <Status status={ele.vehicleStatus} />
                      </Col>
                    </Row>
                    {ele.address && (
                      <Row gutter={[5, 16]}>
                        <Col span={2}>
                          <EnvironmentOutlined />
                        </Col>
                        <Col span={22}>
                          <Text  style={{ whiteSpace:'break-spaces' }}>{ele.address}</Text>
                        </Col>
                      </Row>
                    )}
                    {ele.hourMeter>=0 && (
                      <Row gutter={[5, 16]}>
                        <Col span={2}>
                          <ClockCircleOutlined />
                        </Col>
                        <Col span={22}>
                          {t("InventoryTable.Horametre")} :{" "}
                          {convertToHoursMins(props.props.vehicle ? (ele.hourMeter+props?.props?.vehicle?.profile?.CstHora):(ele.hourMeter+props?.vehicle?.profile?.CstHora))}
                        </Col>
                      </Row>
                    )}
                    {ele.odometer>=0 && (
                      <Row gutter={[5, 16]}>
                        <Col span={2}>
                          <FontAwesomeIcon icon={faRoad} />
                        </Col>
                        <Col span={22}>
                          {t("InventoryTable.Odometre")} :{" "}
                          {props.props.vehicle ? (ele.odometer+props?.props?.vehicle?.profile?.CstOdo).toFixed(2) + " Km":(ele.odometer+props?.vehicle?.profile?.CstOdo).toFixed(2) + " Km"}
                        </Col>
                      </Row>
                    )}
                    <Row gutter={[5, 16]}>
                      <Col span={2}>
                      <DashboardOutlined />
                      </Col>
                      <Col span={22}>
                        {t("Speed")} :{" "}
                        {(ele.speed ?? 0).toFixed(0) + " Km/h"}
                      </Col>
                    </Row>
                    <Row gutter={[5, 16]}>
                      <Col span={2}>
                      <DashboardOutlined />
                      </Col>
                      <Col span={22}>
                        {t("GPRS_Signal")} :{" "}
                        {ele.GPRS_Signal ?? " - "}
                      </Col>
                    </Row>
                    <Row gutter={[5, 16]}>
                      <Col span={2}>
                      <DashboardOutlined />
                      </Col>
                      <Col span={22}>
                        {t("sat_tracked")} :{" "}
                        {ele.sat_tracked ?? " - "}
                      </Col>
                    </Row>
                    <Row gutter={[5, 16]} style={{ minHeight: "40px" }}>
                      <Col>
                        <FieldTimeOutlined /> {t("Lastupdate")} :
                      </Col>
                      <Col>
                        <Text>
                          {dayjs(
                            Math.floor(ele.createdAt / 1000) * 1000
                          ).format("DD MMM YYYY HH:mm")}
                        </Text>
                      </Col>
                    </Row>
                  </Tooltip> */}
                  {(ele.vehicleStatus === "Inactive" &&
                    j < showTrips.length - 1) && <Marker position={[ele.latitude, ele.longitude]}
                  icon={L.icon({
                    iconUrl:"https://img.icons8.com/?size=100&id=jSE5gcMaqEMI&format=png&color=000000",
                    iconAnchor: [30, 50],
                    iconSize: [60, 60],
                  })}  >
                    <Popup offset={[0, -40]} >  
              <a 
              href={`https://www.google.com/maps/search/?api=1&query=${ele.latitude},${ele.longitude}`} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              ${t("vew")}
            </a>
            </Popup>
                    </Marker>}
                </Marker>
              );
            });
          })}
        <Polyline positions={allpoint} color='red'/>
      </MapContainer>
    </>
  );
}