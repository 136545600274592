import React from 'react';
import { Tag } from "antd";
import { useTranslation } from "react-i18next";


function MaintenanceStatus(props) {
    const { t } = useTranslation();
    let status = "";
    let color = "";
        switch (props.status) {
          case "echeance receipt !!":
            color = "#FFACAC";
            status = t("Maintenance.23");
            break;
          case "seuil receipt !!":
            color = "#FFE569";
            status = t("Maintenance.24");
            break;
          case "Validated":
            color = "#BEF0CB";
            status = t("Maintenance.25");
            break;
          default:
            color = "#62CDFF";
            status = "Active";
          break;
        }
        return (
          <Tag color={color}>{status}</Tag>
        );
}

export default MaintenanceStatus;