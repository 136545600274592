/* eslint-disable array-callback-return */
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Polygon, LayerGroup,LayersControl } from "react-leaflet";
import {
  Modal,
  Descriptions,
  Col,
  Row,
  List,
  Typography,
  Card,
  Switch,
} from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_GEOFENCE,GET_ALERT_ACTIVITY, GET_ALERT_SPEED, GET_ALERT_POWER, GET_ALERT_IDLE,GET_ALERTS } from "../../queries/alertQuery";
import { ACTIVATE_ALERT, DISACTIVATE_ALERT } from "../../mutations/alertMutations";
import Loading from "../Loading/Loading";
import { convertToHoursMins,convertToDaysHoursMins } from "../../constants/ConvertHora";
import dayjs from "dayjs";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
const { Text } = Typography;
const UnselectedCard = {
  width: "100% ", height: "100%"
};
function AlertsComponentMobile(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { company, role, _id } = props.props;
    const [activatalert] = useMutation(ACTIVATE_ALERT, {
    refetchQueries: [
      { query: GET_ALERTS, variables: { _id: company, role: role, user: _id } },
    ],
  });
  const [disactivatalert] = useMutation(DISACTIVATE_ALERT, {
    refetchQueries: [
      { query: GET_ALERTS, variables: { _id: company, role: role, user: _id } },
    ],
  });
  
  return (
    <>
      <List
    pagination={{
      pageSize: 10,
      align:"start",
    }}
    itemLayout="horizontal"
    className="AlertList2"
    dataSource={props.AlertData}
    renderItem={(item) => (
      <List.Item style={{margin:"5px 10px"}}>
       <div
                  key={
                    item.key
                  }
                  className="mydivouter2"
                >
                    <div style={{ 
                      position: "absolute",
                      zIndex: 2,
                      top: "50px",
                      right: "20px",
                     }}>
                      <Row>
                        <Switch
                          defaultValue={item.status==="activated"}
                          onChange={(checked)=>{
                            if (checked) {
                              activatalert({ variables: { _id:item._id } });
                            } else {
                              disactivatalert({ variables: { _id:item._id} });
                            }
                          }}  
                        />
                      </Row>
                    </div>
                  <Card
                    style={UnselectedCard}
                    onClick={async() => {
                      navigate("/alertDetails",{state: { alert: item,props:props}})
                      }}
                  >
                          {item.createdAt!==""&&<div style={{ 
                  position: "absolute",
                  zIndex: 2,
                  top: "0px",
                  right: "10px",
                 }}>
                  <Text type="secondary">
                    {dayjs(Math.floor(item.createdAt / 1000) * 1000).format(
        "DD MMM YYYY HH:mm"
      )}
                  </Text>
                </div>}
                <div style={{margin:"20px 10px 10px 10px"}}>
                          <Row>
                            <Col></Col>
                            <Col><Text strong>{item.name}</Text></Col>
                          </Row>
                          <Row>
                            <Col><Text type="secondary">Type: </Text></Col>
                            <Col><Text type="secondary" style={{marginLeft:5}}>{t(item.type+"2")}</Text></Col>
                          </Row>
                          <Row>
                            <Col>
                            <div>
                              <Text type="secondary">{t("By2")+" "+item.createdBy}</Text>
                            </div></Col>
                          </Row>
                </div>
                  <Row gutter={[16,16]} style={{ marginLeft:5,marginTop:7 }}>
                  </Row>
                  </Card>{" "}
                </div>
      </List.Item>
    )}
  />
    </>
  );
}
export default AlertsComponentMobile;
