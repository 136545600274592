import React, {  useRef,useState,useEffect,useContext } from "react";
import { ValueContext } from "../../context/Context";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import "../../css/App.css";
import { MapContainer, TileLayer, Marker ,useMap,Tooltip, LayerGroup,LayersControl, Popup} from "react-leaflet";
import L, { divIcon, point } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { Typography, Row, Col } from "antd";
import {ProfileOutlined ,EnvironmentOutlined,ClockCircleOutlined,LogoutOutlined,FieldTimeOutlined, DashboardOutlined }from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons'
import dayjs from "dayjs";
import Status from "../Multiple/Status";
import { convertToHoursMins } from "../../constants/ConvertHora";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const { Text } = Typography;
export default function Map(props) {
  const { t } = useTranslation();
  let { isDesktop } = useContext(ValueContext);
  const mapRef = useRef(null);
  const data = props.data;
  const loading = props.loading;
  const error = props.error;
  const [currentPopup, setCurrentPopup] = useState(null);
  useEffect(() => {
    if (props.lat !== "" && props.long !== "") {
      const { current: map } = mapRef;
      if (map) {
        const latlng = L.latLng(props.lat, props.long);
        map.setView(latlng, 18);
      }
      openPopupAtLocation(props.lat, props.long);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lat, props.long]);
  if (loading) return "";
  if (error) return <></>;
   function SearchControl() {
     {
       const map = useMap();
       const searchControl = L.control({ position: "topleft" });

       searchControl.onAdd = function () {
         const provider = new OpenStreetMapProvider();
         const search = new GeoSearchControl({
          provider: provider,
          style: 'button',
          autoCompleteDelay: 250,
          showMarker: false,
          showPopup: false,
          autoClose: true,
          retainZoomLevel: false,
          animateZoom: true,
          keepResult: false,
          searchLabel: t("MapSearch"),
          notFoundMessage: t("notFoundAddress"),
          messageHideDelay: 3000,
          autoResize: true,
        });
         return search.onAdd(map);
       };
       if (map._controlCorners.topleft.childElementCount < 2)
         searchControl.addTo(map);
     }

     return null;
  }
  const openPopupAtLocation = (lat, lng) => {
    const { current: map } = mapRef;
    if (map) {
      // Close the previous popup if it exists
      if (currentPopup) {
        currentPopup.remove();
      }
      const dummyMarker = L.marker([lat, lng], {
        opacity: 0,
        interactive: false,
      });
      const popupOptions = {
        autoClose: false,
        closeButton: true,
        offset: L.point(0, 30),
      };

      const popup = L.popup(popupOptions)
        .setLatLng([lat, lng])
        .setContent(props.model);

      dummyMarker.bindPopup(popup);
      popup.openOn(map);

      // Store the current popup reference in state
      setCurrentPopup(popup);
    }
  };


  const Markerlayer = ({ data }) => {
    if (!data) {
      return (
        <MapContainer center={[34.0715274,9.2568922]} zoom={16} scrollWheelZoom={true} zoomControl={isDesktop ? true:false}>
          <LayersControl position="topright">
            <LayerGroup>
              {/* <LayersControl.BaseLayer checked name="Simple">
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite">
                <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer> */}
              <LayersControl.BaseLayer checked name="Google Hybrid">
                <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Streets">
                <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
            </LayerGroup>
          </LayersControl>
        </MapContainer>
      );
    }
    return data.getVehicles.map((veh) => {
      const coordinates = [veh.latitude, veh.longitude];
      var bg_color = "grey";
      switch (veh.status) {
        case "Inactive":
          bg_color = "#FF005C";
          break;
        case "Active":
          bg_color = "#03C988";
          break;
          default:break;
      }
      return (
        <Marker
          key={String(veh._id)}
          position={coordinates}
          icon={L.divIcon({
            className: "markerStyles",
            html: `<div style="background-color:${bg_color}" class="custom-marker"></div>`,
            iconAnchor: [10, 10],
          })}
          // icon={customIcon}
        >
          {isDesktop ? <>
          
          <Tooltip
            className="custom-tooltip"
            direction="bottom"
            offset={[0, 10]}
          >
            <Row>
              <Col span={24}>
                <Text strong>{veh.profile.serialNumber}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Status status={veh.status} />
              </Col>
            </Row>
            <Row gutter={[5, 16]}>
              <Col span={2}>
                <ProfileOutlined />
              </Col>
              <Col span={22}>
                {veh.profile.Categorie} - {veh.profile.Model} -{" "}
                {veh.profile.Marque}
              </Col>
            </Row>
            {veh.address && (
              <Row gutter={[5, 16]}>
                <Col span={2}>
                  <EnvironmentOutlined />
                </Col>
                <Col span={22}>
                <Text  style={{ whiteSpace:'break-spaces' }}>{veh.address}</Text>
                </Col>
              </Row>
            )}
            {veh.hourMeter>=0 && (
              <Row gutter={[5, 16]}>
                <Col span={2}>
                  <ClockCircleOutlined />
                </Col>
                {veh.Source === "DS1" ? (
                  <Col span={22}>
                    {t("InventoryTable.Horametre")} :{" "}
                    {convertToHoursMins(veh.hourMeter)}
                  </Col>
                ) : (
                  <Col span={22}>{t("InventoryTable.Horametre")} : - </Col>
                )}
              </Row>
            )}
            {veh.odometer>=0  && (
              <Row gutter={[5, 16]}>
                <Col span={2}>
                  <FontAwesomeIcon icon={faRoad} />
                </Col>
                <Col span={22}>
                  {t("InventoryTable.Odometre")} :{" "}
                  {(veh.odometer).toFixed(2) + " Km"}
                </Col>
              </Row>
            )}
              <Row gutter={[5, 16]}>
                <Col span={2}>
                <DashboardOutlined />
                </Col>
                <Col span={22}>
                  {t("Speed")} :{" "}
                  {(veh.speed ?? 0).toFixed(0) + " Km/h"}
                </Col>
              </Row>
            <Row gutter={[5, 16]}>
              <Col span={2}>
                <LogoutOutlined />
              </Col>
              <Col span={22}>Source : {veh.Source}</Col>
            </Row>
            <Row gutter={[5, 16]}>
              <Col span={2}>
                <FieldTimeOutlined />
              </Col>
              <Col span={22}>
                {t("Lastupdate")} :{" "}
                {dayjs(Math.floor(veh.updatedAt / 1000) * 1000).format(
                  "DD MMM YYYY HH:mm:ss"
                )}
              </Col>
            </Row>
            </Tooltip>
            <Popup>
              <a 
              href={`https://www.google.com/maps/search/?api=1&query=${veh.latitude},${veh.longitude}`} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {t("vew")}
            </a>
            </Popup>
          </>:<>
          <Link
            to={{ pathname: "/details" }}
          state={{ vehicle: veh, path: "/map" }}
           onContextMenu={(e) => {e.preventDefault(); }}
          >
          <Popup className="MainMapPopUp">
          <Row>
              <Col span={24}>
                <Text strong>{veh.profile.serialNumber}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Status status={veh.status} />
              </Col>
            </Row>
            <Row gutter={[5, 16]}>
              <Col span={2}>
                <ProfileOutlined />
              </Col>
              <Col span={22}>
                {veh.profile.Categorie} - {veh.profile.Model} -{" "}
                {veh.profile.Marque}
              </Col>
            </Row>
            {/* {veh.address && (
              <Row gutter={[5, 16]}>
                <Col span={2}>
                  <EnvironmentOutlined />
                </Col>
                <Col span={22}>
                <Text  style={{ whiteSpace:'break-spaces' }}>{veh.address}</Text>
                </Col>
              </Row>
            )}
            {veh.hourMeter>=0 && (
              <Row gutter={[5, 16]}>
                <Col span={2}>
                  <ClockCircleOutlined />
                </Col>
                {veh.Source === "DS1" ? (
                  <Col span={22}>
                    {t("InventoryTable.Horametre")} :{" "}
                    {convertToHoursMins(veh.hourMeter)}
                  </Col>
                ) : (
                  <Col span={22}>{t("InventoryTable.Horametre")} : - </Col>
                )}
              </Row>
            )}
            {veh.odometer>=0  && (
              <Row gutter={[5, 16]}>
                <Col span={2}>
                  <FontAwesomeIcon icon={faRoad} />
                </Col>
                <Col span={22}>
                  {t("InventoryTable.Odometre")} :{" "}
                  {(veh.odometer).toFixed(2) + " Km"}
                </Col>
              </Row>
            )}
            <Row gutter={[5, 16]}>
              <Col span={2}>
                <LogoutOutlined />
              </Col>
              <Col span={22}>Source : {veh.Source}</Col>
            </Row> */}
            <Row gutter={[5, 16]}>
              <Col span={2}>
                <FieldTimeOutlined />
              </Col>
              <Col span={22}>
                {t("Lastupdate")} :{" "}
                {dayjs(Math.floor(veh.updatedAt / 1000) * 1000).format(
                  "DD MMM YYYY HH:mm:ss"
                )}
              </Col>
            </Row>
      </Popup>
            </Link>
          
          </>}
        </Marker>
      );
    });
  };

  const createcluster = (cluster) => {
    // console.log(cluster);
    return new divIcon({
      html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
      className: "custom-marker-cluster",
      iconSize: point(40, 40, true),
    });
  };
  // const handleClusterClick = (event) => {
  //   // Check if the cluster is already spiderfied, and toggle its state
  //   let { current: map } = mapRef;
  //   let child = event.layer.getAllChildMarkers();
  //   console.log(event.layer.getAllChildMarkers());
  //   const bounds = L.latLngBounds(child.map((ele) => [ele._latlng.lat, ele._latlng.lng]));
  //   map.fitBounds(bounds);
  //   console.log(bounds);
  //   // event.layer.zoomToShowLayer();
  //   // if (event.layer._zoom>13) {
  //   //   const latlng1 = event.latlng;
  //   //   let latlng = L.latLng(latlng1.lat, latlng1.lng);
  //   //   map.setView(latlng, 18);
  //   //    console.log(event);
  //   //   } else {
  //   //   console.log("no zoom");
  //   // }
  // };

  return (
    <>
      <MapContainer
        ref={mapRef}
        center={[34.0715274,9.2568922]}
        zoom={6}
        zoomControl={isDesktop ? true:false}
        scrollWheelZoom={true}
        style={{ height: "100vh", width: "100wh" }}
      >
        <LayersControl position="topright">
          {/* <LayersControl.BaseLayer checked name="Simple">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
          </LayersControl.BaseLayer> */}
          <LayersControl.BaseLayer checked name="Google Hybrid">
            <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Google Streets">
            <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
          </LayersControl.BaseLayer>
        </LayersControl>
        {isDesktop&&<SearchControl />}
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={createcluster}
          disableClusteringAtZoom={18}
          // onClick={handleClusterClick}
          // spiderfyOnMaxZoom={true}
        >
          <Markerlayer data={data} />
        </MarkerClusterGroup>
      </MapContainer>
    </>
  );
}
// }
