import { React } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Space, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

function Breadcrumbs(props) {
  const { t } = useTranslation();
  const data = props.data
   let Name = "";
   let zone;
   if (props.path === "/inventory") {
     Name = t("SideBar.Inventory");
     zone = "/inventory";
   } else {
     Name = "zone";
     zone = "/zone";
   }
  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to={zone}>
                <div className="bredIcon">
                  <Space>
                    <LeftOutlined />
                    {Name}
                  </Space>
                </div>
              </Link>
            ),
          },
        ]}
      />
      <div>
        <Space>
          <div className="ProfileSNumber">
            {/* <Text>tt</Text> */}
            <Text>{data.getZone.name}</Text>
          </div>
          <Text mark>{t(`zone.${data.getZone.categorie}`)}</Text>
        </Space>
      </div>
    </>
  );
}

export default Breadcrumbs;
