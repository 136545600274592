
import { gql } from '@apollo/client';

const LOGIN = gql`
 query Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            _id
            token
            email
            role{
                name
            }
            company{
                _id
                name
            }
            workspace{
              _id
              name
              vehicle{
                _id
              }
            }
            }
        
 }
`;
const VERIFY_TOKEN = gql`
 query Login($token: String!) {
        login(token: $token) {
            _id
            company{
            _id
            name
            }
            email
            role{
            name
            }
            workspace{
            _id
            name
            vehicle{
                _id
            }
          }
            }
        
 }
`;
const VERIFY_INVITATION = gql`
 query verifyInvitation($invitationToken: String!) {
  verifyInvitation(invitationToken: $invitationToken) 
 }
`;
const GET_USERS = gql`
  query Get_Users($_id: ID, $role: String, $workspace: [ID]) {
    getUsers(_id: $_id, role: $role, workspace: $workspace) {
      _id
      email
      fname
      lname
      company{
        _id
        name
      }
      role {
        _id
        name
      }
      workspace{
        _id
        name
      }
    }
  }
`;
const GET_USER = gql`
  query Get_User($_id: ID ) {
    getUser(_id: $_id) {
      _id
      email
      fname
      lname
      company{
        _id
      }
      role {
        _id
      }
      workspace{
        _id
      }
    }
  }
`;
const GET_ADMINUSER = gql`
  query Get_AdminUser($_id: ID, $_iduser:ID ) {
    getAdminUser(_id: $_id,_iduser:$_iduser)
  }
`;
const GET_USERS_FOR_ADMINISTRATION = gql`
  query Get_Users($_id: ID, $role: String, $workspace: [ID]) {
    getUsers(_id: $_id, role: $role, workspace: $workspace) {
      _id
      email
    }
  }
`;
export { LOGIN, VERIFY_INVITATION, GET_USERS, GET_USERS_FOR_ADMINISTRATION,GET_USER,GET_ADMINUSER,VERIFY_TOKEN };
