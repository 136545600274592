import React, { useEffect,useContext } from "react";
import { ValueContext } from "../../context/Context";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Layout, Col, Row,Typography } from "antd";
import {ProfileOutlined ,EnvironmentOutlined,ClockCircleOutlined,LogoutOutlined,FieldTimeOutlined, DashboardOutlined }from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker,Tooltip,useMap, LayerGroup,LayersControl, Popup } from "react-leaflet";
import L from "leaflet";
import "../../../node_modules/leaflet/dist/leaflet.css";
import { GET_VEHICLE } from "../../queries/mapQuery";
import { io } from "socket.io-client";
import dayjs from "dayjs";
import { convertToHoursMins } from "../../constants/ConvertHora";
import Status from "../Multiple/Status";
import "../../css/ProfileTabs.css";
const { Content } = Layout;
const { Text } = Typography;

function LiveTab(props) {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ValueContext);
  const client = useApolloClient();
  const { company } = props.props;
  const data  = props.data;
 const socket = io({ path: "/socket.io", transports: ["websocket"] });
  useEffect(() => {
    if (socket) {
      socket.on('updateVehicle'+props.vehicle._id, (updatedVehicle) => {
        const newVehicle = {
          ...data.getVehicle, // spread the existing fields of the vehicle
          Source:data.getVehicle.Source,
          address:updatedVehicle.address,
          assetID:data.getVehicle.assetID,
          createdAt:data.getVehicle.createdAt,
          hourMeter: updatedVehicle.hourMeter,
          odometer: updatedVehicle.odometer,
          latitude: updatedVehicle.latitude,
          longitude: updatedVehicle.longitude,
          profile:data.getVehicle.profile,
          status: updatedVehicle.status,
          updatedAt: updatedVehicle.updatedAt,
        };
        // update the data in the Apollo cache
        client.writeQuery({
          query: GET_VEHICLE,
          variables: { _id: props.vehicle._id },
          data: { getVehicle: newVehicle },
        });
      });
    }
    return () => {
      if (socket) {
        socket.off(company+'updateVehicle'+props.vehicle._id);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, data, client, props.vehicle._id]);
function FitBounds() {
  const map = useMap();
  useEffect(() => {
      const bounds = L.latLngBounds([[data.getVehicle.latitude, data.getVehicle.longitude]]);
      try {
        map.fitBounds(bounds,{maxZoom: map.getZoom()});
      } catch (error) {
        console.log(error);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])
}
  var bg_color = "grey";
  switch (data.getVehicle.status) {
    case "Inactive":
      bg_color = "#FF005C";
      break;
    case "Active":
      bg_color = "#03C988";
      break;
    default:
      break;
  }
  return (
    <>
      <Content
        className="liveContent"
      >
        <MapContainer
          center={[data.getVehicle.latitude, data.getVehicle.longitude]}
          scrollWheelZoom={true}
          style={{ height: "70vh", width: "90wh" }}
        >
          <LayersControl position="topright">
            <LayerGroup>
              {/* <LayersControl.BaseLayer checked name="Simple">
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite">
                <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer> */}
              <LayersControl.BaseLayer checked name="Google Hybrid">
                <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Streets">
                <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
            </LayerGroup>
          </LayersControl>
          <Marker
            key={String(props.vehicle._id)}
            position={[data.getVehicle.latitude, data.getVehicle.longitude]}
            icon={L.divIcon({
              className: "markerStyles",
              html: `<div style="background-color:${bg_color}" class="custom-marker"></div>`,
              // iconAnchor: [10, 10],
            })}
            // icon={customIcon}
          >
            <FitBounds />
            <Tooltip
              className="custom-tooltip"
              direction="bottom"
              offset={[5, 10]}
            >
              <Row>
                <Col span={24}>
                  <Status status={data.getVehicle.status} />
                </Col>
              </Row>
              <Row gutter={[5, 16]}>
                <Col span={2}>
                  <ProfileOutlined />
                </Col>
                <Col span={22}>
                  <Text strong>{data.getVehicle.profile.serialNumber+" - "}</Text>
                  {data.getVehicle.profile.Categorie+" - "}
                  {data.getVehicle.profile.Model+" - "}
                  {data.getVehicle.profile.Marque}
                </Col>
              </Row>
              {data.getVehicle.address && (
                <>
                <Row gutter={[5, 16]}>
                  <Col span={2}>
                    <EnvironmentOutlined />
                  </Col>
                  <Col span={22}>
                    <Text  style={{ whiteSpace:'break-spaces' }}>{data.getVehicle.address}</Text>
                  </Col>
                </Row>
                <Row gutter={[5, 16]}>
                  <Col span={2}>
                    
                  </Col>
                  <Col span={22}>
                            <Text type="secondary">
                              {dayjs(
                                Math.floor(data.getVehicle.GPSTime ? data.getVehicle.GPSTime:data.getVehicle.updatedAt / 1000) *
                                  1000
                              ).format("DD MMM YYYY HH:mm:ss")}
                            </Text>
                          </Col>
                </Row>
                </>
              )}
              {data.getVehicle.hourMeter>=0 && (
                <Row gutter={[5, 16]}>
                  <Col span={2}>
                    <ClockCircleOutlined />
                  </Col>
                  <Col span={22}>
                    {t("InventoryTable.Horametre")} :{" "}
                    {convertToHoursMins(data.getVehicle.hourMeter)}
                  </Col>
                </Row>
              )}
              {data.getVehicle.odometer>=0 && (
                <Row gutter={[5, 16]}>
                  <Col span={2}>
                    <FontAwesomeIcon icon={faRoad} />
                  </Col>
                  <Col span={22}>
                    {t("InventoryTable.Odometre")} :{" "}
                    {(data.getVehicle.odometer).toFixed(2) + " Km"}
                  </Col>
                </Row>
              )}
              <Row gutter={[5, 16]}>
                <Col span={2}>
                <DashboardOutlined />
                </Col>
                <Col span={22}>
                  {t("Speed")} :{" "}
                  {(data.getVehicle.speed ?? 0).toFixed(0) + " Km/h"}
                </Col>
              </Row>
              {isDesktop&&<><Row gutter={[5, 16]}>
                <Col span={2}>
                <DashboardOutlined />
                </Col>
                <Col span={22}>
                  {t("GPRS_Signal")} :{" "}
                  {data.getVehicle.GPRS_Signal ?? " - "}
                </Col>
              </Row>
              <Row gutter={[5, 16]}>
                <Col span={2}>
                <DashboardOutlined />
                </Col>
                <Col span={22}>
                  {t("sat_tracked")} :{" "}
                  {data.getVehicle.sat_tracked ?? " - "}
                </Col>
              </Row>
              <Row gutter={[5, 16]}>
                <Col span={2}>
                  <LogoutOutlined />
                </Col>
                <Col span={22}>Source : {data.getVehicle.Source}</Col>
              </Row></>}
              <Row gutter={[5, 16]} style={{ minHeight: "40px" }}>
                <Col>
                  <FieldTimeOutlined /> {t("Lastupdate")} :
                </Col>
                <Col>
                  <Text>
                    {dayjs(
                      Math.floor(data.getVehicle.updatedAt / 1000) * 1000
                    ).format("DD MMM YYYY HH:mm")}
                  </Text>
                </Col>
              </Row>
            </Tooltip>
            <Popup>
              <a 
              href={`https://www.google.com/maps/search/?api=1&query=${data.getVehicle.latitude},${data.getVehicle.longitude}`} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {t("vew")}
            </a>
            </Popup>
          </Marker>
        </MapContainer>
      </Content>
    </>
  );
}
export default LiveTab;
