import { React, lazy } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";
import { Tabs } from "antd";
import { Link, useLocation } from "react-router-dom";
import { GET_ZONE } from "../../queries/zoneQuery";
import "../../css/ProfileTabs.css";
import Breadcrumbs from "../../components/Multiple/BreadcrumbsZone";
import { useQuery } from "@apollo/client";
const ZoneAprecucomponent = lazy(() => import("../../components/Zone/ZoneAprecu"));
const { Content } = Layout;

function ZoneAprecu(props) {
  const { t } = useTranslation();
  let location = useLocation();
  const zone = location.state.zone;
  const path = location.state.path;
  const { loading, error, data } = useQuery(GET_ZONE, {
    variables: { _id: zone._id , role:props.props.role},
  });
  const items = [
    {
      key: "1",
      label: <div className="TabLink">{t("ZoneTabs.insight")}</div>,
      children: <ZoneAprecucomponent data={data} zone={zone} props={props} />,
    },
    {
      key: "2",
      label: (
        <Link to={{ pathname: "/chronologie" }} state={{ zone, path }}>
          <div className="TabLink">{t("ZoneTabs.chronology")}</div>
        </Link>
      ),
    },
  ];

  if (loading) return <></>;
  if (error) return <></>;
  return (
    <Content
      style={{
        height: "100vh",
        width: "100wh",
        padding: "30px 20px 20px 30px",
        overflowY: "scroll",
      }}
    >
      <Breadcrumbs data={data} path={path} props={props} />

      <Tabs defaultActiveKey="1" items={items} />
    </Content>
  );
}
export default ZoneAprecu;
