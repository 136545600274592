/* eslint-disable array-callback-return */
import { React, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Layout } from "antd";
import {
  Typography,
  Space,
  Card,
  InputNumber,
  Select,
  Button,
  Form,Row,Col,Input,Switch,notification
} from "antd";
import { GET_VEHICLES_FOR_ADMINISTRATION_PAGE } from "../../queries/mapQuery";
import { GET_USERS_FOR_ADMINISTRATION,GET_ADMINUSER } from "../../queries/UserQuery";
import { GET_ALERTS } from "../../queries/alertQuery";
import { ADD_ALERT} from "../../mutations/alertMutations";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Button as MobileBtn } from "antd-mobile";
import { Link } from "react-router-dom";
import "../../css/Alerts.css";
import Loading from "../../components/Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ValueContext } from "../../context/Context";
const { Content } = Layout;
const { Text } = Typography;

function ActivityAlerts(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const DAYS = [
    {
      key: "monday",
      label: t("monday"),
    },
    {
      key: "tuesday",
      label: t("tuesday"),
    },
    {
      key: "wednesday",
      label: t("wednesday"),
    },
    {
      key: "thursday",
      label: t("thursday"),
    },
    {
      key: "friday",
      label: t("friday"),
    },
    {
      key: "saturday",
      label: t("saturday"),
    },
    {
      key: "sunday",
      label: t("sunday"),
    },
  ];
  const [formats, setFormats] = useState([
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
  ]);
  const { company, role,_id,workspaces } = props.props;
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [name, setname] = useState(0);
  const [option, setoption] = useState();
  const [e, sete] = useState();
  const [er, seter] = useState();
  const [user, setUser] = useState([_id]);
  const [vehicle, setvehicle] = useState([]);
  const { isDesktop } = useContext(ValueContext);
const [showFirstRow, setShowFirstRow] = useState(false);
const [showSecondRow, setShowSecondRow] = useState(false);
  const {
    loading: loadingv,
    error: errorv,
    data: datav,
  } = useQuery(GET_VEHICLES_FOR_ADMINISTRATION_PAGE, {
    variables: { _id: company, role: role, workspace: workspaces },
  });
  const  { loading: loadinga, error: errora, data: dataa } =
    useQuery(GET_ADMINUSER, {
      variables: { _id: company,_iduser:_id},
    });
  const { loading: loadingu, error: erroru, data: datau } =
    useQuery(GET_USERS_FOR_ADMINISTRATION, {
      variables: { _id: company, role: role,workspace:workspaces },
    });
  const successNotification = () => {
    notification.open({
      description: t("Notification.16"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const [addAlert, { loading, error }] =
    useMutation(ADD_ALERT,{
      refetchQueries: [{ query: GET_ALERTS, variables: { _id: company, role: role, user: _id } }],
      onCompleted: () => {
      successNotification();
    },
  });
  const submit = async () => {
   dataa.getAdminUser.map((element) => {
     if (!user.includes(element)) {
       user.push(element);
     }
   });
    if (!user.includes(_id)) {
      user.push(_id);
    }
    if (formats.length === 0) {
      sete("error")
       return;
    }
    if (!showFirstRow && !showSecondRow)
    { seter("error"); return}
  const { error, data }= await addAlert({
      variables:
        { user, 
          vehicle: vehicle ? (vehicle.includes("All") ? (vehicleoptions.map(option => option.value)):vehicle): [],
           type: "Activity", period: formats, limit: showFirstRow? hours * 60 + minutes:hours,  name, role,company,createdBy:_id,option:option }
  })
    if (data) {
      navigate("/alerts");
    } else {
      return <p>{ error }</p>
    }
  };
  if (loadinga) return <Loading/>
  if (errora) return <p> Error : { errora}</p>
  if (loading) return <Loading/>
  if (error) return <p> Error : { error}</p>
  if (loadingu) return <Loading/>
  if (erroru) return <p> Error : { erroru}</p>
  if (loadingv) return <Loading/>
  if (errorv) return <p> Error : { errorv}</p>
  const handleCheckboxChange = (selectedOptions) => {
    sete();
    setFormats(selectedOptions);
  };
   const vehicleoptions = [];
   datav &&
     datav.getVehicles.map((ele) => {
       vehicleoptions.push({
         value: ele._id,
         label: ele.profile.Model + " / " + ele.profile.serialNumber,
       });
     });
   const useroptions = [];
   datau &&
     datau.getUsers.map((ele) => {
       useroptions.push({
         value: ele._id,
         label: ele.email,
       });
     });
  const selectProps = {
    mode: "multiple",
    style: {
      width: "70%",
    },
    vehicle,
    options: [{value: "All", label: t("AllEquipment")},...vehicleoptions],
    onChange: (newValue) => {
      setvehicle(newValue);
    },
    filterOption: (inputValue, option) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    },
    placeholder: t("Alerts.30"),
    maxTagCount: "responsive",
  };
  const selectProps2 = {
    mode: "multiple",
    style: {
      width: "70%",
    },
    user,
    options: useroptions,
    onChange: (newValue) => {
      setUser(newValue);
    },
    filterOption: (inputValue, option) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    },
    placeholder: t("Alerts.14"),
    maxTagCount: "responsive",
  };
  const handleFirstSwitchChange = (checked) => {
   if(checked){ setoption("hr")}else{setoption("km")}
  setShowFirstRow(checked);
    setShowSecondRow(!checked); // Close the second switch when opening the first
    seter();
};
const handleSecondSwitchChange = (checked) => {
  if(checked){setoption("km")} else{setoption("hr")}
  setShowSecondRow(checked);
  setShowFirstRow(!checked); // Close the first switch when opening the second
  seter();
};

  return (
    <Content className="ActContent">
      {!isDesktop&&<MobileBtn className='RHistoryBtn3' size='mini' shape='rounded'
          onClick={()=>{
            navigate(-1);
            }} >
          <FontAwesomeIcon icon={faArrowLeft} />
          </MobileBtn>}
      <Card
        title={t("Alerts.AlertRules")}
        style={{
          width: '100%',
        maxWidth: '650px',
        margin: 'auto',
        }}
      >
        <Form
          layout="vertical"
          initialValues={{
            members:[_id]
          }}
          onFinish={async()=> {await submit()}}
        >
          <Form.Item
      label={t("Administration.name")}
      name="username"
      rules={[
        {
          required: true,
          message: t("FormValidations.34"),
        },
      ]}
    >
      <Input  style={{width: '50%'}} placeholder={t("Administration.name")} onChange={(e)=>{setname(e.target.value)}} />
    </Form.Item>
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            <Form.Item
              name="option"
            >
            <Text strong>{t("Alerts.1")}</Text>
              <div style={{ marginBottom: "10px" }}>
                <Space size={"small"}>
      <Switch
        checked={showFirstRow}
        onChange={handleFirstSwitchChange}
                />
                  <label htmlFor="hr">{t('alerthora')}</label>
                </Space>
    </div>
            {showFirstRow && <Row gutter={[5,5]}>
            <Col><div><Text>{t("Alerts.2")}</Text></div></Col>
            <Col><Form.Item>
                <InputNumber min={0} defaultValue={0} onChange={(e)=>{setHours(e)}} />
              </Form.Item></Col>
            <Col><div><Text>{t("Alerts.3")}</Text></div></Col>
            <Col><Form.Item>
                <InputNumber min={0} defaultValue={0} onChange={(e)=>{setMinutes(e)}} />
              </Form.Item></Col>
            <Col><div><Text>{t("Alerts.4")}</Text></div></Col>
            </Row>}
              <div style={{ marginBottom: "10px" }}>
                <Space size={"small"}>
      <Switch
        checked={showSecondRow}
        onChange={handleSecondSwitchChange}
                />
                  <label htmlFor="hr">{t('alertodo')}</label>
                </Space>
    </div>
            {showSecondRow && <Row gutter={[5,5]}>
            <Col><div><Text>{t("Alerts.2")}</Text></div></Col>
            <Col><Form.Item>
                <InputNumber min={0} defaultValue={0} onChange={(e)=>{setHours(e)}} addonAfter="Km" />
              </Form.Item></Col>
            {/* <Col><div><Text>Km</Text></div></Col> */}
              </Row>} 
            <div style={{color:"red"}}>{er&&t("Maintenance.10")}</div>
            </Form.Item>
            <div>
              <Text strong>{t("Alerts.5")}</Text>
            </div>
            <div>
              <Text type="secondary">{t("Alerts.6")}</Text>
            </div>
            <Form.Item
              name="equipment"
            rules={[
              {
                required: true,
                message: t("FormValidations.29"),
              },
            ]}
            >
              <Select {...selectProps} />
            </Form.Item>
            <div>
              <Text strong>{t("Alerts.7")}</Text>
            </div>
            <div>
              <Text type="secondary">{t("Alerts.8")}</Text>
            </div>
            <Form.Item>
            <Checkbox.Group value={formats} onChange={handleCheckboxChange}>
            {DAYS.map((day, index) => (
                  <Checkbox
                    value={day.key}
                    className={formats.includes(day.key) ? "checkedBtn ToggelCheck" :"UncheckedBtn ToggelCheck"}
                    style={{ border: "1px ", borderRadius: "5px" }}
                  >
                    {day.label}
                  </Checkbox>
                ))}
            </Checkbox.Group>
              <div style={{color:"red"}}>{e&&t("FormValidations.36")}</div>
              
            </Form.Item>
            <div>
            <Text strong>{t("Alerts.13")}</Text>
          </div>
          <Form.Item
            name="members"
            rules={[
              {
                required: true,
                message: t("FormValidations.30"),
              },
            ]}
          >
          <Select {...selectProps2} />
          </Form.Item>
            <div style={{ marginTop:10 }}>
              <Space direction="horizontal">
              <div>
                <Link to={{ pathname: "/alerts" }}>
                  <Button>{t("Alerts.9")}</Button>
                </Link></div>
                <div>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t("Alerts.10")}
                  </Button>
                </Form.Item></div>
              </Space>
            </div>
          </Space>
        </Form>
      </Card>
    </Content>
  );
}
export default ActivityAlerts;
