/* eslint-disable array-callback-return */
import { React, useState ,useEffect, useContext} from "react";
import { useTranslation } from "react-i18next";
import { useQuery,useMutation } from "@apollo/client";
import dayjs from "dayjs";
import {
  Input,
  Typography,
  Table,
  Button,
  Layout,
  Modal,
  Col,
  Row,
  Dropdown,
  notification,
  List,
  Card,
} from "antd";
import { SearchOutlined,EllipsisOutlined,ExclamationCircleFilled } from "@ant-design/icons";
import { GET_MAINTENANCES } from "../queries/maintenanceQuery";
import { io } from "socket.io-client";
import { DELETE_MAINTENANCE } from "../mutations/maintenanceMutations"
import "../css/Maintenance.css";
import Loading from "../components/Loading/Loading";
import CreateMaintenanceForm from "../components/Forms/CreateMaintenanceForm";
import UpdateMaintenanceForm from "../components/Forms/UpdateMaintenanceForm";
import OpenMaintenance from "../components/Forms/OpenMaintenance";
import MaintenanceStatus from "../components/Multiple/MaintenanceStatus";
import { ValueContext } from "../context/Context";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

function Maintenance(props) {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ValueContext);
  const columns = [
    {
      title: t("Formeworkspace.lableforname"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("InventoryTable.SerialNumber"),
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: t("InventoryTable.Model"),
      dataIndex: "model",
      key: "model",
    },
    {
      title: t("InventoryTable.Category"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("InventoryTable.Status"),
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("Maintenance.1"),
      dataIndex: "echeance",
      key: "echeance",
    },
    {
      title: t("InventoryTable.Created"),
      dataIndex: "date",
      key: "date",
      render: (text) => (
        text[0]!==""&&<div>
         <div>
           <Text>
            {text[0]}
           </Text>
         </div>
         <Text type="secondary">{t("By")+" "+text[1]}</Text>
       </div>
     ),
    },
    {
      title: t("validle"),
      dataIndex: "validate",
      key: "validate",
      render: (text) => (
        text[0]&&<div>
         <div>
           <Text>
            {text[0]}
           </Text>
         </div>
         <Text type="secondary">{t("By")+" "+text[1]}</Text>
       </div>
     ),
    },
    {
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const [searchInput, setSearchInput] = useState("");
  const { role, _id } = props.props;

  const { loading, error, data,refetch } = useQuery(GET_MAINTENANCES, { variables: { user: _id, role: role } });
  const socket = io({ path: "/socket.io", transports: ["websocket"] });
  useEffect(() => {
    if (socket) {
      socket.on(_id + "MaintenanceNotification", (Msg) => {
        if (Msg === "Maintenance Notification") {
          refetch();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, data]);
  const successNotification = () => {
    notification.open({
      description: t("Notification.15"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const [deleteMaintenance] = useMutation(DELETE_MAINTENANCE, {
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_MAINTENANCES,
        variables: { user: _id, role: role },
      },
    ],
  });
    if (loading) return <Loading/>;
    if (error) return <></>;
    const showDeleteConfirm = (_id) => {
      confirm({
        title: t("Maintenance.17"),
        icon: <ExclamationCircleFilled />,
        content: t("Maintenance.18"),
        okText: t("FormValidations.11"),
        okType: "danger",
        cancelText: t("FormValidations.12"),
        onOk() {
          deleteMaintenance({ variables: { _id, role } });
        },
      });
    };
    const TableData = data.getMaintenances.filter((maintenance) => {
      if (searchInput === "") {
        return maintenance;
      } else if (
        maintenance.vehicle.profile.Model.toLowerCase().includes(searchInput.toLowerCase())
      ) {
        return maintenance;
      }
    });
    const MaintenanceData = TableData.map((maintenance) => {
      return {
        key: maintenance._id,
        name: maintenance.name,
        model: maintenance.vehicle.profile.Model,
        category: maintenance.vehicle.profile.Categorie,
        serialNumber: maintenance.vehicle.profile.serialNumber,
        status: <MaintenanceStatus status={maintenance.status} />,
        echeance: maintenance.echeance.includes("km") ? parseInt(maintenance.echeance)+" km":parseInt(maintenance.echeance)+" hr",
        date: [dayjs(Math.floor(maintenance.createdAt / 1000) * 1000).format(
          "DD MMM YYYY HH:mm"
        ),maintenance.createdBy],
        validate: [maintenance.status==="Validated"&&dayjs(Math.floor(maintenance.updatedAt / 1000) * 1000).format(
          "DD MMM YYYY HH:mm"
        ),maintenance.validatedby],
        actions: role !== "User" && (
          <>
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <OpenMaintenance
                        props={props.props}
                        maintenance={maintenance}
                      />
                    ),
                    key: "0",
                  },
                  maintenance.status !== "Validated" && {
                    label: (
                      <UpdateMaintenanceForm
                        props={props.props}
                        maintenance={maintenance}
                      />
                    ),
                    key: "1",
                  },
                  {
                    label: (
                      <Button
                        type="text"
                        block
                        onClick={() => {
                          showDeleteConfirm(maintenance._id);
                        }}
                      >
                        <Text type="danger">{t("Maintenance.13")}</Text>
                      </Button>
                    ),
                    key: "2",
                  },
                ],
              }}
              trigger={["click"]}
            >
              <Button type="text" icon={<EllipsisOutlined />}></Button>
            </Dropdown>
          </>
        ),
      };
    });
  return (
    <Content className="InvContent">
      {isDesktop ? <Table
        columns={columns}
        size="small"
        dataSource={MaintenanceData}
        title={() => (
          <Row>
          <Col span={12}>
              <Input
                size="default"
                className="SearchBar"
                placeholder={t("SearchInput")+" ("+MaintenanceData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Col>
              <Col span={12}>              
                <div className="ButtonsDiv">
                {role!=="User"&&<CreateMaintenanceForm props={props.props} />}
                </div>
            </Col>
        </Row>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />:<List
      pagination={{
        pageSize: 10,
        align:"start",
      }}
header={
  <Row>
          <Col span={12}>
              <Input
                size="default"
                className="SearchBar"
                placeholder={t("SearchInput")+" ("+MaintenanceData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Col>
              <Col span={12}>              
                <div className="ButtonsDiv">
                {role!=="User"&&<CreateMaintenanceForm props={props.props} />}
                </div>
            </Col>
        </Row>
}
itemLayout="horizontal"
className="InvList"
dataSource={MaintenanceData}
renderItem={(item, index) => (
  <List.Item style={{margin:"5px 10px"}}>
   <div
          key={
            item.serialNumber
          }
          className="mydivouter"
        >
                      <div style={{ 
                        position: "absolute",
                        zIndex: 2,
                        top: "40px",
                        right: "10px",
                       }}>
                        <Row>
                       {item.actions}
                        </Row>
                      </div>
          <Card
            style={{ width: "100% ", height: "100%", }}>
            <div style={{margin:"10px 10px 10px 10px"}}>
                          <Row>
                            <Col><Text strong>{item.name}</Text></Col>
                          </Row>
                          <Row>
                            <Col><Text strong>{item.status}</Text></Col>
                          </Row>
                          <Row>
                            <Col><Text type="secondary" style={{marginLeft:5}}>{item.serialNumber+" / "+item.model+" / "+item.category}</Text></Col>
                          </Row>
                          <Row>
                            <Col>
                            <div>
                              <Text type="secondary">{t("By2")+" "+item.date[1]}</Text>
                            </div></Col>
                          </Row>
                </div>
          </Card>{" "}
        </div>
  </List.Item>
)}
/>}
    </Content>
  );
}
export default Maintenance;
