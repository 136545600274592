/* eslint-disable array-callback-return */
import { React, useState,useContext } from "react";
import { ValueContext } from "../../context/Context";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Input,
  Typography,
  Table,
  Button,
  Layout,
  Modal,
  Card,
  Col,
  Row,Dropdown
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  EllipsisOutlined,ExclamationCircleFilled
} from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import "../../css/Alerts.css";
import { GET_ALERTS } from "../../queries/alertQuery";
import { DELETE_ALERT, ACTIVATE_ALERT, DISACTIVATE_ALERT } from "../../mutations/alertMutations";
import { GET_NOTIFICATIONS, GET_NOTREEDNOTIFICATIONS } from "../../queries/notificationQuery";
import Loading from "../Loading/Loading";
import AlertDetails from "../Forms/AlertDetails";
import AlertsComponentMobile from "./AlertsComponentMobile";
import dayjs from "dayjs";
import { Modal as MobileModal, TabBar } from "antd-mobile";
import { faCarBurst, faCarOn, faCarTunnel, faGasPump, faGaugeHigh, faGears, faMapLocationDot, faPlug, faTruckPickup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

function Alerts(props) {
  const { isDesktop } = useContext(ValueContext);
  const { t } = useTranslation();
  const { company, role, _id } = props.props;
  const [searchInput, setSearchInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
//   const tabs1 = [
//     {
//         key: '/alerts/activity',
//         icon: <Link to="/alerts/activity"><FontAwesomeIcon icon={faCarOn} /></Link>,
//         title:t("Alerts.Activity"),
//     },
//     {
//       key: '/alerts/inactivity',
//       icon: <Link to="/alerts/inactivity"><FontAwesomeIcon icon={faCarTunnel} /></Link>,
//       title:t("Alerts.Inactivity"),
      
//     },
//     {
//         key: '/alerts/Fuel-Level',
//         icon: <Link to="/alerts/Fuel-Level"><FontAwesomeIcon icon={faGasPump} /></Link>,
//         title:t("Niveau de carburant"),
//     },
// ];
const tabs2 = [
    {
        key: '/alerts/geofencing',
        icon: <Link to="/alerts/geofencing"><FontAwesomeIcon icon={faMapLocationDot} /></Link>,
        title:t("Geofencing"),
        
    },
    {
        key: '/alerts/speed',
        icon: <Link to="/alerts/speed"><FontAwesomeIcon icon={faGaugeHigh} /></Link>,
        title:t("speed2"),
        
    },
    {
        key: '/alerts/power-cut',
        icon: <Link to="/alerts/power-cut" ><FontAwesomeIcon icon={faPlug} /></Link>,
        title:t("power2"),
        
    },
];
  const tabs3 = [
    {
        key: '/alerts/idel',
        icon: <Link to="/alerts/idel"><FontAwesomeIcon icon={faGears} /></Link>,
        title:t("idle2"),
    },
    {
        key: '/alerts/crush',
        icon: <Link to="/alerts/crush"><FontAwesomeIcon icon={faCarBurst} /></Link>,
        title:t("Crush"),
        
    },
    {
        key: '/alerts/towing',
        icon: <Link to="/alerts/towing" ><FontAwesomeIcon icon={faTruckPickup} /></Link>,
        title:t("Towing"),
        
    },
];
  const { loading, error, data } = useQuery(GET_ALERTS, {
    variables: { _id: company, role: role, user: _id },fetchPolicy: "no-cache",
  });
  const [deletealert] = useMutation(DELETE_ALERT, {
    refetchQueries: [
      { query: GET_ALERTS, variables: { _id: company, role: role, user: _id } },
      { query: GET_NOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
      { query: GET_NOTREEDNOTIFICATIONS, variables: { _id: company, role: role, user: _id } },
    ],
  });
  const [activatalert] = useMutation(ACTIVATE_ALERT, {
    refetchQueries: [
      { query: GET_ALERTS, variables: { _id: company, role: role, user: _id } },
    ],
  });
  const [disactivatalert] = useMutation(DISACTIVATE_ALERT, {
    refetchQueries: [
      { query: GET_ALERTS, variables: { _id: company, role: role, user: _id } },
    ],
  });
  if (loading) return <Loading />;
  if (error) return <></>;
  const showDeleteConfirm = (_id) => {
    confirm({
      title: t("FormValidations.33"),
      icon: <ExclamationCircleFilled />,
      content: t("FormValidations.14"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deletealert({ variables: { _id, role } });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: t("Alerts.16"),
      dataIndex: "name",
      key: "model",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("InventoryTable.Created"),
      dataIndex: "created",
      key: "created",
      render: (text) => (
        text[0]!==""&&<div>
         <div>
           <Text>
            {text[0]}
           </Text>
         </div>
         <Text type="secondary">{t("By")+" "+text[1]}</Text>
       </div>
     ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
  ];

  const TableData = data.getAlerts.filter((alert) => {
    if (searchInput === "") {
      return alert;
    } else if (alert.name.toLowerCase().includes(searchInput)) {
      return alert;
    }
  });
  const AlertData = TableData.map((alert) => {
    return {
      key: alert._id,
      name: (
        <div className="InvDetBtn">
          <Text>{alert.name}</Text>
          <AlertDetails alert={alert}/>
        </div>
      ),
      type: t((alert.type)+"2"),
      status: t(alert.status),
      created: [dayjs(Math.floor(alert.createdAt / 1000) * 1000).format(
        "DD MMM YYYY HH:mm"
      ),alert.createdBy],
      action: (
        <>
            <Dropdown 
              menu={{
                items: [
                  {
                    label: t("DeleteAlert"),
                    key: "1",
                    danger: true,
                  },
                  {
                    label: "Désactiver l'alerte",
                    key: "2",
                  },
                  {
                    label: "Activer l'alerte",
                    key: "3",
                  },
                ],
                onClick:({ key }) => {
                  switch(key) {
                    case "1":
                      showDeleteConfirm(alert._id);
                      break;
                    case "2":
                      disactivatalert({ variables: { _id: alert._id } });
                      break;
                    case "3":
                      activatalert({ variables: { _id:alert._id } });
                      break;
                    default:
                      break;
                  }
                }
              }}
              autoAdjustOverflow
              placement="bottomRight"
              arrow={{
                pointAtCenter: true,
              }}
              trigger={["click"]}
            >
              <Button type="text" style={{width:"100%"}} ><EllipsisOutlined /></Button>
            </Dropdown>
        </>
      ),
    };
  });
  if (role==="User") {
    columns.pop()
  }
  return (
    <Content className="AlertComponentContent" >
      {isDesktop ? (<Table
        columns={columns}
        size="small"
        dataSource={AlertData}
        title={() => (
          <Row>
          <Col span={12}>
              <Input
              size="default"
              className="SearchBar"
              placeholder={t("AlertsSearchInput")+" ("+AlertData.length+t("elements")+")"}
              prefix={<SearchOutlined />}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            </Col>
              <Col span={12}>              
                <div className="ButtonsDiv">
                 {role!=="User"&&<Button type="primary" onClick={showModal}>
                 {isDesktop? <div><PlusOutlined /> {t("Add")}</div>:<div><PlusOutlined /></div>}
            </Button>}
                </div>
            </Col>
        </Row>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />):<>
      <Row style={{margin:10}}>
          <Col span={12}>
              <Input
              size="default"
              className="SearchBar2"
              placeholder={t("AlertsSearchInput")+" ("+AlertData.length+t("elements")+")"}
              prefix={<SearchOutlined />}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            </Col>
              <Col span={12}>              
                <div className="ButtonsDiv">
                 {role!=="User"&&<Button type="primary" onClick={showModal}>
                 {isDesktop? <div><PlusOutlined /> {t("Add")}</div>:<div><PlusOutlined /></div>}
            </Button>}
                </div>
            </Col>
        </Row>
      <AlertsComponentMobile AlertData={TableData} props={props.props}/></>}
            {isDesktop ? <Modal
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
              closable={false}
            >
              <Row gutter={[16, 16]}>
                <Col sm={24} md={8}>
                  <Link to={{ pathname: "/alerts/activity" }}>
                    <Card hoverable style={{ height: "158px",textAlign: "center",}}>
                      <div>
                        <Text strong>{t("Alerts.Activity")}</Text>
                      </div>
                      <Text type="secondary">{t("Alerts.ActivityText")}</Text>
                    </Card>
                  </Link>
                </Col>
                <Col sm={24} md={8}>
                  <Link to={{ pathname: "/alerts/geofencing" }}>
                    <Card hoverable style={{ height: "158px",textAlign: "center",}}>
                      <div>
                        <Text strong>Geofencing</Text>
                      </div>
                      <Text type="secondary">{t("Alerts.GeofencingText")}</Text>
                    </Card>
                  </Link>
                </Col>
                <Col sm={24} md={8} className="InactivityCard">
                    <Link to={{ pathname: "/alerts/inactivity" }}>
                      <Card  hoverable style={{ 
                        height: "158px",
                        textAlign: "center",
                       }}>
                        <div>
                          <Text strong>{t("Alerts.Inactivity")}</Text>
                        </div>
                        <Text type="secondary">
                          {t("Alerts.InactivityText")}
                        </Text>
                      </Card>
                    </Link>
                </Col>
                <Col sm={24} md={8} className="CarbDecreaseCard">
                    <Link to={{ pathname: "/alerts/Fuel-Level" }}>
                      <Card  hoverable style={{ 
                        height: "158px",
                        textAlign: "center",
                       }}>
                        <div>
                          <Text strong>{t("Niveau de carburant")}</Text>
                        </div>
                        <Text type="secondary">
                          {t("Recevoire une alerte en cas de diminution de niveau de carburant")}
                        </Text>
                      </Card>
                    </Link>
                </Col>
                <Col sm={24} md={8} className="speedCard">
                    <Link to={{ pathname: "/alerts/speed" }}>
                      <Card  hoverable style={{ 
                        height: "158px",
                        textAlign: "center",
                       }}>
                        <div>
                          <Text strong>{t("speed2")}</Text>
                        </div>
                        <Text type="secondary">
                          {t("Recevoire une alerte en cas de dépassement de vitesse limite")}
                        </Text>
                      </Card>
                    </Link>
                </Col>
                <Col sm={24} md={8} className="powerCard">
                    <Link to={{ pathname: "/alerts/power-cut" }}>
                      <Card  hoverable style={{ 
                        height: "158px",
                        textAlign: "center",
                       }}>
                        <div>
                          <Text strong>{t("power2")}</Text>
                        </div>
                        <Text type="secondary">
                          {t("Recevoire une alerte en cas d'appareil débranché")}
                        </Text>
                      </Card>
                    </Link>
                </Col>
                <Col sm={24} md={8} className="idelCard">
                    <Link to={{ pathname: "/alerts/idel" }}>
                      <Card  hoverable style={{ 
                        height: "158px",
                        textAlign: "center",
                       }}>
                        <div>
                          <Text strong>{t("idle2")}</Text>
                        </div>
                        <Text type="secondary">
                          {t("Recevoir une alerte en cas d'excès de ralentie")}
                        </Text>
                      </Card>
                    </Link>
                </Col>
                <Col sm={24} md={8} className="crushCard">
                    <Link to={{ pathname: "/alerts/crush" }}>
                      <Card  hoverable style={{ 
                        height: "158px",
                        textAlign: "center",
                       }}>
                        <div>
                          <Text strong>{t("Crush")}</Text>
                        </div>
                        <Text type="secondary">
                          {t("Recevoir une alerte en cas de possibilité d'accident")}
                        </Text>
                      </Card>
                    </Link>
                </Col>
                <Col sm={24} md={8} className="towingCard">
                    <Link to={{ pathname: "/alerts/towing" }}>
                      <Card  hoverable style={{ 
                        height: "158px",
                        textAlign: "center",
                       }}>
                        <div>
                          <Text strong>{t("Towing")}</Text>
                        </div>
                        <Text type="secondary">
                          {t("Recevoir une alerte en cas de remorquage")}
                        </Text>
                      </Card>
                    </Link>
                </Col>
              </Row>
            </Modal>:<MobileModal
          className="TabsModal"
          visible={isModalOpen}
          closeOnMaskClick={true}
          closeOnAction={true}
          onClose={() => {
            setIsModalOpen(false)
          }}
          content={
            <>
            {/* <TabBar  activeKey={null} onChange={()=>{setIsModalOpen(false)}} style={{margin:'10px 0 20px 0'}}>
              {tabs1.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge}/>))}
            </TabBar> */}
            <TabBar className="TAB2" activeKey={null} onChange={()=>{setIsModalOpen(false)}} style={{margin:'20px 0 10px 0'}}>
              {tabs2.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge}/>))}
            </TabBar>
            <TabBar activeKey={null} onChange={()=>{setIsModalOpen(false)}} style={{margin:'20px 0 10px 0'}}>
              {tabs3.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge}/>))}
            </TabBar>
            </>
          }
        />}
    </Content>
  );
}
export default Alerts;
