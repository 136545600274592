import { gql } from "@apollo/client";

const GET_ROLES = gql`
  query Get_Roles($role: String!) {
    getRoles(role: $role) {
      _id
      name
    }
  }
`;
const GET_ROLE = gql`
  query Get_Role($name:String,$role: String!) {
    getRole(name:$name,role: $role) {
      _id
      name
    }
  }
`;
export { GET_ROLES, GET_ROLE };
