/* eslint-disable array-callback-return */
import { React, useState, useEffect,useContext } from "react";
import { ValueContext } from "../../context/Context";
import { useApolloClient } from '@apollo/client';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import {
  Input,
  Typography,
  Table,
  Button,
  Layout,
  Tooltip,
  Switch,
  Space,
  Modal,
  notification,Row,Col
} from "antd";
import { useQuery , useMutation } from "@apollo/client";
import { GET_VEHICLES } from "../../queries/mapQuery";
import {
  SearchOutlined,
  InsertRowLeftOutlined,
  ExclamationCircleFilled,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import "../../css/Inventory.css";
import dayjs from "dayjs";
import Loading from "../../components/Loading/Loading";
import { convertToHoursMins } from "../../constants/ConvertHora";
import CreateAssetForm from "../../components/Forms/CreateAssetForm";
import { DELETE_ASSET } from "../../mutations/assetMutations";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

function CarbReful(props) {
  const { t } = useTranslation();
  const client = useApolloClient();  
  const { isDesktop } = useContext(ValueContext);
  const [CarbRefuelcheckedColumns, setCarbRefuelcheckedColumns] = useState(
    JSON.parse(localStorage.getItem("CarbRefuelcheckedColumns")) || []
  );
  const Displayedcolumns = [
    {
        title: t("Date"),
        dataIndex: "date",
        width: 'max-content',
    },
    {
        title: t("InventoryTable.SerialNumber"),
        dataIndex: "serialNumber",
        width: 'max-content',
    },
    {
        title: t("InventoryTable.Model"),
        dataIndex: "model",
        width: 'max-content',
    },
    {
        title: t("Espace de travail"),
        dataIndex: "ET",
        width: 'max-content',
    },
    {
        title: t("Horamètre"),
        dataIndex: "hourmeter",
    },
    {
        title: t("Odomètre"),
      dataIndex: "odometer",
    },
    {
        title: t("H. de fonctionnement"),
        dataIndex: "HF",
    },
    {
        title: t("Distance Parcourue"),
        dataIndex: "DP",
    },
    {
      title: t("Niveau de carb (avant)"),
      dataIndex: "NCAV",
    },
    {
      title: t("Niveau de carb (après)"),
      dataIndex: "NCAP",
    },
    {
        title: t("Qté de carb"),
        dataIndex: "QC",
    },
    {
        title: t("Conso. moy"),
        dataIndex: "CM",
    },
    {
      title: t("Position"),
      dataIndex: "position",
    },
];
  const columns = [
    {
        title: t("Date"),
        dataIndex: "date",
        width: 'max-content',
    },
    {
        title: t("InventoryTable.SerialNumber"),
        dataIndex: "serialNumber",
        width: 'max-content',
    },
    {
        title: t("InventoryTable.Model"),
        dataIndex: "model",
        width: 'max-content',
    },
    {
        title: t("Espace de travail"),
        dataIndex: "ET",
        width: 'max-content',
    },
    {
        title: t("Horamètre"),
        dataIndex: "hourmeter",
    },
    {
        title: t("Odomètre"),
      dataIndex: "odometer",
    },
    {
        title: t("H. de fonctionnement"),
        dataIndex: "HF",
    },
    {
        title: t("Distance Parcourue"),
        dataIndex: "DP",
    },
    {
      title: t("Niveau de carb (avant)"),
      dataIndex: "NCAV",
    },
    {
      title: t("Niveau de carb (après)"),
      dataIndex: "NCAP",
    },
    {
        title: t("Qté de carb"),
        dataIndex: "QC",
    },
    {
        title: t("Conso. moy"),
        dataIndex: "CM",
    },
    {
      title: t("Position"),
      dataIndex: "position",
    },
].filter((col) =>
CarbRefuelcheckedColumns.every(
    (y) => !col.dataIndex.toLowerCase().includes(y.toLowerCase())
    )
  );
  const [searchInput, setSearchInput] = useState("");
  const { company, role, workspaces } = props.props;
  const { loading, error, data } = useQuery(GET_VEHICLES, {
    variables: { _id: company, role: role, workspace: workspaces },
  });
  const successNotification = () => {
    notification.open({
      description: t("Notification.11"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const showDeleteConfirm = (_id) => {
    confirm({
      title: t("Maintenance.26"),
      icon: <ExclamationCircleFilled />,
      content: t("Maintenance.27"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deleteAsset({ variables: { _id,role } });
      },
    });
  };
  const [deleteAsset] = useMutation(DELETE_ASSET, {
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_VEHICLES,
        variables: { _id: company, role: role, workspace: workspaces },
      },
    ],
  });
  const socket = io({ path: "/socket.io", transports: ["websocket"] });
  useEffect(() => {
    if (socket) {
      socket.on(company+'updateVehicle', (updatedVehicle) => {
        const updatedVehicles = data.getVehicles&&data.getVehicles.map((vehicle) =>
        vehicle._id === updatedVehicle._id ? updatedVehicle : vehicle
      );
        // update the data in the Apollo cache
        client.writeQuery({
          query: GET_VEHICLES,
          variables: { _id: company, role: role, workspace: workspaces },
          data: { getVehicles: updatedVehicles },
        });
      });
    }
    return () => {
      if (socket) {
        socket.off(company+'updateVehicle');
      }
    };
  }, [socket, data, client, company, role, workspaces]);

  if (loading) return <Loading />;
  if (error) return <></>;
  const ConsoData = [
    {
      key: '1',
      date: (
        <div className="InvDetBtn">
          <Text>{'24 août 2023 13:54'}</Text>
            <Link
              to={{ pathname: "/Carb-Reful/details" }}
            //state={{ vehicle: vehicle, path: "/inventory" }}
             onContextMenu={(e) => {e.preventDefault(); }}
            >
          <Button>
              {t("Details")}
          </Button>
            </Link>
        </div>
      ),
      serialNumber: '182 Tun 4582',
      model: 'Actros',
      ET: 'Djerba',
      position: 'نهج محمد بدرة, ميدون, آركو, معتمدية جربة ميدون, ولاية مدنين, 4165, تونس',
      hourmeter: '8894 h 0 min',
      odometer: '-',
      HF: '20 h 0 min',
      DP: '-',
      NCAV: '20 L',
      NCAP: '400 L',
      QC: '380 L',
      CM: '35 L/h',
    },
  ];
//   let InventoryData = [];

//   if (data.getVehicles.length > 0) {
//     const TableData = data.getVehicles.filter((vehicle) => {
//       if (searchInput === "") {
//         return vehicle;
//       } else if (vehicle.profile.Model.toLowerCase().includes(searchInput.toLowerCase())) {
//         return vehicle;
//       }
//     });
//     InventoryData = TableData.map((vehicle) => {
//       return {
//         key: vehicle._id,
//         model: vehicle.profile.Model,
//         category: vehicle.profile.Categorie,
//         marque: vehicle.profile.Marque,
//         serialNumber: (
//           <div className="InvDetBtn">
//             <Text>{vehicle.profile.serialNumber}</Text>
//               <Link
//                 to={{ pathname: "/profile" }}
//               state={{ vehicle: vehicle, path: "/inventory" }}
//                onContextMenu={(e) => {e.preventDefault(); }}
//               >
//             <Button>
//                 {t("Details")}
//             </Button>
//               </Link>
//           </div>
//         ),
//         horametre: (vehicle.hourMeter)&&convertToHoursMins(vehicle.hourMeter),
//         odometer: (vehicle.odometer)&& parseFloat(vehicle.odometer).toFixed(2)>0?parseFloat(vehicle.odometer).toFixed(2)+" Km":0+" Km",
//         status: [vehicle.status,vehicle.IgnitionStatus?vehicle.IgnitionStatus:null],
//         address: [
//           vehicle.address,
//           dayjs(Math.floor(vehicle.updatedAt / 1000) * 1000).format(
//             "DD MMM YYYY HH:mm"
//           ),
//           vehicle.zone
//         ],
//         workspace:<div style={{ textAlign: "center" }}>{vehicle.workspace?.name ?? "-"}</div>,
//         company:role==="Super Admin"?<div style={{ textAlign: "center" }}>{vehicle?.company?.name ?? "-"}</div>:"",
//         createdAt:[dayjs(Math.floor(vehicle.profile.createdAt / 1000) * 1000).format(
//             "DD MMM YYYY HH:mm"
//           ),vehicle.createdBy],
//         actions:  (role !== "AdminWorkspace"&&role !== "User")&&(
//           <>
//             <Dropdown
//               menu={{
//                 items: [
//                   {
//                     label: (
//                       <Button
//                         type="text"
//                         block
//                         onClick={() => {
//                           showDeleteConfirm(vehicle._id);
//                         }}
//                       >
//                         <Text type="danger">{t("Maintenance.28")}</Text>
//                       </Button>
//                     ),
//                     key: "2",
//                   },
//                 ],
//               }}
//               trigger={["click"]}
//             >
//               <Button type="text" icon={<EllipsisOutlined />}></Button>
//             </Dropdown>
//           </>
//         ),
//       };
//     });
//   }
  const onChange = (checked, value) => {
    if (checked) {
      let x = CarbRefuelcheckedColumns.filter((x) => x !== value);
      setCarbRefuelcheckedColumns(x);
      localStorage.setItem("CarbRefuelcheckedColumns", JSON.stringify(x));
    } else {
      setCarbRefuelcheckedColumns([...CarbRefuelcheckedColumns, value]);
      localStorage.setItem(
        "CarbRefuelcheckedColumns",
        JSON.stringify([...CarbRefuelcheckedColumns, value])
      );
    }
  };
  return (
    <Content className="InvContent">
      <Table
        columns={role !== "Super Admin" ? (columns.filter(option=>option.title!==t("company"))):columns}
        size="small"
        dataSource={ConsoData}
        title={() => (
          <>
          <Row>
          <Col span={12}>
              <Input
                size="default"
                className="InventorySearchBar"
                placeholder={t("SearchInput")+" ("+ConsoData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              /></Col>
              <Col span={12}>              
              <div className="ButtonsDiv">
                  <Tooltip
                    color={"white"}
                    trigger={"click"}
                    placement="bottomRight"
                    title={
                      <div>
                      {
                        Displayedcolumns.map((column)=>{
                          return(<div>
                          <Space size={"small"}>
                            <Switch
                              size="small"
                              value={column.dataIndex}
                              checked={
                                !CarbRefuelcheckedColumns.some(
                                  (code) => code === column.dataIndex
                                )
                              }
                              onChange={(checked) =>
                                onChange(checked, column.dataIndex)
                              }
                            />
                            <Text>{column.title}</Text>
                          </Space>
                        </div>)
                        })
                      }
                      </div>
                    }
                  >
                    <Button style={{ marginRight:'5px' }}>
                    {isDesktop?<div><InsertRowLeftOutlined style={{ marginRight:10 }}/>{t("InventoryTable.Columns")}</div>:<div><InsertRowLeftOutlined /></div>}
                    </Button>
                  </Tooltip>
                  <Button style={{ marginRight:'5px' }}>
                    {isDesktop?<div><VerticalAlignBottomOutlined style={{ marginRight:10 }} />{t("Export")}</div>:<div><InsertRowLeftOutlined /></div>}
                    </Button>
                  </div></Col>
                  </Row>
                </>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />
    </Content>
  );
}
export default CarbReful;
