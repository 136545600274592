/* eslint-disable array-callback-return */
import { React, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button, Form, Modal,  notification } from "antd";
import { useMutation } from "@apollo/client";
import { PlusOutlined } from "@ant-design/icons";
import { ADD_COMPANY } from "../../mutations/companyMutation";
import { GET_COMPANYS } from "../../queries/companyQuery";
import { ValueContext } from "../../context/Context";
function CreateCompanyForm(props) {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ValueContext);
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    notification.open({
      description: t("Notification.10"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const { role} = props.props;
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [adduser] = useMutation(ADD_COMPANY, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_COMPANYS,
        variables: { role: role },
      },
    ],
  });

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        {isDesktop? <div><PlusOutlined /> {t("Add")}</div>:<div><PlusOutlined /></div>}
      </Button>
      <Modal
        open={open}
        title={t("AddCompany")}
        okText={t("Alerts.10")}
        cancelText={t("Alerts.9")}
        style={{
          top: 20,
        }}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              adduser({
                variables: {
                  name: values.name,
                  description: values.description,
                  role: role,
                },
              });

              setOpen(false);

              form.resetFields();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="name"
            label={t("Administration.name")}
            rules={[
              {
                required: true,
                message: t("FormValidations.34"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: t("FormValidations.35"),
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default CreateCompanyForm;
