import { gql } from "@apollo/client";

const GET_MARQUES = gql`
  query Get_Marques($company: ID, $role: String) {
    getMarques(company: $company, role: $role) {
      _id
      name
    }
  }
`;
export { GET_MARQUES };