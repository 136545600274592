import { gql } from "@apollo/client";

const GET_CATEGORIES = gql`
  query Get_Categories($company: ID, $role: String) {
    getCategories(company: $company, role: $role) {
      _id
      name
    }
  }
`;
export { GET_CATEGORIES };