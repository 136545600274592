import { gql } from "@apollo/client";

const GET_COMPANYS = gql`
  query Get_Companys( $role: String!) {
    getCompanys( role: $role) {
      _id
      name
      description
    }
  }
`;
const GET_COMPANY = gql`
  query Get_Company($role: String!) {
    getCompany(role: $role) {
      _id
      name
    }
  }
`;
export { GET_COMPANYS, GET_COMPANY };
