import { gql } from "@apollo/client";


const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($_id: ID) {
    updateNotification(_id: $_id) {
      _id
    }
  }
`;
const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($_id: ID, $role: String) {
    deleteNotification(_id: $_id, role: $role) {
      _id
      name
    }
  }
`;

export { UPDATE_NOTIFICATION, DELETE_NOTIFICATION };
