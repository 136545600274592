import React, { useState ,useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom"
import { useLazyQuery } from '@apollo/client';
import { LOGIN } from '../../queries/UserQuery';
import { useDispatch } from 'redux-react-hook';
import * as actions from '../../constants/action_types';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input,Divider,Typography } from "antd";
import { Layout,Card } from "antd";
import '../../css/auth.css'
import axios from "axios";
import Loading from '../../components/Loading/Loading';
import { ValueContext } from '../../context/Context';
const { Content,Header } = Layout;
const { Title, Text } = Typography;


function Login() {
    const { t } = useTranslation();
    const { isDesktop } = useContext(ValueContext);
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false);
    const [loading2,setLoading2] = useState(true);
    const navigate = useNavigate()
    const dispatch = useDispatch();
  const [login, { error }] = useLazyQuery(LOGIN);
  const subscription = JSON.parse(localStorage.getItem("subscription"));
console.log("from login : ",subscription);

    useEffect(() => {
      setTimeout(() => {
        setLoading2(false);
      }, 500); // 5 seconds delay
    }, []);

    const handleClick = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 5000); // 5 seconds delay
    };

    const submit = async () => {
      form
      .validateFields()
      .then(async (values) => {
          const { data } = await login({
            variables: {
              email: values.email,
              password:values.password
            },
          });
          const { _id, token,role,company,workspace } = data.login;
          const workspaces = workspace.map((ele) => ele._id);
          role.name==="Admin"?localStorage.setItem("nameworkspace",company.name ):localStorage.setItem("nameworkspace", workspace[0]&&workspace[0].name);
            dispatch({
              type: actions.SET_AUTH_USER,
              authUser: Object.freeze({
                _id,
                email: values.email,
                role: role.name,
                company: company ? company._id : null,
                companyname: company?.name ? company.name : null,
                workspaces: role.name==="Admin"?[]: workspaces?.length > 0 ? workspaces[0] : workspaces,
                fullworkspaces: workspace,
                allworkspaces: workspaces,
              }),
            });
            role.name==="Admin"?localStorage.setItem("worckID", JSON.stringify([])):localStorage.setItem('worckID', (workspaces.length > 0) ? workspaces[0]:JSON.stringify([]));
        localStorage.setItem('token', token);
        if (subscription) {
          
          // Send the token to your server
          const requestBody = {
            query: `
            mutation {
              updateUserSub(subscriptions: {
                endpoint: "${subscription.endpoint}",
                expirationTime: ${subscription.expirationTime ? `"${subscription.expirationTime}"` : null},
                keys: {
                  p256dh: "${subscription.keys.p256dh}",
                  auth: "${subscription.keys.auth}"
                  }
                  }, _id: "${_id}") {
                    _id
                    }
                    }
                    `,
                  };
                  
                  const { data1 } = await axios.post(
                    "/graphql",
                    requestBody
                  );
                  
                }
            navigate("/map");
      })
      .catch((info) => {
        setLoading(false);
        console.log("Validate Failed:", info);
      });
        }

if (loading2) return <Loading />;    

    return (
        <>
                {isDesktop&&<Header className='loginHeader'>
        <div className="nav-wrapper w-container">
        <div className="nav-logo">
            <a href="/" className="link-block-header-logo w-inline-block w--current"><Title level={4}>DeviceSpeak</Title></a>
          </div>
          <nav class="nav-links nav-menu">
          <a href="/" className="nav-link-block w-inline-block">
            <div className="text-block-3">Home</div>
          </a>
          <a href="/login" className="nav-link-block w-inline-block">
            <div className="text-block-3">{t("Login")}</div>
          </a>
          </nav>
        </div>
        </Header>}
    <Content className='PassContent'
          style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: "600px",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
        <Card className="Authcard"  bordered={true}   style={{
          textAlign: "center",
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
  }} >
        <Title level={4}>{t("Welcome")}</Title>
        <Divider />
        <Form
        layout="vertical"
        form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={submit}
    >
            <Form.Item
            name="email"
            label={t("FormeAddUser.lableforemail")}
            rules={[
              {
                type: "email",
                message: t("FormValidations.16"),
              },
              {
                required: true,
                message: t("FormValidations.15"),
              },
            ]}
          >
              <Input
                prefix={<UserOutlined  />}
                className='authInput'
                style={{width: "100%",}}
                placeholder="Email"
                type="email"
              />
            </Form.Item>
            <Form.Item
            name="password"
            label={t("FormeAddUser.lableforpassword")}
            rules={[
              {
                required: true,
                message: t("FormValidations.17"),
              },
            ]}
              style={{
                  width: "100%",
                }}
            >
              <Input.Password
                prefix={<LockOutlined/>}
                style={{width: "100%",marginBottom:24}}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button block ghost
                style={{
                  textAlign: "center",
                  width: "100%",
                  borderRadius: "5px",

                }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                onClick={handleClick}
              >
                Log in
              </Button>
            </Form.Item>
            {error&&<Text type='danger'>{t(error.message)}</Text>}
            </Form>
          <div>
          <Link to={"/reset-password"}><Text type="secondary" style={{ fontSize:"12px" }}>{t("Forgot")}</Text></Link>
          </div>
          <div style={{ marginTop:"20px",textAlign:"initial"}}>
          <Text type="secondary" style={{ fontSize:"12px" }}>{t("What")}</Text>
          </div>
        </Card>
      </Content>
        </>
    )
}

export default Login;