import { React, useState } from "react";
import { Link ,useLocation } from "react-router-dom";
import { Breadcrumb, Space, Typography, Row, Col,Select } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { GET_VEHICLE } from "../../queries/mapQuery";
import { UPDATE_ASSET } from "../../mutations/assetMutations";
import { Input, Button, Form, Modal, Checkbox, notification } from "antd";
import { useMutation,useQuery } from "@apollo/client";
import { EditOutlined } from "@ant-design/icons";
import { GET_CATEGORIES } from "../../queries/categorieQuery";
import { GET_MARQUES } from "../../queries/marqueQuery";

const { Text } = Typography;

function Breadcrumbs(props) {
  let location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const { role,company } = props.props.props
  const data = props.data
  const successNotification = () => {
    notification.open({
      description: t("Notification.2"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const [open, setOpen] = useState(false);
  const [componentDisabled, setComponentDisabled] = useState(false);
  const [form] = Form.useForm();
  const [updateAsset] = useMutation(UPDATE_ASSET, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      { query: GET_VEHICLE, variables: { _id: data.getVehicle._id } },
    ],
  });
     const { loading:loadingC, error:errorC, data:dataC } = useQuery(GET_CATEGORIES, {
     variables: { company: company, role: role },
   });
   const { loading:loadingM, error:errorM, data:dataM } = useQuery(GET_MARQUES, {
     variables: { company: company, role: role },
   });
  let usagehr = data.getVehicle.profile.usage === "hr"
  let Name = "";
  let zone
  if (props.path === "/inventory") {
    Name = t("SideBar.Inventory");
  } else if(props.path==="/map") {
    Name = t("SideBar.Map");
  } else {
    Name = "zone"
    zone="/zone"
  }
   const catoptions = [];
   dataC &&
     dataC.getCategories.map((ele) => {
       catoptions.push({
         value: ele.name,
         label: ele.name,
       });
     });
   const marqueoptions = [];
   dataM &&
     dataM.getMarques.map((ele) => {
       marqueoptions.push({
         value: ele.name,
         label: ele.name,
       });
     });
  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to={zone?zone:props.path}>
                <div className="bredIcon">
                  <Space>
                    <LeftOutlined />
                    {Name}
                  </Space>
                </div>
              </Link>
            ),
          },
        ]}
      />
      <div>
        <Space>
          <div className="ProfileSNumber">
            <Text>{data.getVehicle.profile.Model}</Text>
          </div>
          <Text mark>{data.getVehicle.profile.Categorie}</Text>
        </Space>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={16}>
        <div style={{ display:'inline-block',width:'100%' }}>
          <Text type="secondary">{data.getVehicle.profile.Marque} - </Text>
          <Text type="secondary">{data.getVehicle.profile.serialNumber}</Text>
        </div>
        </Col>
        {(role!=="User"&&path==="/profile")&&<Col span={8}>
        <div className="ButtonsDiv">
          <Button
            type="text"
            onClick={() => {
              setOpen(true);
            }}
          >
            <EditOutlined /> {t("Edit")}
          </Button></div>
        </Col>}
          <Modal
            open={open}
            title={t("FormValidations.45")}
            okText={t("Alerts.10")}
            cancelText={t("Alerts.9")}
            style={{
              top: 20,
            }}
            onCancel={() => {
              setOpen(false);
            }}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  updateAsset({
                    variables: {
                      _id: data.getVehicle._id,
                      serialNumber: values.serialNumber,
                      Model: values.Model,
                      Marque: values.Marque,
                      Categorie: values.Categorie,
                      hourMeter: parseInt(values.hourMeter),
                      odometer: parseInt(values.odometer),
                      Fuel: parseInt(values.fuel),
                      Source: values.Source,
                      ischange: (parseInt(values.hourMeter)!==parseInt(Math.floor(data.getVehicle.hourMeter / 60))),
                      ischange1: (parseInt(values.odometer)!==parseInt(Math.floor(data.getVehicle.odometer))),
                      assetID: values.IMEI,
                    },
                  });
                  setOpen(false);
                  // form.resetFields();
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
          >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                serialNumber: data.getVehicle.profile.serialNumber,
                Model: data.getVehicle.profile.Model,
                Marque: data.getVehicle.profile.Marque,
                Categorie: data.getVehicle.profile.Categorie,
                hourMeter: parseInt(Math.floor(data.getVehicle.hourMeter / 60)),
                odometer: parseInt(Math.floor(data.getVehicle.odometer)),
                fuel: parseInt(data.getVehicle.profile.Fuel),
                Source: data.getVehicle.Source,
                IMEI: data.getVehicle.assetID,
              }}
            >
              <Form.Item
                name="serialNumber"
                label={t("InventoryTable.SerialNumber")}
                rules={[
                  {
                    required: true,
                    message:  t("FormValidations.1"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="Marque"
                label={t("InventoryTable.Marque")}
                rules={[
                  {
                    required: true,
                    message:  t("FormValidations.2"),
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  options={marqueoptions}
                />
              </Form.Item>
              <Form.Item
                name="Model"
                label={t("InventoryTable.Model")}
                rules={[
                  {
                    required: true,
                    message:  t("FormValidations.3"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="Categorie"
                label={t("InventoryTable.Category")}
                rules={[
                  {
                    required: true,
                    message:  t("FormValidations.4"),
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  options={catoptions}
                />
              </Form.Item>
              <Form.Item
                name="hourMeter"
                label={t("InventoryTable.Horametre")}
                rules={[
                  {
                    required: true,
                    message:  t("FormValidations.5"),
                  },
                ]}
              >
                <Input
                  type="number"
                  addonAfter={t("FormValidations.10")}
                />
              </Form.Item>
              <Form.Item
            name="odometer"
            label={t("InventoryTable.Odometre")}
            rules={[
              {
                required: true,
                message: t("FormValidations.42"),
              },
            ]}
          >
            <Input type="number" addonAfter={"Km"} />
          </Form.Item>
              <Form.Item
            name="fuel"
            label={t("Fuelconsumption")}
            rules={[
              {
                required: true,
                message: t("FormValidations.27"),
              },
            ]}
          >
            <Input type="number" addonAfter={data.getVehicle.profile.usage==="hr" ? t("hr"):t("km") } />
          </Form.Item>
              <Checkbox
                checked={componentDisabled}
                onChange={(e) => setComponentDisabled(e.target.checked)}
              >
                Edit Source
              </Checkbox>
              <Form.Item
                name="Source"
                label="Source"
                rules={[
                  {
                    required: true,
                    message:  t("FormValidations.6"),
                  },
                ]}
              >
                <Select disabled={!componentDisabled}
              style={{
                width: "100%",
              }}
              options={[{value: "DS1", label:"DS1"},
              {value: "Teltonika FMB140", label:"Teltonika FMB140"},
              {value: "Teltonika FMB920", label:"Teltonika FMB920"},]}
            />
              </Form.Item>
              <Form.Item
                name="IMEI"
                label="IMEI"
                rules={[
                  {
                    required: true,
                    message:  t("FormValidations.7"),
                  },
                ]}
              >
                <Input disabled={!componentDisabled} />
              </Form.Item>
            </Form>
          </Modal>
      </Row>
    </>
  );
}

export default Breadcrumbs;
