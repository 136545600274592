/* eslint-disable array-callback-return */
import { React,useContext,useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";
import { Typography, Space, Divider, Input, Form, Select, Button ,Checkbox,notification,Radio} from "antd";
import { GET_VEHICLES_FOR_ADMINISTRATION_PAGE } from "../../queries/mapQuery";
import { GET_USERS_FOR_ADMINISTRATION ,GET_ADMINUSER } from "../../queries/UserQuery";
import { GET_ALERTS } from "../../queries/alertQuery";
import { GET_ZONES_FOR_GEOFENCE } from "../../queries/zoneQuery";
import { ADD_GEOFENCE } from "../../mutations/geofenceMutation";
import { useQuery, useMutation} from "@apollo/client";
import { Link,useNavigate } from "react-router-dom";
import "../../css/Alerts.css";
import GeofenceMap from "../../components/map/geofenceMap";
import Loading from "../../components/Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ValueContext } from "../../context/Context";
import { Button as MobileBtn } from "antd-mobile";
const { Content } = Layout;
const { Text, Title } = Typography;

function GeofencingAlerts(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { company, role ,_id,workspaces} = props.props;
  const { isDesktop } = useContext(ValueContext);
  const [name, setname] = useState();
  const [user, setUser] = useState([]);
  const [vehicle, setvehicle] = useState([]);
  const [typeshape, settypeshape] = useState();
  const [coordinates, setcoordinates] = useState();
  const [radius, setradius] = useState();
  const [condition, setcondition] = useState();
  const [zone, setzone] = useState("zone");
  const [e, sete] = useState();
  const onChange = (checkedValues) => {
    setcondition(checkedValues.toString());
  };
  const options = [
    {
      label: t("Alerts.22"),
      value: "Out",
    },
    {
      label: t("Alerts.21"),
      value: "In",
    },
  ];
  const {
    loading: loadingv,
    error: errorv,
    data: datav,
  } = useQuery(GET_VEHICLES_FOR_ADMINISTRATION_PAGE, {
    variables: { _id: company, role: role, workspace: workspaces },
  });
  const {
    loading: loadingz,
    error: errorz,
    data: dataz,
  } = useQuery(GET_ZONES_FOR_GEOFENCE, {
    variables: { _id: company, role: role },
  });
  const { loading: loadingu, error: erroru, data: datau } =
    useQuery(GET_USERS_FOR_ADMINISTRATION, {
      variables: { _id: company, role: role,workspace:workspaces },
    });
  const successNotification = () => {
    notification.open({
      description: t("Notification.16"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const [addAlert] = useMutation(ADD_GEOFENCE,{
    refetchQueries: [{ query: GET_ALERTS, variables: { _id: company, role: role, user: _id } }],
    onCompleted: () => {
      successNotification();
    },
  });
  const  { loading: loadinga, error: errora, data: dataa } =
    useQuery(GET_ADMINUSER, {
      variables: { _id: company},
    });
  const submit = async () => {
    if (!typeshape) {
      sete("error")
      return
    }
   dataa.getAdminUser.map((element) => {
     if (!user.includes(element)) {
       user.push(element);
     }
   });
     if (!user.includes(_id)) {
       user.push(_id);
     }
    const { error, data }= await addAlert({
      variables:
        { user,
          vehicle: vehicle ? (vehicle.includes("All") ? (vehicleoptions.map(option => option.value)):vehicle): [],
          type: "Geofence",typeshape, name, role,company,coordinates,radius,createdBy:_id ,condition}
  })
    if (data) {
      navigate("/alerts");
    } else {
      return <p>{ error }</p>
    }
  }
  if (loadinga) return <Loading />;
  if (errora) return <p> Error : {errora}</p>;
  if (loadingu) return <Loading />;
  if (erroru) return <p> Error : {erroru}</p>;
  if (loadingv) return <Loading />;
  if (errorv) return <p> Error : {errorv}</p>;
  if (loadingz) return <Loading />;
  if (errorz) return <p> Error : {errorz}</p>;
  const vehicleoptions = [];
   datav &&
     datav.getVehicles.map((ele) => {
       vehicleoptions.push({
         value: ele._id,
         label: ele.profile.Model + " / " + ele.profile.serialNumber,
       });
     });
   const useroptions = [];
   datau &&
     datau.getUsers.map((ele) => {
       useroptions.push({
         value: ele._id,
         label: ele.email,
       });
     });
   const zoneoptions = dataz?.getZones.map((ele,i) => ({
     value: i,
     label: ele.name,
   }));
  function creteshape(type,coordinates,radius) {
    settypeshape(type);
    setcoordinates(coordinates);
    setradius(radius);
    sete()
  }
 
  const selectProps = {
    mode: "multiple",
    style: {
      width: "70%",
    },
    vehicle,
    options: [{value: "All", label: t("AllEquipment")},...vehicleoptions],
    onChange: (newValue) => {
      setvehicle(newValue);
    },
    filterOption: (inputValue, option) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    },
    placeholder: t("Alerts.30"),
    maxTagCount: "responsive",
  };
  const selectProps2 = {
    mode: "multiple",
    style: {
      width: "70%",
    },
    user,
    options: useroptions,
    onChange: (newValue) => {
      setUser(newValue);
    },
    filterOption: (inputValue, option) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    },
    placeholder: t("Alerts.14"),
    maxTagCount: "responsive",
  };
  const selectProps3 = {
    style: {
      width: "70%",
    },
    options: zoneoptions,
    onChange: (newValue) => {
      setcoordinates(dataz.getZones[newValue].coordinates);
      settypeshape("polygon");
    },
    filterOption: (inputValue, option) => {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    },
    placeholder: t("Alerts.14"),
  };
  return (
    <Content className="GeoContent">
      {!isDesktop&&<MobileBtn className='RHistoryBtn3' size='mini' shape='rounded'
          onClick={()=>{
            navigate(-1);
            }} >
          <FontAwesomeIcon icon={faArrowLeft} />
          </MobileBtn>}
      <Title level={2}>{t("Alerts.15")}</Title>
      <Divider />
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={submit}
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Text strong>{t("Alerts.16")}</Text>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t("FormValidations.34"),
              },
            ]}
          >
            <Input
              style={{
                width: "30%",
              }}
              placeholder="Name"
              onChange={(e) => {
                setname(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="check"
            rules={[
              {
                required: true,
                message: t("FormValidations.36"),
              },
            ]}
          >
            <Checkbox.Group
              options={options}
              defaultValue={["Pear"]}
              onChange={onChange}
            />
          </Form.Item>
          <Text strong>{t("Alerts.17")}</Text>
          <Radio.Group name="radiogroup" defaultValue="zone" onChange={(e) => {
            setzone(e.target.value);
            zone === "new" ? settypeshape("polygon") : settypeshape("");
            setcoordinates()
          }}>
            <Radio value="zone">{t("Alerts.25") }</Radio>
            <Radio value="new">{t("Alerts.26") }</Radio>
          </Radio.Group>
          {zone!=="zone"?<><GeofenceMap creteshape={creteshape} />
            <div style={{ color: "red" }}>{e && t("FormValidations.37")}</div></> :
            <Form.Item
            name="radio"
            rules={[
              {
                required: true,
                message: t("FormValidations.36"),
              },
            ]}
          >
              
              <Select {...selectProps3} />
          </Form.Item>
          }
          <div>
            <Text strong>{t("Alerts.5")}</Text>
          </div>
          <div>
            <Text type="secondary">{t("Alerts.6")}</Text>
          </div>
          <Form.Item
            name="selectVehicle"
            rules={[
              {
                required: true,
                message: t("FormValidations.36"),
              },
            ]}
          >
            <Select {...selectProps} />
          </Form.Item>
          <div>
            <Text strong>{t("Alerts.13")}</Text>
          </div>
          <Form.Item>
            <Select {...selectProps2} defaultValue={_id} />
          </Form.Item>
          <div style={{ marginTop: 20 }}>
            <Space direction="horizontal">
              <Link to={{ pathname: "/alerts" }}>
                <Button>{t("Alerts.9")}</Button>
              </Link>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("Alerts.10")}
                </Button>
              </Form.Item>
            </Space>
          </div>
        </Space>
      </Form>
    </Content>
  );
}
export default GeofencingAlerts;
