import { gql } from "@apollo/client";

const CREATE_MAINTENANCE = gql`
  mutation createMaintenance(
    $name: String!
    $operations: [String]
    $echeance: String!
    $seuil: String!
    $user: [ID]
    $vehicle: ID!
    $createdBy:ID
  ) {
    createMaintenance(
        name: $name
        operations: $operations
        echeance: $echeance
        seuil: $seuil
        user: $user
        vehicle: $vehicle
        createdBy: $createdBy
    ) {
      _id
      name
    }
  }
`;
const UPDATE_MAINTENANCE = gql`
  mutation updateMaintenance(
    $_id: ID!
    $name: String!
    $operations: [String]
    $echeance: String!
    $seuil: String!
    $user: [ID]
  ) {
    updateMaintenance(
        _id: $_id
        name: $name
        operations: $operations
        echeance: $echeance
        seuil: $seuil
        user: $user
    ) {
      _id
      name
    }
  }
`;
const DELETE_MAINTENANCE = gql`
  mutation deleteMaintenance(
    $_id: ID!
    $role: String!
  ) {
    deleteMaintenance(
      _id: $_id
      role: $role
    ) {
      _id
    }
  }
`;
const VALIDATE_MAINTENANCE = gql`
  mutation validateMaintenance(
    $_id: ID!
    $hourMeter:Int,
    $odometer:Float,
    $validatedby:ID
  ) {
    validateMaintenance(
      _id: $_id
      hourMeter:$hourMeter,
      odometer:$odometer,
      validatedby :$validatedby
    ) {
      _id
    }
  }
`;
export { CREATE_MAINTENANCE,DELETE_MAINTENANCE,UPDATE_MAINTENANCE,VALIDATE_MAINTENANCE };
