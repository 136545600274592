/* eslint-disable array-callback-return */
import { React, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Polygon, LayerGroup,LayersControl } from "react-leaflet";
import {
  Button,
  Modal,
  Descriptions,
  Col,
  Row,
  Typography,
  List,
  Card,
} from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
const { Text } = Typography;
const UnselectedCard = {
  width: "100% ", height: "100%"
};
function NotificationsMobile(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();  
  return (
    <>
      <List
    pagination={{
      pageSize: 10,
      align:"start",
    }}
    itemLayout="horizontal"
    className="AlertList2"
    dataSource={props.NotificationData}
    renderItem={(item) => (
      <List.Item>
       <div
                  key={
                    item.key
                  }
                  style={{margin:"5px 10px"}}
                  className="mydivouter2"
                >
                  <Card
                    style={UnselectedCard}
                    onClick={async() => {
                      navigate("/notificationDetails",{state: { notification: item}})
                      }}
                  >
                          {item.createdAt!==""&&<div style={{ 
                  position: "absolute",
                  zIndex: 2,
                  bottom: "0px",
                  right: "10px",
                 }}>
                  <Text type="secondary">
                    {dayjs(Math.floor(item.createdAt / 1000) * 1000).format(
        "DD MMM YYYY HH:mm"
      )}
                  </Text>
                </div>}
                  {item.status=== "Not Read"&&<div style={{ 
                  position: "absolute",
                  zIndex: 2,
                  top: "5px",
                  right: "10px",
                 }}>
                   <div class="new-box">{t("new")}</div>
                </div>}
                <div style={{margin:"20px 10px 10px 10px"}}>
                          <Row style={{marginBottom:10}}>                           
                            <Col><Text strong>{item.nameAlert}</Text></Col>
                          </Row>
                          <Row>
                            <Col><Text type="secondary">Type: </Text></Col>
                            <Col><Text type="secondary" style={{marginLeft:5}}>{t(item.typeAlert+"2")}</Text></Col>
                          </Row>
                </div>
                  <Row gutter={[16,16]} style={{ marginLeft:5,marginTop:7 }}>
                  </Row>
                  </Card>{" "}
                </div>
      </List.Item>
    )}
  />
    </>
  );
}
export default NotificationsMobile;
