import { gql } from "@apollo/client";

const GET_ZONES = gql`
  query Get_Zones($_id: ID, $role: String, $workspace: [ID]) {
    getZones(_id: $_id, role: $role, workspace: $workspace) {
      _id
      name
      address
      categorie 
      createdAt
      createdBy
      workspace{
        name
      }
    }
  }
`;
const GET_ZONES_FOR_GEOFENCE = gql`
  query Get_Zones($_id: ID, $role: String) {
    getZones(_id: $_id, role: $role) {
      _id
      name
      coordinates
    }
  }
`;
const GET_ZONECOUNT = gql`
  query Get_ZoneCount($_id: ID, $period: String) {
    countZone(_id: $_id, period: $period) {
      totalsort
      totalentred
      data {
        _id
        profile {
          Model
          Categorie
          serialNumber
          Marque
          Fuel
          usage
          createdAt
        }
        data {
          _id
          fonctionnement
          conso
          presence
          hourMeter
          odometer
          createdAt
          entre
        }
      }
    }
  }
`;

const GET_ZONE = gql`
  query Get_Zone($_id: ID, $role: String) {
    getZone(_id: $_id, role: $role) {
      _id
      name
      address
      categorie
      coordinates
      membre {
        _id
        fname
        lname
        email
        zoneraportperiod
      }
      createdAt
    }
  }
`;
export { GET_ZONES, GET_ZONE, GET_ZONECOUNT, GET_ZONES_FOR_GEOFENCE };
