import { gql } from "@apollo/client";

const ADD_CATEGORIE = gql`
  mutation CreateCategorie(
    $name: String!
    $company: ID!
    $role: String
  ) {
    createCategorie( name: $name company: $company role: $role ) {
      _id
      name
    }
  }
`;
const UPDATE_CATEGORIE = gql`
  mutation updateCategorie(
    $name: String!
    $_id: ID
    $role: String
  ) {
    updateCategorie( name: $name _id: $_id role: $role ) {
      _id
      name
    }
  }
`;
const DELETE_CATEGORIE = gql`
  mutation deleteCategorie(
    $_id: ID!
    $role: String
  ) {
    deleteCategorie( _id: $_id  role: $role ) {
      _id
      name
    }
  }
`;

export { ADD_CATEGORIE , UPDATE_CATEGORIE, DELETE_CATEGORIE};
