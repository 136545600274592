import { React} from "react";
import {  Layout,Skeleton } from "antd";
import {  Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import "../../css/ProfileTabs.css";
import HistoryTab from "../../components/Profile/HistoryTab";
import Breadcrumbs from "../../components/Multiple/Breadcrumbs";
import { useQuery } from "@apollo/client";
import { GET_VEHICLE } from "../../queries/mapQuery";
const { Content } = Layout;


function VehicleHistorique(props) {
  const { t } = useTranslation();
  let location = useLocation();
  const vehicle = location.state.vehicle;
  const path = location.state.path;
  const { loading, error, data } = useQuery(GET_VEHICLE, {
    variables: { _id: vehicle._id },
  });
  const items = [
    {
      key: "1",
      label: (
        <Link to={{ pathname: "/profile" }} state={{ vehicle,path }}>
          <div className="TabLink">{t("Tabs.Profile")}</div>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to={{ pathname: "/live" }} state={{ vehicle,path }}>
          <div className="TabLink">Live</div>
        </Link>
      ),
    },
    {
      key: "3",
      label: <div className="TabLink">{t("Tabs.History")}</div>,
      children: <HistoryTab vehicle={data?.getVehicle}/>,
    },
    {
      key: "4",
      label: (
        <Link to={{ pathname: "/utilisation" }} state={{ vehicle,path }}>
          <div className="TabLink">{t("Tabs.Utilisation")}</div>
        </Link>
      ),
    },
  ];

  // if (loading) return <Skeleton/>;
  if (error) return <></>;
  return (
    <Content className="HistoriquePageContent">
    <Skeleton loading={loading} 
    title={{ width:'10%' }}
    paragraph={{
      rows: 2,
      width:['20%','30%'],
    }}>
      <Breadcrumbs data={data} vehicle={vehicle} path={path} props={props} />
    </Skeleton>  

      <Tabs defaultActiveKey="3" items={items} />
    </Content>
  );
}
export default VehicleHistorique;
