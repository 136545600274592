import { gql } from '@apollo/client';

const ADD_USER = gql`
mutation createUser($email: String!,$fname: String!,$lname: String!, $password: String!, $confirm: String!,$role:ID!,$company:ID,$workspace:[ID]) {
  createUser(userInput: {
    email: $email,
    fname: $fname,
    lname: $lname,
    password: $password
    confirm: $confirm
    company: $company
    role: $role
    workspace: $workspace
}) {
    _id
    token
    email
}
  }
`;
const SIGNUP = gql`
mutation signup($fname: String!,$lname: String!, $password: String!, $invitationToken: String!) {
  signup(UserInputSignup: {
    fname: $fname,
    lname: $lname,
    password: $password
    invitationToken: $invitationToken
}) {
  _id
  token
  email
  role{
    name
  }
  company{
    _id
    name
  }
  workspace{
    _id
  }
}
  }
`;
const SEND_INVITAION = gql`
mutation sendInvitation($email: String!,$role:ID!,$company:ID,$workspace:[ID],$lang: String!) {
  sendInvitation(UserInputInvitation: {
    email: $email
    company: $company
    role: $role
    workspace: $workspace
    lang: $lang
}) {
  _id
  email
}
  }
`;
const SEND_RESETTOKEN = gql`
mutation sendResetPassword($email: String!,$lang:String) {
  sendResetPassword(email: $email, lang: $lang) {
  _id
  email
}
  }
`;
const RESET_USERPASSWORD = gql`
mutation resetUserpassword($resetToken:String!,$password: String!,$confirm: String!) {
  resetUserpassword(
    resetToken: $resetToken,
    password: $password,
    confirm: $confirm
) {
  _id
  token
  email
  role{
    name
  }
  company{
    _id
  }
  workspace{
    _id
  }
}
  }
`;
const UPDATE_USER = gql`
mutation updateUser($_id:ID,$email: String,$fname: String,$lname: String,$role:ID,$company:ID,$workspace:[ID]) {
  updateUser(UserInputupdate: {
    _id: $_id,
    email: $email,
    fname: $fname,
    lname: $lname,
    company: $company
    role: $role
    workspace: $workspace
}) {
    _id
    email
}
  }
`;
const UPDATE_USERPASSWORD = gql`
mutation updateUserpassword($_id:ID,$current: String,$new: String) {
  updateUserpassword(
    _id: $_id,
    current: $current,
    new: $new
) {
    _id
}
  }
`;
const UPDATE_USERPERIOD = gql`
  mutation updateUserperiod($_id: ID, $period: String,$zone:ID, $role: String) {
    updateUserzoneperiodraport(_id: $_id, period: $period,zone:$zone role: $role) {
      _id
    }
  }
`;
const DELETE_USER = gql`
mutation deleteUser($_id: ID!, $role:String!) {
  deleteUser(
    _id: $_id,
    role: $role
) {
    _id
    email
}
  }
`;
const UPDATE_USER_SUB = gql`
  mutation UpdateUserSub($subscriptions: SubscriptionInput!, $_id: ID!) {
    updateUserSub(subscriptions: $subscriptions, _id: $_id) {
      _id
      subscriptions {
        endpoint
        expirationTime
        keys {
          p256dh
          auth
        }
      }
    }
  }
`;

const REMOVE_USERSUBSCRIPTION = gql`
  mutation removeUserSub($_id: ID, $subscriptions: SubscriptionInput) {
    removeUserSub(_id: $_id, subscriptions: $subscriptions) {
      _id
    }
  }
`;



export { ADD_USER, SIGNUP, SEND_INVITAION, DELETE_USER, UPDATE_USER, UPDATE_USERPASSWORD, RESET_USERPASSWORD, SEND_RESETTOKEN, UPDATE_USERPERIOD , UPDATE_USER_SUB, REMOVE_USERSUBSCRIPTION};
