import React from 'react';
import { Tag  } from "antd";


function Status(props) {

    let color = "";
        switch (props.status) {
          case "Inactive":
            color = "#FFACAC";
            break;
          case "Active":
            color = "#BEF0CB";
            break;
          default:color = "#F0EEED";
          break;
        }
        return (
          props.status ? <Tag color={color}>{props.status}</Tag> : null
        );
}

export default Status;