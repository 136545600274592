import { gql } from '@apollo/client';

const CREATE_ASSET = gql`
mutation createAsset($serialNumber:String!,
  $Model:String!, 
  $Marque:String!, 
  $Categorie:String!,
  $usage:String!,
  $hourMeter:Int!,
  $odometer:Float!,
  $Fuel:Float!,
  $Source:String!,
  $assetID: String!
  $company:ID!
  $workspace:ID
  $createdBy:ID
  ) {
  createAsset(assetCreationInput: {
    serialNumber:$serialNumber,
    Model:$Model, 
    Marque:$Marque, 
    Categorie:$Categorie,
    usage:$usage,
    hourMeter:$hourMeter,
    odometer:$odometer,
    Fuel:$Fuel,
    Source:$Source,
    assetID: $assetID,
    company:$company
    workspace:$workspace
    createdBy: $createdBy
}) {
    _id
    assetID
}
  }
`;
const UPDATE_ASSET = gql`
mutation updateAsset(
  $_id:ID!,
  $serialNumber:String!,
  $Model:String!, 
  $Marque:String!, 
  $Categorie:String!,
  $hourMeter:Int!,
  $odometer:Float!,
  $Fuel:Float!,
  $Source:String,
  $ischange: Boolean! 
  $ischange1: Boolean! 
  $assetID: String!) {
    updateAsset(assetUpdateInput: {
    _id:$_id,
    serialNumber:$serialNumber,
    Model:$Model, 
    Marque:$Marque, 
    Categorie:$Categorie,
    hourMeter:$hourMeter,
    odometer:$odometer,
    Fuel:$Fuel,
    Source:$Source,
    ischange:$ischange,
    ischange1:$ischange1,
    assetID: $assetID
}) {
    _id
    assetID
}
  }
`;
const UPDATE_WORKSPACEASSET = gql`
  mutation Updateworkspaceasset($_id: ID, $workspace: ID) {
    updateworkspaceasset(_id: $_id, workspace: $workspace) {
      _id
    }
  }
`;
const DELETE_ASSET = gql`
  mutation deleteAsset($_id: ID,$role: String) {
    deleteVehicle(_id: $_id, role: $role) {
      _id
    }
  }
`;
const IMMOBILIZE_VEHICLE = gql`
  mutation immobilizeVehicle($assetID: String) {
    immobilizeVehicle(assetID: $assetID
      ) {
      _id
    }
  }
`;
const UNIMMOBILIZE_VEHICLE = gql`
  mutation unimmobilizeVehicle($assetID: String) {
    unimmobilizeVehicle(assetID: $assetID
      ) {
      _id
    }
  }
`;

export { CREATE_ASSET, UPDATE_ASSET, UPDATE_WORKSPACEASSET, DELETE_ASSET, IMMOBILIZE_VEHICLE, UNIMMOBILIZE_VEHICLE };