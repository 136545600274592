/* eslint-disable array-callback-return */
import { React, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Input,
  Typography,
  Table,
  Col,
  Row,
  Button,
  Layout,
  Modal,
  Dropdown,
  notification,
} from "antd";
import { ValueContext } from "../context/Context";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ZONES } from "../queries/zoneQuery";
import {
  PlusOutlined,
  SearchOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import "../css/Inventory.css";
import dayjs from "dayjs";
import Loading from "../components/Loading/Loading";
import { DELETE_ZONE } from "../mutations/zoneMutation";
const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

function Zone(props) {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ValueContext);
  const columns = [
    {
      title: t("Administration.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("InventoryTable.Category"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("HistoryTable.3"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("Workspace"),
      dataIndex: "workspace",
      key: "workspace",
    },
    {
      title: t("InventoryTable.Created"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        text[0]!==""&&<div>
         <div>
           <Text>
            {text[0]}
           </Text>
         </div>
         <Text type="secondary">{t("By")+" "+text[1]}</Text>
       </div>
     ),
    },
    {
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const [searchInput, setSearchInput] = useState("");
  const { company, role,workspaces,allworkspaces } = props.props;
  const { loading, error, data } = useQuery(GET_ZONES, {
    variables: { _id: company, role: role, workspace: workspaces==[] ? allworkspaces:workspaces },
  });
  const successNotification = () => {
    notification.open({
      description: t("Notification.18"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const showDeleteConfirm = (_id) => {
    confirm({
      title: t("Maintenance.29"),
      icon: <ExclamationCircleFilled />,
      content: t("Maintenance.30"),
      okText: t("FormValidations.11"),
      okType: "danger",
      cancelText: t("FormValidations.12"),
      onOk() {
        deletezone({ variables: { _id ,role} });
        window.location.reload();
      },
    });
  };
  const [deletezone] = useMutation(DELETE_ZONE, {
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_ZONES,
        variables: { _id: company, role: role },
      },
    ],
  });
  if (loading) return <Loading />;
  if (error) return <></>;
  var InventoryData = [];
  if (data.getZones.length > 0) {
    const TableData = data.getZones.filter((zone) => {
      if (searchInput === "") {
        return zone;
      } else if (
        zone.name.toLowerCase().includes(searchInput.toLowerCase())
      ) {
        return zone;
      }
    });
    InventoryData = TableData.map((zone) => {
      return {
        key: zone._id,
        category:t(`zone.${zone.categorie}`) ,
        address: zone.address,
        workspace:zone.workspace?.map((workspace,index) => workspace.name + ((index<(zone.workspace.length-1)) ? ", ":"")),
        createdAt:[dayjs(Math.floor(zone.createdAt / 1000) * 1000).format(
            "DD MMM YYYY HH:mm"
          ),zone.createdBy],
        name: (
          <div className="InvDetBtn">
            <Text>{zone.name}</Text>
            <Link
              to={{ pathname: "/apercu" }}
              state={{ zone: zone, path: "/zone" }}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            >
              <Button>{t("Details")}</Button>
            </Link>
          </div>
        ),
        
        actions: (
          <>
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <Button
                        type="text"
                        block
                        onClick={() => {
                          showDeleteConfirm(zone._id);
                        }}
                      >
                        <Text type="danger">{t("Maintenance.31")}</Text>
                      </Button>
                    ),
                    key: "2",
                  },
                ],
              }}
              trigger={["click"]}
            >
              <Button type="text" icon={<EllipsisOutlined />}></Button>
            </Dropdown>
          </>
        ),
      };
    });
  }
  return (
    <Content className="InvContent">
      <Table
        columns={columns}
        size="small"
        scroll={{ x: "true" }}
        dataSource={InventoryData}
        title={() => (
          <>
            <Row>
            <Col span={18}>
              <Input
                size="default"
                className="SearchBar"
                placeholder={t("AlertsSearchInput")+" ("+InventoryData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              /></Col>
              <Col span={6}>
              <div className="ButtonsDiv">
                {role !== "User" && (
                  <Link to={{ pathname: "/zone/create" }}>
                    <Button type="primary" style={{ marginRight: "5px" }}>
                      {isDesktop?(<><PlusOutlined /> {t("Add")}</>):(<><PlusOutlined /></>)}
                    </Button>
                  </Link>
                )}
              </div></Col>
            </Row>
          </>
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          position: ["bottomLeft"],
        }}
      />
    </Content>
  );
}
export default Zone;
