import { gql } from "@apollo/client";

const GET_MAINTENANCES = gql`
  query getMaintenances($role: String!, $user: ID) {
    getMaintenances(role: $role, user: $user) {
      _id
      name
      operations
      status
      echeance
      seuil
      createdAt
      updatedAt
      createdBy
      validatedby
      user{
        _id
      }
      vehicle{
        _id
        hourMeter
        odometer
          profile{
              Model
              Categorie
              serialNumber
          }
      }
  }
  }
`;
const GET_MAINTENANCE = gql`
  query getMaintenance($_id: ID!) {
    getMaintenance(_id: $_id) {
      name
      operations
      status
      echeance
      seuil
      createdAt
      updatedAt
      user{
        _id
      }
      vehicle{
        _id
        hourMeter
        odometer
          profile{
              Model
              Categorie
              serialNumber
          }
      }
  }
  }
`;
export { GET_MAINTENANCES ,GET_MAINTENANCE};