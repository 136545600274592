import React, { useRef,useState,useEffect} from "react";
import { MapContainer,TileLayer, Marker,useMap,LayersControl,Polyline} from "react-leaflet";
import L from "leaflet";
import { PlayCircleTwoTone, PauseCircleTwoTone } from "@ant-design/icons";
import "../../../node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "../../css/App.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import "../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { Button } from "antd-mobile";
require("leaflet-routing-machine");
export default function HistoriqueMap(props) {
  const { t } = useTranslation();
  let trips_data = props.trips_data;
  let bearingsarr1 = props.bearingsarr1;
  const showTrips= props.showTrips;
  const allpoint = trips_data.map((ele) => ele.allpoint).flat();
  const mapRef = useRef(null);
   const [index, setIndex] = useState(0);
   const [speed, setSpeed] = useState(1000); 
   const [playing, setPlaying] = useState(false);
   const markerRef = useRef(null);
   const initialRender = useRef(true);

   useEffect(() => {
     let interval;
     if (playing) {
       interval = setInterval(() => {
         setIndex((prevIndex) =>
           prevIndex < allpoint.length - 1 ? prevIndex + 1 : 0
         );
       }, speed);
     }
     return () => clearInterval(interval);
   }, [playing, speed, allpoint.length]);

   useEffect(() => {
     if (markerRef.current) {
       markerRef.current.setLatLng(allpoint[index]);
     }
   }, [index, allpoint]);
  
   function FitBounds() {
    const map = useMap();
    useEffect(() => {
      if (initialRender.current && allpoint.length > 0) {
        const bounds = L.latLngBounds(allpoint);
        try {
          map.fitBounds(bounds);
        } catch (error) {
          console.log(error);
        }
        initialRender.current = false; // Set to false after initial render
      }
    }, [map, allpoint]);
    return null;
  }
  return (
    <>
      <MapContainer
        updateInterval={100}
        ref={mapRef}
        center={[51.505, -0.09]}
        zoom={1.5}
        zoomControl={false}
        scrollWheelZoom={true}
        style={{height:window.innerHeight*0.65}}
      >
        <LayersControl position="topright">
          {/* <LayerGroup>
            <LayersControl.BaseLayer checked name="Simple">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
            </LayersControl.BaseLayer>
          </LayerGroup> */}
          <LayersControl.BaseLayer checked name="Google Hybrid">
            <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Google Streets">
            <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
          </LayersControl.BaseLayer>
        </LayersControl>
        <FitBounds />
        <Polyline positions={allpoint} color="#03C988" />
        {trips_data.map((ele, i) => {
          if (i < trips_data.length) {
            return (
              <Marker
                position={ele.allpoint[ele.allpoint.length - 1]}
                icon={L.icon({
                  iconUrl:
                    "https://img.icons8.com/?size=100&id=jSE5gcMaqEMI&format=png&color=000000",
                  iconAnchor: [20, 30],
                  iconSize: [30, 30],
                })}
              >
                {/* <Popup offset={[0, -40]}>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${ele.latitude},${ele.longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ${t("vew")}
                  </a>
                </Popup> */}
              </Marker>
            );
          }
        })}
        {allpoint.length > 0 && (
          <Marker
            position={allpoint[index]}
            ref={markerRef}
            icon={L.icon({
              iconUrl:
                "https://img.icons8.com/?size=100&id=VNVNgeTOW2O6&format=png&color=000000",
              iconAnchor: [15, 30],
              iconSize: [30, 30],
            })}
          />
        )}
      </MapContainer>
      <div
        style={{
          position: "absolute",
          bottom: "36vh",
          width:"94vw",
          left: "2vw",
          zIndex: 1000,
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        {!playing ? (
          <Button style={{width:"15vw"}} fill='none' size='mini'  onClick={() => setPlaying(true)}>
          <FontAwesomeIcon icon={faPlay} />
        </Button>
        ) : (
          <Button style={{width:"15vw"}} fill='none' size='mini' onClick={() => setPlaying(false)}>
          <FontAwesomeIcon icon={faPause} />
        </Button>
        )}
        <Button style={{width:"13vw"}} fill='none' size='mini' onClick={() => setSpeed(2500)}>
          x0.1
        </Button>
        <Button style={{width:"13vw"}} fill='none' size='mini' onClick={() => setSpeed(1000)}>
          x1
        </Button>
        <Button style={{width:"13vw"}} fill='none' size='mini' onClick={() => setSpeed(500)}>
          x10
        </Button>
        <Button style={{width:"13vw"}} fill='none' size='mini' onClick={() => setSpeed(250)}>
          x20
        </Button>
        <Button style={{width:"13vw"}} fill='none' size='mini' onClick={() => setSpeed(100)}>
          x50
        </Button>
        <Button
          fill='none' size='mini'
          style={{width:"14vw"}}
          onClick={() => {
            setIndex(0);
            setPlaying(false);
          }}
        >
          <FontAwesomeIcon icon={faStop} />
        </Button>
      </div>
    </>
  );
}