import { gql } from "@apollo/client";
const ADD_ZONE = gql`
  mutation CreateZone(
    $name: String!
    $company: ID!
    $categorie: String
    $address: String
    $coordinates: [[Float]]
    $radius: Float
    $createdBy: ID
    $role: String
    $workspace:[ID]
  ) {
    createZone(
      name: $name
      company: $company
      categorie: $categorie
      address: $address
      coordinates: $coordinates
      radius: $radius
      createdBy: $createdBy
      role: $role
      workspace: $workspace
    ) {
      _id
    }
  }
`;
const UPDATE_ZONE = gql`
  mutation updateZone(
    $name: String!
    $company: ID!
    $categorie: String
    $address: String
    $coordinates: [[Float]]
    $radius: Float
    $role: String
  ) {
    updateZone(
      name: $name
      company: $company
      categorie: $categorie
      address: $address
      coordinates: $coordinates
      radius: $radius
      role: $role
    ) {
      _id
    }
  }
`;
const ADD_ZONEMEMBER = gql`
  mutation addZonemembre(
    $member: [ID]
    $_id: ID
    $role: String
  ) {
    addZonemembre(
      member: $member
      _id: $_id
      role: $role
    ) {
      _id
    }
  }
`;
const REMOVE_ZONEMEMBER = gql`
  mutation removeZonemembre($member: ID, $_id: ID, $role: String) {
    removeZonemembre(member: $member, _id: $_id, role: $role) {
      _id
    }
  }
`;
const DELETE_ZONE = gql`
  mutation deleteZone($_id: ID!, $role: String) {
    deleteZone(_id: $_id, role: $role) {
      _id
    }
  }
`;

export { ADD_ZONE, UPDATE_ZONE, DELETE_ZONE ,ADD_ZONEMEMBER,REMOVE_ZONEMEMBER};
