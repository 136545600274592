/* eslint-disable array-callback-return */
import { React, useState,useContext } from "react";
import { ValueContext } from "../../context/Context";
import { useTranslation } from "react-i18next";
import { Input, Button, Form, Modal, Select, notification } from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";
import { PlusOutlined } from "@ant-design/icons";

import { GET_WORKSPACES } from "../../queries/workspaceQuery";
import { GET_VEHICLES_FOR_ADMINISTRATION_PAGE } from "../../queries/mapQuery";
import { GET_ADMINUSER, GET_USERS_FOR_ADMINISTRATION } from "../../queries/UserQuery";
import { GET_COMPANYS } from "../../queries/companyQuery";
import { ADD_WORKSPACE } from "../../mutations/workspaceMutation";
import Loading from "../Loading/Loading";
function CreateWorkspaceForm(props) {
  const { isDesktop } = useContext(ValueContext);
  const { t } = useTranslation();
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    // notification.open({
    //   description: t("Notification.5"),
    //   duration:2,
    //   closeIcon: false,
    //   className:'CustomSuccess'
    // });
    window.location.reload();
  };
  const { company, role,_id } = props.props;
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState([_id]);
  const [form] = Form.useForm();
  const [getvehicles, { loading: loadingv, data: datav }] =
  useLazyQuery(GET_VEHICLES_FOR_ADMINISTRATION_PAGE, {
    variables: { _id: company, role: role },fetchPolicy: "no-cache",
    onError: (error) => {
      errorNotification(error.message);
    },
  });
const [getusers, { loading: loadingu, data: datau}] =
  useLazyQuery(GET_USERS_FOR_ADMINISTRATION, {
    variables: { _id: company, role: role },fetchPolicy: "no-cache",
    onError: (error) => {
      errorNotification(error.message);
    },
  });
  const [getadmins, { loading: loadinga, data: dataa}] =
  useLazyQuery(GET_ADMINUSER, {
    variables: { _id: company, _iduser: _id },fetchPolicy: "no-cache",
  });
const [getcompanys, { loading: loadingc, data: datac }] =
  useLazyQuery(GET_COMPANYS, {
    variables: { role: role },fetchPolicy: "no-cache",
    onError: (error) => {
      errorNotification(error.message);
    },
  });
  const [addworkspace] = useMutation(ADD_WORKSPACE, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_WORKSPACES,
        variables: { _id: company, role: role },
      },
    ],
  });
  const vehicleoptions = [];
  datav &&
    datav.getVehicles.map((ele) => {
      vehicleoptions.push({
        value: ele._id,
        label: ele.profile.Model + " / " + ele.profile.serialNumber,
      });
    });
  const useroptions = [];
  datau &&
    datau.getUsers.map((ele) => {
      useroptions.push({
        value: ele._id,
        label: ele.email,
      });
    });
  const companyoptions = [];
  role === "Super Admin" &&
    datac &&
    datac.getCompanys.map((ele) => {
      companyoptions.push({
        value: ele._id,
        label: ele.name,
      });
    });
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
          getvehicles();
          getusers();
          getadmins();
          if (role === "Super Admin") {
            getcompanys();
          }
        }}
      >
        {isDesktop? <div><PlusOutlined /> {t("workspace.Add")}</div>:<div><PlusOutlined /></div>}
      </Button>
      <Modal
        open={open}
        title={t("workspace.Add")}
        okText={t("Alerts.10")}
        cancelText={t("Alerts.9")}
        style={{
          top: 20,
        }}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              setUser(values.members);
              dataa.getAdminUser.map((element) => {
                if (!user.includes(element)) {
                  user.push(element);
                }
              });
              if (!user.includes(_id)) {
                user.push(_id);
              }
              addworkspace({
                variables: {
                  name:values.name,
                  user:user,
                  vehicle: values.equipment ? (values.equipment.includes("All") ? (vehicleoptions.map(option => option.value)):values.equipment): [],
                  role: role,
                  company: values.company ? values.company : company,
                },
              });

              setOpen(false);

              form.resetFields();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {loadingc || loadingu || loadingv || loadinga ? <Loading/>:(<Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            members:[_id]
          }}
        >
          <Form.Item
            name="name"
            label={t("Formeworkspace.lableforname")}
            rules={[
              {
                required: true,
                message: t("FormValidations.26"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="equipment"
            label={t("Formeworkspace.lableforequipment")}
          >
            <Select
            mode="multiple"
              style={{
                width: "100%",
              }}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={[{value: "All", label: t("AllEquipment")},...vehicleoptions]}
            />
          </Form.Item>
          <Form.Item
            name="members"
            label={t("Formeworkspace.lableformembers")}
          >
            <Select
            mode="multiple"
              style={{
                width: "100%",
              }}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={useroptions}
              onChange= {(newValue) => {setUser(newValue);}}
            />
          </Form.Item>
          {role === "Super Admin" && (
            <Form.Item
              name="company"
              label={t("FormeAddUser.lableforcompany")}
              rules={[
                {
                  required: true,
                  message: t("FormValidations.25"),
                },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                options={companyoptions}
              />
            </Form.Item>
          )}
        </Form>)}
      </Modal>
    </>
  );
}
export default CreateWorkspaceForm;
