import React from 'react';
import { Spin,Layout  } from "antd";
  const {Content}=Layout;
function Loading() {

    return (
      <>
      <Content
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '200px',
          margin: '20px',
        }}
      >
        <Spin size="large" />
      </Content>
    </>
    )
}

export default Loading;