import React, { useEffect } from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Layout, Col, Row,Typography,Card } from "antd";
import { Button, Collapse, FloatingPanel, List, TabBar } from 'antd-mobile';
import { EnvironmentOutlined,FieldTimeOutlined, EditOutlined }from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCar, faChartLine, faDiamondTurnRight, faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker,useMap, LayerGroup,LayersControl } from "react-leaflet";
import L from "leaflet";
import "../../../node_modules/leaflet/dist/leaflet.css";
import { GET_VEHICLE , GET_DURATION } from "../../queries/mapQuery";
import { io } from "socket.io-client";
import dayjs from "dayjs";
import { convertSecondsToDynamicTime, convertSecondsToHoursMins, convertToHoursMins } from "../../constants/ConvertHora";
import "../../css/Mobile.css";
import Status from "../../components/Multiple/Status";
import Loading from "../../components/Loading/Loading";
import { Footer } from "antd/es/layout/layout";
import { GET_LASTUTILISATION } from "../../queries/utilisationQuery";
const { Content } = Layout;
const { Text } = Typography;
const anchors = [window.innerHeight * 0.39, window.innerHeight * 0.4, window.innerHeight * 0.8];

function Details(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useApolloClient();
  let location = useLocation();
  const vehicle = location.state.vehicle;
  const path = location.state.path;
  const { loading, error, data,refetch } = useQuery(GET_VEHICLE, {
    variables: { _id: vehicle._id },fetchPolicy: "no-cache",
  });
  const { loading:loading1, error:error1, data:data1,refetch:refetchd } = useQuery(GET_DURATION, {
    variables: { vehicle: vehicle._id , status:vehicle.status },fetchPolicy: "no-cache",
  });
  const { loading:loadingU, data:dataU , refetch:refetchlastut} = useQuery(GET_LASTUTILISATION, {
    variables: { _id: vehicle._id },fetchPolicy: "no-cache",
    // onError: (error) => {
    //   errorNotification(error.message);
    // },
  });
  const socket = io({ path: "/socket.io", transports: ["websocket"] });
  useEffect(() => {
    if (socket) {
      socket.on('updateVehicle'+vehicle._id, () => {
        // const newVehicle = {
        //   ...data.getVehicle, // spread the existing fields of the vehicle
        //   Source:data.getVehicle.Source,
        //   address:updatedVehicle.address,
        //   assetID:data.getVehicle.assetID,
        //   createdAt:data.getVehicle.createdAt,
        //   hourMeter: updatedVehicle.hourMeter,
        //   odometer: updatedVehicle.odometer,
        //   latitude: updatedVehicle.latitude,
        //   longitude: updatedVehicle.longitude,
        //   profile:data.getVehicle.profile,
        //   status: updatedVehicle.status,
        //   updatedAt: updatedVehicle.updatedAt,
        //   GPSTime: updatedVehicle.GPSTime,
        //   speed: updatedVehicle.speed,
        // };
        // console.log(updatedVehicle);
        // // update the data in the Apollo cache
        // client.writeQuery({
        //   query: GET_VEHICLE,
        //   variables: { _id: vehicle._id },
        //   data: { getVehicle: newVehicle },
        // });
        refetch();
        refetchd();
      });
    }
    return () => {
      if (socket) {
        socket.off('updateVehicle'+vehicle._id);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, data, client, vehicle._id]);
  if (loading) return <Loading />;
  if (error) return <></>;
  if (loading1) return <Loading />;
  const tabs = [
    {
        key: 'hist',
        icon: <Link to="/MobileHistory" state={{ vehicle,data,path }} ><FontAwesomeIcon icon={faMapLocationDot} /></Link>,
        title:t("Tabs.History"),
    },
    {
        key: 'utilis',
        icon: <Link to="/MobileUtilisation" state={{ vehicle,path,data }}><FontAwesomeIcon icon={faChartLine} /></Link>,
        title:t("Tabs.Utilisation"),
    },
];
function FitBounds() {
  const map = useMap();
  useEffect(() => {
      const bounds = L.latLngBounds([[data.getVehicle.latitude, data.getVehicle.longitude]]);
      try {
        map.fitBounds(bounds,{maxZoom: map.getZoom()});
      } catch (error) {
        console.log(error);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])
}
  var bg_color = "grey";
  switch (data.getVehicle.status) {
    case "Inactive":
      bg_color = "#FF005C";
      break;
    case "Active":
      bg_color = "#03C988";
      break;
    default:
      break;
  }
  return (
    <>
      <Content
        className="liveContent"
      >
            <Button className='RHistoryBtn3' size='mini' shape='rounded'
          onClick={()=>{
            navigate(-1);
            }} >
          <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        <MapContainer
          center={[data.getVehicle.latitude, data.getVehicle.longitude]}
          scrollWheelZoom={true} zoomControl={false}
          style={{ height: "70vh", width: "90wh" }}
        >
          <LayersControl position="topright">
            <LayerGroup>
              {/* <LayersControl.BaseLayer checked name="Simple">
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite">
                <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer> */}
              <LayersControl.BaseLayer checked name="Google Hybrid">
                <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Streets">
                <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
              </LayersControl.BaseLayer>
            </LayerGroup>
          </LayersControl>
          <Marker
            key={String(vehicle._id)}
            position={[data.getVehicle.latitude, data.getVehicle.longitude]}
            icon={L.divIcon({
              className: "markerStyles",
              html: `<div style="background-color:${bg_color}" class="custom-marker"></div>`,
              // iconAnchor: [10, 10],
            })}
            // icon={customIcon}
          >
            <FitBounds />
          </Marker>
        </MapContainer>
        <FloatingPanel className="DetailsFloating"  anchors={anchors}>
            <Row>
                <Col style={{padding:5,textAlign:'center'}} span={8}><Text strong>{data.getVehicle.profile.serialNumber}</Text></Col>
                <Col style={{padding:5}} span={8}></Col>
                <Col style={{padding:5,textAlign:'center'}} span={8}><Text type="secondary">{(data.getVehicle.speed ?? 0).toFixed(0) + " Km/h"}</Text></Col>
            </Row>
            <Row>
                <Col style={{padding:10}} span={12}><a 
              href={`https://www.google.com/maps/search/?api=1&query=${data.getVehicle.latitude},${data.getVehicle.longitude}`} 
              target="_blank" 
              rel="noopener noreferrer"
            ><Card className="DPMCard" ><FontAwesomeIcon icon={faDiamondTurnRight} /></Card></a></Col>
                {/* <Col style={{padding:10}} span={8}><Card className="DPMCard" bordred><FontAwesomeIcon icon={faDiamondTurnRight} /></Card></Col> */}
                <Col style={{padding:10}} span={12}><Card className="DPMCard" onClick={()=>{navigate("/UpdateAssetMobile",{state: { data: data }})}} ><EditOutlined /></Card></Col>
            </Row>
            <Row gutter={[5, 16]}>
                  <Col span={2} style={{textAlign:'center'}}>
                    <EnvironmentOutlined />
                  </Col>
                  <Col span={22}>
                    <Text  style={{ whiteSpace:'break-spaces' }}>{data.getVehicle.address}</Text>
                  </Col>
                </Row>
                <Row gutter={[5, 16]}>
                  <Col span={2}>
                    
                  </Col>
                  <Col span={22}>
                            <Text type="secondary">
                              {dayjs(
                                Math.floor(data.getVehicle.GPSTime ? data.getVehicle.GPSTime:data.getVehicle.updatedAt / 1000) *
                                  1000
                              ).format("DD MMM YYYY HH:mm:ss")}
                            </Text>
                          </Col>
                </Row>
                <Row gutter={[5, 16]}>
                  <Col span={2} style={{textAlign:'center'}}>
                  <FieldTimeOutlined /> 
                  </Col>
                  <Col span={22}>
                  <Text>{t("Lastupdate")+" : "}
                    {dayjs(
                      Math.floor(data.getVehicle.updatedAt / 1000) * 1000
                    ).format("DD MMM YYYY HH:mm:ss")}
                  </Text>
                  </Col>
                </Row>
                      <List className="DList" style={{backgroundColor:"#f5f7f8"}} header={
                          <Row style={{color:'black'}} gutter={[16, 16]}>
                          <Col span={2}>
                          <FontAwesomeIcon icon={faCar} />
                          </Col>
                          <Col span={22}>
                          {data.getVehicle.profile.Marque+" "+data.getVehicle.profile.Model}
                          </Col>
                          </Row>
                        }>
                            <List.Item >
                                <Row style={{margin:"10px 0px"}} gutter={[16, 16]}>
                                <Col span={8}>
                                {t("Etat")}
                                </Col>
                                <Col span={16} style={{textAlign:'end'}}>
                                <Status status={data.getVehicle.status} />{" "+convertSecondsToDynamicTime((data1?.getduration ?? 0)/1000)}
                                </Col>
                                </Row> 
                            </List.Item>
                            <List.Item >{data.getVehicle.odometer>=0 && (
                                <Row style={{margin:"10px 0px"}} gutter={[5, 16]}>
                                <Col span={8}>
                                {t("InventoryTable.Odometre")}
                                </Col>
                                <Col span={16} style={{textAlign:'end'}}>
                                    {(data.getVehicle.odometer).toFixed(2) + " Km"}
                                </Col>
                                </Row>
                                )}
                            </List.Item>
                            <List.Item >
                                <Row style={{margin:"10px 0px"}} gutter={[5, 16]}>
                                <Col span={8}>
                                {t("InventoryTable.Horametre")}
                                </Col>
                                <Col span={16} style={{textAlign:'end'}}>
                                {convertToHoursMins(data.getVehicle.hourMeter) !==
                                "0 h 0 min"
                                  ? convertToHoursMins(data.getVehicle.hourMeter)
                                  : "-"}
                                </Col>
                                </Row>
                            </List.Item>
                        </List>
                        <List className="DList" style={{backgroundColor:"#f5f7f8"}} header={
                          <Row style={{color:'black'}} gutter={[16, 16]}>
                          {t("DailyUtilisation")}
                          </Row>
                        }>
                            <List.Item >
                                <Row style={{margin:"10px 0px"}} gutter={[5, 16]}>
                                <Col span={8}>
                                {t("UsageKM")}
                                </Col>
                                <Col span={16} style={{textAlign:'end'}}>
                                {dataU
                            ? parseFloat(
                                dataU.getLastUtilisation.utilisationKM
                              ) > 0
                              ? parseFloat(
                                  dataU.getLastUtilisation.utilisationKM
                                ).toFixed(2) + " Km"
                              : "-"
                            : "-"}
                                </Col>
                                </Row>
                            </List.Item>
                            <List.Item >
                                <Row style={{margin:"10px 0px"}} gutter={[5, 16]}>
                                <Col span={8}>
                                {t("UsageHours")}
                                </Col>
                                <Col span={16} style={{textAlign:'end'}}>
                                {dataU
                            ? convertToHoursMins(
                                dataU.getLastUtilisation.utilisation
                              ) !== "0 h 0 min"
                              ? convertToHoursMins(
                                  dataU.getLastUtilisation.utilisation
                                )
                              : "-"
                            : "-"}
                                </Col>
                                </Row>
                            </List.Item>
                            <List.Item >
                                <Row style={{margin:"10px 0px"}} gutter={[5, 16]}>
                                <Col span={8}>
                                {t("IdleHours")}
                                </Col>
                                <Col span={16} style={{textAlign:'end'}}>
                                {dataU
                            ? convertSecondsToHoursMins(
                                dataU.getLastUtilisation.ralentie
                              ) !== "0 h 0 min"
                              ? convertSecondsToHoursMins(
                                  dataU.getLastUtilisation.ralentie
                                )
                              : "-"
                            : "-"}
                                </Col>
                                </Row>
                            </List.Item>
                            <List.Item >
                                <Row style={{margin:"10px 0px"}} gutter={[5, 16]}>
                                <Col span={8}>
                                {t("ProductivityHours")}
                                </Col>
                                <Col span={16} style={{textAlign:'end'}}>
                                {dataU
                            ? convertSecondsToHoursMins(
                                dataU.getLastUtilisation.productiv
                              ) !== "0 h 0 min"
                              ? convertSecondsToHoursMins(
                                  dataU.getLastUtilisation.productiv
                                )
                              : "-"
                            : "-"}
                                </Col>
                                </Row>
                            </List.Item>
                            <List.Item >
                                <Row style={{margin:"10px 0px"}} gutter={[5, 16]}>
                                <Col span={8}>
                                {t("Fuel")}
                                </Col>
                                <Col span={16} style={{textAlign:'end'}}>
                                {data.getVehicle.profile.usage==="hr" ?((dataU)
                            ? parseFloat((dataU.getLastUtilisation.utilisation / 60) *data.getVehicle.profile.Fuel).toFixed(2) +" L":0+" L")
                                :((dataU)
                            ? parseFloat((parseFloat(dataU.getLastUtilisation.utilisationKM) *data.getVehicle.profile.Fuel) /100).toFixed(2) +" L":0+" L")}
                                </Col>
                                </Row>
                            </List.Item>
                        </List>
      </FloatingPanel>
      <Footer
            style={{
              position: 'fixed',
              zIndex:1000,
              bottom: 0,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
        <TabBar activeKey={null}>
          {tabs.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge}/>))}
        </TabBar>
        </Footer>
      </Content>
    </>
  );
}
export default Details;
