import { gql } from "@apollo/client";

const GET_WORKSPACES = gql`
  query Get_Workspaces($_id: ID, $role: String, $workspace: [ID]) {
    getWorkspaces(_id: $_id, role: $role,workspace:$workspace) {
      _id
      name
      createdAt
      user {
        _id
      }
      vehicle {
        _id
      }
      company {
        _id
      }
    }
  }
`;
const GET_SHORT_WORKSPACES_FORDASHBOARD = gql`
  query Get_Workspaces($_id: ID, $role: String, $workspace: [ID]) {
    getWorkspaces(_id: $_id, role: $role,workspace:$workspace) {
      _id
      name
      vehicle {
        _id
      }
    }
  }
`;
const GET_WORKSPACE = gql`
  query Get_Workspace($name: String, $role: String) {
    getWorkspace(name:$name role: $role) {
      _id
      name
      user{
        _id
      }
      vehicle{
        _id
      }
      company{
        _id
      }
    }
  }
`;
export { GET_WORKSPACES, GET_WORKSPACE, GET_SHORT_WORKSPACES_FORDASHBOARD };