import React,{useContext, useState} from 'react'
import { Layout,Select,Col, Row,Typography,Card,Timeline, List, } from "antd";
import { useTranslation } from "react-i18next";
import { ValueContext } from '../../context/Context';
import Loading from '../Loading/Loading';
import dayjs from "dayjs";
import { Link, useLocation } from "react-router-dom";
import HistoriqueMap from "../map/HistoriqueMap";
import { GET_HISTORYBYPERIOD } from '../../queries/mapQuery';
import { useQuery } from '@apollo/client';
import { EnvironmentOutlined,} from "@ant-design/icons";
import "../../css/ProfileTabs.css"
import { convertToHoursMins } from '../../constants/ConvertHora';
const SelectedCard = { borderLeft: "2px solid #1677ff"};
const UnselectedCard = {
  width: "100% ", height: "100%"
};
const { Text } = Typography;
const { Content } = Layout; 
const Time = (date_1, date_2) =>{
  let difference = date_1 - date_2;
  let Minute = (Math.floor(difference/1000)/60);
  let HH = Math.floor(Minute/60);
  if(HH<10) HH="0"+HH;
  let MM = Math.floor(Minute%60);
  if(MM<10) MM="0"+MM;
  return HH+":"+MM;
}
function HistoryTab(props) {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ValueContext);
  let location = useLocation();
  let vehicle = location.state.vehicle;
  let [period, setperiod] = useState("ToDay");
  let { loading, error, data  } = useQuery(GET_HISTORYBYPERIOD, { variables: { vehicle: vehicle._id ,period:period  } });
  let [showTrips, setshowTrips] = useState([0]);
  let trips_data = []
  let trip = []
  let bearingsarr1 = []
  let bearingarr1 = []
  if (loading) return <Loading/>;
  if (error) return <></>;
  let bearing = (params) => {
    let [start_latitude, start_longitude] = params[0];
  let [stop_latitude, stop_longitude] = params[1];

  let y = Math.sin(stop_longitude - start_longitude) * Math.cos(stop_latitude);
  let x = Math.cos(start_latitude) * Math.sin(stop_latitude) - Math.sin(start_latitude) * Math.cos(stop_latitude) * Math.cos(stop_longitude - start_longitude);
  let brng = Math.atan2(y, x) * 180 / Math.PI;

  return brng;
};
      // eslint-disable-next-line no-unused-vars
      // let datas = data&&data.getHistoryByPeriod.map((ele,i) => {
        
      //   if (ele.vehicleStatus === "Inactive") {
      //     if (trip.length !== 0) {
      //       if (Time(trip[trip.length - 1].createdAt, trip[0].createdAt) !== "00:00"&&(bearingarr1.length !== 0)) {
      //         trip.push(ele);
      //         trips_data.push(trip);
      //         bearingsarr1.push(bearingarr1);
      //       }
      //       }
      //       trip = []
      //       bearingarr1 = []
            
      //     }
      //     else{
      //       trip.push(ele)
      //       if (i<data.getHistoryByPeriod.length-1) {
      //         let ber =bearing([[data.getHistoryByPeriod[i].latitude,data.getHistoryByPeriod[i].longitude],[data.getHistoryByPeriod[i+1].latitude,data.getHistoryByPeriod[i+1].longitude]])
      //         bearingarr1.push(ber<180?ber+(180-ber)*2:ber)              
      //       }
      //     }
      //     return ([ele.latitude, ele.longitude])
      //   });
      trips_data = [...data.getTrip_HistoryByPeriod];
  // bearingsarr1 = [...data.getTrip_HistoryByDate.bearingsarr1];
  let periodoptions = [
    {
      value: "ToDay",
      label: t("Alerts.23"),
    },
    {
      value: "YesterDay",
      label: t("Alerts.24"),
    },
    {
      value: "Last 7 Day",
      label: t("Alerts.18"),
    },
    {
      value: "Last 15 Day",
      label: t("Alerts.19"),
    },
    {
      value: "Last Month",
      label: t("Alerts.20"),
    },
  ];
  let length= trips_data.length-1
  let Tabledata = data.getTrip_HistoryByPeriod.map((trip, i) => {
      return{
        key: length-i,
        date: dayjs(Math.floor(trip.start_time/1000)*1000).format("DD MMM YYYY"),
        st: [dayjs(Math.floor(trip.start_time/1000)*1000).format("HH:mm"),dayjs(Math.floor(trip.end_time/1000)*1000).format("HH:mm")],
        address: [trip.start_address.slice(0, 60) + "...", trip.end_address.slice(0, 60) + "..."],
        time:[trip.duration,trip.distance],
      }
  });
  const items = Tabledata.map((item,index)=>{
    return {
      label:<>
      <div
                  key={
                    item.key
                  }
                  className="mydivouter2"
                >
                  <Card
                    style={showTrips.includes(index)?SelectedCard:UnselectedCard}
                    onClick={()=>{
                      if(!showTrips.includes(index)){
                        setshowTrips([...showTrips, index].sort().reverse());
                      }else{
                        setshowTrips(showTrips.filter((trip)=> {return trip!==index;}))
                      }
                    }}
                  >
                  <Row gutter={[16,16]} style={{ margin:5 }}>
                    <Col span={8}>
                    <Text type='secondary'>Date</Text>
                    <Text style={{ display:'block' }}>{item.date}</Text>
                    </Col>
                    <Col span={8}>
                    <Text type='secondary'>{t("Duration")}</Text>
                    <Text style={{ display:'block' }}>{item.time[0]}</Text>
                    </Col>
                    <Col span={8}>
                    <Text type='secondary'>Distance</Text>
                    <Text style={{ display:'block' }}>{item.time[1]}</Text>
                    </Col>
                  </Row>
                  <Row gutter={[16,16]} style={{ marginLeft:10,marginTop:10 }}>
                  <Timeline
                    items={[
                      {
                        children: (
                        <Row gutter={[16,16]}>
                        <Col span={5}>{item.st[0]}</Col>
                        <Col span={19}>{item.address[0]}</Col>
                        </Row>),
                      },
                      {
                        dot:<EnvironmentOutlined/>,
                        children: (
                        <Row gutter={[16,16]}>
                        <Col span={5}>{item.st[1]}</Col>
                        <Col span={19}>{item.address[1]}</Col>
                        </Row>),
                      },
                    ]}
                  />
                  </Row>
                  </Card>{" "}
                </div>
      </>,
      key:index,
    }

  });
  return (
    <>
    <Content className='HistoryTabContent'>
    {isDesktop ? (<Row>
        <Col sm={24} md={10} >
        <Row >
    <Col style={{ marginLeft:10,marginTop:10 }}>
    <Text style={{maxWidth:'95%'}} ellipsis={{ rows:1 }} ><EnvironmentOutlined style={{ color:'red' }} /> {vehicle.address}</Text></Col>
    </Row>
    <Row>
    <Col span={23} style={{ marginLeft:10,marginTop:5,marginBottom:5 }}>
    <div className='ButtonsDiv'>
              <Select
                      style={{ width:144, }}
                      value={period}
                      options={periodoptions}
                      onChange={(e) => {
                        setperiod(e);
                      }}
                    /> </div>
                    </Col>            
    </Row>
    <List
    itemLayout="horizontal"
    className="HistoryList"
    dataSource={Tabledata}
    renderItem={(item, index) => (
      <List.Item>
       <div
                  key={
                    item.key
                  }
                  className="mydivouter"
                >
                  <Card
                    style={showTrips.includes(index)?SelectedCard:UnselectedCard}
                    onClick={()=>{
                      if(!showTrips.includes(index)){
                        setshowTrips([...showTrips, index].sort().reverse());
                      }else{
                        setshowTrips(showTrips.filter((trip)=> {return trip!==index;}))
                      }
                    }}
                  >
                  <Row gutter={[16,16]} style={{ margin:10 }}>
                    <Col span={8}>
                    <Text type='secondary'>Date</Text>
                    <Text style={{ display:'block' }}>{item.date}</Text>
                    </Col>
                    <Col span={8}>
                    <Text type='secondary'>{t("Duration")}</Text>
                    <Text style={{ display:'block' }}>{item.time[0]}</Text>
                    </Col>
                    <Col span={8}>
                    <Text type='secondary'>Distance</Text>
                    <Text style={{ display:'block' }}>{item.time[1]}</Text>
                    </Col>
                  </Row>
                  <Row gutter={[16,16]} style={{ marginLeft:10,marginTop:10 }}>
                  <Timeline
                    items={[
                      {
                        children: (
                        <Row gutter={[16,16]}>
                        <Col >{item.st[0]}</Col>
                        <Col >{item.address[0]}</Col>
                        </Row>),
                      },
                      {
                        dot:<EnvironmentOutlined/>,
                        children: (
                        <Row gutter={[16,16]}>
                        <Col >{item.st[1]}</Col>
                        <Col >{item.address[1]}</Col>
                        </Row>),
                      },
                    ]}
                  />
                  </Row>
                  </Card>{" "}
                </div>
      </List.Item>
    )}
  />
        </Col>
        <Col sm={24} md={14}>
        <HistoriqueMap 
            // bearingsarr1={bearingsarr1}
            trips_data={trips_data} 
        showTrips={showTrips}
        props={props}
        />
        </Col>
    </Row>):<>
    <Row style={{width:'100%',display:'inline-block'}}>
      <Col sm={24} md={14}>
        <Row >
    <Col style={{ marginLeft:10,marginTop:10 }}>
    <Text style={{maxWidth:'95%'}} ellipsis={{ rows:1 }}><EnvironmentOutlined style={{ color:'red' }} /> {vehicle.address}</Text></Col>
    </Row>
    <Row>
    <Col span={23} style={{ marginLeft:10,marginTop:5,marginBottom:5, }}>
      <div className='ButtonsDiv'>
              <Select
                      style={{ width:144, }}
                      value={period}
                      options={periodoptions}
                      onChange={(e) => {
                        setperiod(e);
                      }}
                    /> 
      </div>
                    </Col>    
    </Row>
    <List
    itemLayout="horizontal"
    className="HistoryList2"
    dataSource={Tabledata}
    renderItem={(item, index) => (
      <List.Item>
       <div
                  key={
                    item.key
                  }
                  className="mydivouter2"
                >
                  <Card
                    style={UnselectedCard}
                  >
                    <Link
                          to={{ pathname: "/history/route" }}
                          state={{ props, bearingsarr1:bearingsarr1, trips_data:trips_data, showTrips: [index],vehicle,item }}
                           onContextMenu={(e) => {e.preventDefault(); }}
                        >
                  <Row gutter={[16,16]} style={{ margin:5 }}>
                    <Col span={8}>
                    <Text type='secondary'>Date</Text>
                    <Text style={{ display:'block' }}>{item.date}</Text>
                    </Col>
                    <Col span={8}>
                    <Text type='secondary'>{t("Duration")}</Text>
                    <Text style={{ display:'block' }}>{item.time[0]}</Text>
                    </Col>
                    <Col span={8}>
                    <Text type='secondary'>Distance</Text>
                    <Text style={{ display:'block' }}>{item.time[1]}</Text>
                    </Col>
                  </Row>
                  <Row gutter={[16,16]} style={{ marginLeft:5,marginTop:7 }}>
                  <Timeline
                    items={[
                      {
                        children: (
                        <Row gutter={[16,16]}>
                        <Col span={4}>{item.st[0]}</Col>
                        <Col span={20}>{item.address[0]}</Col>
                        </Row>),
                      },
                      {
                        dot:<EnvironmentOutlined/>,
                        children: (
                        <Row gutter={[16,16]}>
                        <Col span={4}>{item.st[1]}</Col>
                        <Col span={20}>{item.address[1]}</Col>
                        </Row>),
                      },
                    ]}
                  />
                  </Row></Link>
                  </Card>{" "}
                </div>
      </List.Item>
    )}
  />
        </Col>
        </Row>
    </>}
    
    </Content>
    </>
  );
}
export default HistoryTab;
