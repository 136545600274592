import { gql } from "@apollo/client";

const GET_ALERTS = gql`
  query Get_Alerts($_id: ID, $role: String, $user: ID) {
    getAlerts(_id: $_id, role: $role, user: $user) {
      _id
      name
      type
      status
      user{
        fname
        lname
      }
      activity{
        _id
      }
      inactivity{
        _id
      }
      geofence{
        _id
      }
      speed{
        _id
      }
      power{
        _id
      }
      idle{
        _id
      }
      crush{
        _id
      }
      towing{
        _id
      }
      createdAt
      createdBy
    }
  }
`;
const GET_ALERT = gql`
  query Get_Alert($_id: ID!, $role: String!) {
    getAlert(_id: $_id, role: $role) {
      _id
      name
      type
      createdAt
    }
  }
`;
const GET_GEOFENCE = gql`
  query getGeofence($_id: ID!) {
    getGeofence(_id: $_id) {
      condition
      coordinates
      vehicles{
          profile{
            serialNumber
            Model
            Marque
            Categorie
          }
        }
      }
    }
`;
const GET_ALERT_ACTIVITY = gql`
  query getAlert_activity($_id: ID!) {
    getAlert_activity(_id: $_id) {
      period
      limit
      option
      vehicle{
          profile{
            serialNumber
            Model
            Marque
            Categorie
          }
        }
      }
    }
`;
const GET_ALERT_IDLE = gql`
  query getAlert_idle($_id: ID!) {
    getAlert_idle(_id: $_id) {
      limit
      vehicle{
          _id
          profile{
            serialNumber
            Model
            Marque
            Categorie
          }
        }
      }
    }
`;
const GET_ALERT_SPEED = gql`
  query getAlert_speed($_id: ID!) {
    getAlert_speed(_id: $_id) {
      speedLimit
      limit
      vehicle{
          profile{
            serialNumber
            Model
            Marque
            Categorie
          }
        }
      }
    }
`;
const GET_ALERT_POWER = gql`
  query getAlert_power($_id: ID!) {
    getAlert_power(_id: $_id) {
      vehicle{
          profile{
            serialNumber
            Model
            Marque
            Categorie
          }
        }
      }
    }
`;
const GET_ALERT_CRUSH = gql`
  query getAlert_crush($_id: ID!) {
    getAlert_crush(_id: $_id) {
      vehicle{
          profile{
            serialNumber
            Model
            Marque
            Categorie
          }
        }
      }
    }
`;
const GET_ALERT_TOWING = gql`
  query getAlert_towing($_id: ID!) {
    getAlert_towing(_id: $_id) {
      vehicle{
          profile{
            serialNumber
            Model
            Marque
            Categorie
          }
        }
      }
    }
`;
export { GET_ALERTS, GET_ALERT, GET_GEOFENCE, GET_ALERT_ACTIVITY, GET_ALERT_SPEED, GET_ALERT_POWER,GET_ALERT_IDLE,GET_ALERT_CRUSH,GET_ALERT_TOWING };
