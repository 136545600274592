import React, { useRef } from "react";
import { MapContainer, TileLayer, FeatureGroup, useMap, LayerGroup,LayersControl } from "react-leaflet";
import "../../css/App.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import "../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import { EditControl } from "react-leaflet-draw";
export default function ZoneMap(props) {
  const mapRef = useRef(null);

  function SearchControl() {
    {
      const map = useMap();
      
      if (props.bounds.length > 0) {
        map.fitBounds(props.bounds);
        props.setbounds([])
      }
      }

    return null;
  }
  
  const handleCreate = (e) => {
    switch (e.layerType) {
      case "circle":
        props.creteshape(
          e.layerType,
          [Object.values(e.layer._latlng)],
          Math.round(e.layer._mRadius)
        );
        break;
      case "polygon":
      case "rectangle":
        const coordinates = e.layer._latlngs[0].map((ele) => {
          return Object.values(ele);
        });
        props.creteshape(e.layerType, coordinates);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <MapContainer
        updateInterval={100}
        ref={mapRef}
        center={[51.505, -0.09]}
        zoom={1.5}
        scrollWheelZoom={true}
        style={{ height: "400px", width: "100wh", borderRadius: 10 }}
      >
        <LayersControl position="topright">
          <LayerGroup>
            <LayersControl.BaseLayer checked name="Simple">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Google Hybrid">
              <TileLayer url="https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Google Streets">
              <TileLayer url="https://mt0.google.com/vt/lyrs=m&x&x={x}&y={y}&z={z}" />
            </LayersControl.BaseLayer>
          </LayerGroup>
        </LayersControl>
        <SearchControl />
        <FeatureGroup>
          <EditControl
            position="topright"
            onCreated={(e) => {
              handleCreate(e);
            }}
            draw={{
              rectangle: false,
              circle: false,
              circlemarker: false,
              marker: false,
              polyline: false,
              polygon: true,
            }}
            edit={{
              edit: false,
              remove: true,
            }}
          />
        </FeatureGroup>
      </MapContainer>
    </>
  );
}
