import React, { useState , useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom"
import { useMutation, useQuery } from '@apollo/client';
import { VERIFY_INVITATION } from '../../queries/UserQuery';
import { SIGNUP } from '../../mutations/userMutations';
import { useDispatch } from 'redux-react-hook';
import * as actions from '../../constants/action_types';
import { Button, Form, Input,Divider,Typography } from "antd";
import { Layout,Card } from "antd";
import '../../css/auth.css'
import Loading from '../../components/Loading/Loading';
import axios from "axios";
const { Content,Header } = Layout;
const { Title, Text } = Typography;

function Signup() {
    const { t } = useTranslation();
    let params = useParams();
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { loading:loadingv,error:errorv } = useQuery(VERIFY_INVITATION, {
      variables: { invitationToken:params.token },
    });
  const [signup, { error }] = useMutation(SIGNUP);
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  console.log("from signup : ", subscription);

    const handleClick = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 5000); // 5 seconds delay
    };

    const submit = async () => {
      form
      .validateFields()
      .then(async (values) => {
          let { data } = await signup({
            variables: {
              fname: values.fname,
              lname: values.lname,
              password:values.password,
              invitationToken:params.token,
            },
          });
          const { _id,email, token,role,company,workspace } = data.signup;
          const workspaces = workspace.map((ele) => ele._id);
           role.name==="Admin"?localStorage.setItem("nameworkspace", company.name):localStorage.setItem("nameworkspace", workspace[0]&&workspace[0].name);
            dispatch({
              type: actions.SET_AUTH_USER,
              authUser: Object.freeze({
                _id,
                email: email,
                role: role.name,
                company: company ? company._id : null,
                companyname: company.name ? company.name : null,
                workspaces: role.name==="Admin"?[]: workspaces?.length > 0 ? workspaces[0] : workspaces,
                fullworkspaces: workspace,
                allworkspaces: workspaces,
              }),
            });
            role.name==="Admin"?localStorage.setItem("worckID", JSON.stringify([])):localStorage.setItem('worckID', (workspaces.length > 0) ? workspaces[0]:JSON.stringify([]));
        localStorage.setItem('token', token);
        if (subscription) { 

          // Send the token to your server
          const requestBody = {
            query: `
            mutation {
              updateUserSub(subscriptions: {
                endpoint: "${subscription.endpoint}",
                expirationTime: ${subscription.expirationTime ? `"${subscription.expirationTime}"` : null},
                keys: {
                  p256dh: "${subscription.keys.p256dh}",
                  auth: "${subscription.keys.auth}"
                  }
                  }, _id: "${_id}") {
                    _id
                    }
                    }
                    `,
                  };
                  
                  const { data1 } = await axios.post(
                    "/graphql",
                    requestBody
                  );
                  
                }
            navigate("/map");
      })
      .catch((info) => {
        setLoading(false);
        console.log("Validate Failed:", info);
      });
        }
    if(loadingv) return <Loading/>;;
    return (
        <>
                <Header className='loginHeader'>
        <div className="nav-wrapper w-container">
        <div className="nav-logo">
            <a href="/" className="link-block-header-logo w-inline-block w--current"><Title level={4}>DeviceSpeak</Title></a>
          </div>
        </div>
        </Header>
    <Content className='PassContent'
          style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: "600px",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
        <Card className="Authcard"  bordered={true}   style={{
          textAlign: "center",
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
  }} >
        <Title level={4}>{t("Welcome")}</Title>
        <Divider />
        <Form
        layout="vertical"
        form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={submit}
    >
            <Form.Item
            name="fname"
            label={t("FormeAddUser.fname")}
            rules={[
              {
                required: true,
                message: t("FormValidations.38"),
              },
            ]}
          >
            <Input className='authInput' />
          </Form.Item>
          <Form.Item
            name="lname"
            label={t("FormeAddUser.lname")}
            rules={[
              {
                required: true,
                message: t("FormValidations.39"),
              },
            ]}
          >
            <Input className='authInput' />
          </Form.Item>
          <Form.Item
            name="password"
            label={t("Password")}
            rules={[
              {
                required: true,
                message: t("FormValidations.17"),
              },
            ]}
          >
            <Input.Password style={{ width: "100%",marginBottom:24}} />
          </Form.Item>

            <Form.Item>
              <Button block ghost
                style={{
                  textAlign: "center",
                  width: "100%",
                  borderRadius: "5px",

                }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                onClick={handleClick}
              >
                Sign up
              </Button>
            </Form.Item>
            {errorv&&<Text type='danger'>Unable to get invitation. Please try again</Text>}
            {error&&<Text type='danger'>{error.message}</Text>}
            </Form>
          <div style={{ marginTop:"20px",textAlign:"initial"}}>
          <Text type="secondary" style={{ fontSize:"12px" }}>{t("What")}</Text>
          </div>
        </Card>
      </Content>
        </>
    )
}

export default Signup;