import { gql } from "@apollo/client";

const GET_VEHICLES = gql`
  query Get_Vehicles($_id: ID, $role: String, $workspace: [ID]) {
    getVehicles(_id: $_id, role: $role, workspace: $workspace) {
      _id
      longitude
      latitude
      status
      IgnitionStatus
      address
      hourMeter
      odometer
      Source
      assetID
      speed
      updatedAt
      profile {
        Model
        Categorie
        serialNumber
        Marque
        Fuel
        createdAt
      }
      workspace{
        name
      }
      zone{
        _id
      }
      company{
        name
      }
      createdBy
    }
  }
`;
const GET_VEHICLESBYZONE = gql`
  query Get_VehiclesByzone($_id: ID, $zone: ID) {
    getVehiclesByzone(_id: $_id, zone: $zone) {
      _id
      longitude
      latitude
      status
      IgnitionStatus
      address
      hourMeter
      odometer
      Source
      assetID
      updatedAt
      profile {
        Model
        Categorie
        serialNumber
        Marque
        Fuel
        usage
        CstHora
        createdAt
      }
    }
  }
`;
const GET_VEHICLES_FOR_ADMINISTRATION_PAGE = gql`
  query Get_Vehicles($_id: ID, $role: String, $workspace: [ID]) {
    getVehicles(_id: $_id, role: $role, workspace: $workspace) {
      _id
      profile {
        serialNumber
        Model
      }
    }
  }
`;
const GET_VEHICLES_FOR_Maintenance_PAGE = gql`
  query Get_Vehicles($_id: ID, $role: String, $workspace: [ID]) {
    getVehicles(_id: $_id, role: $role, workspace: $workspace) {
      _id
      hourMeter
      odometer
      profile {
        serialNumber
        Model
      }
    }
  }
`;
const GET_VEHICLE = gql`
  query Get_Vehicle($_id: ID!) {
    getVehicle(_id: $_id) {
      _id
      Source
      assetID
      longitude
      latitude
      status
      address
      hourMeter
      odometer
      createdAt
      updatedAt
      GPSTime
      GPRS_Signal
      sat_tracked
      immoStatus
      speed
      batteryLevel
      createdBy
      profile {
        Model
        Marque
        Categorie
        serialNumber
        Fuel
        usage
        CstHora
        CstOdo
      }
      workspace {
        _id
        name
      }
    }
  }
`;
const GET_HISTORY = gql`
 query Get_History($vehicle:ID!) {
getHistory(vehicle:$vehicle){
  _id
  longitude
  latitude
  vehicleStatus
  hourMeter
  odometer
  address
  createdAt
  }
        
 }
`;
// const GET_HISTORYBYPERIOD = gql`
//   query Get_HistoryByPeriod($vehicle: ID!, $period: String,$pageSize:Int,$skip:Int) {
//     getHistoryByPeriod(vehicle: $vehicle, period: $period,pageSize:$pageSize,skip:$skip) {
//       bearings
//       trips{
//         _id
//         longitude
//         latitude
//         vehicleStatus
//         hourMeter
//         odometer
//         address
//         createdAt
//         updatedAt
//       }
//     }
//   }
// `;
// const GET_HISTORYBYPERIOD = gql`
//   query Get_HistoryByPeriod($vehicle: ID!, $period: String,$pageSize:Int,$skip:Int) {
//     getHistoryByPeriod(vehicle: $vehicle, period: $period,pageSize:$pageSize,skip:$skip) {
//       longitude
//       latitude
//       vehicleStatus
//       hourMeter
//       odometer
//       speed
//       GPRS_Signal
//       sat_tracked
//       address
//       createdAt
//     }
//   }
// `;
const GET_HISTORYBYPERIOD = gql`
  query Get_HistoryByPeriod($vehicle: ID!, $period: String) {
    getTrip_HistoryByPeriod(vehicle: $vehicle, period: $period) {
      bearingsarr1
      trips_data{
       longitude
       latitude
       vehicleStatus
       createdAt
      }
      start_time
      end_time
      start_address
      end_address
      duration
      distance
      allpoint
    }
  }
`;
const GET_HISTORYBYDATE = gql`
  query Get_HistoryByDate($vehicle: ID!, $period: [String]) {
    getTrip_HistoryByDate(vehicle: $vehicle, period: $period) {
      trips {
        bearingsarr1
        trips_data {
          longitude
          latitude
          vehicleStatus
          createdAt
        }
        start_time
        end_time
        start_address
        end_address
        duration
        distance
        allpoint
      }
      totalTime
      max_speed
      avg_moy_speed
      totalDistance
      stop_time
    }
  }
`;
// const GET_HISTORYBYDATE = gql`
//   query Get_HistoryByDate($vehicle: ID!, $period: [String]) {
//     getHistoryByDate(vehicle: $vehicle, period: $period) {
//       bearingsarr1
//       trips_data{
//         _id
//        longitude
//        latitude
//        vehicleStatus
//        hourMeter
//        odometer
//        speed
//        GPRS_Signal
//        sat_tracked
//        address
//        createdAt
//       }
//     }
//   }
// `;
const GET_DURATION = gql`
  query Get_Duration($vehicle: ID!, $status: String) {
    getduration(vehicle: $vehicle, status: $status) 
  }
`;
const GET_HISTORYJSON = gql`
 query Get_History($vehicle:ID!) {
getHistory(vehicle:$vehicle){
    _id
    longitude
    latitude
    status
    address
    createdAt
    valueBatt 
    batteri_connecte 
    status_M 
    gps_signal 
    gprs_signal 
    vibmoteur 
    reset 
    GPS_time 
  }
        
 }
`;
const GET_UTILISATION = gql`
  query Get_Utilisation($vehicle: ID!) {
    getUtilisations(vehicle: $vehicle) {
      date
      utilisation
      utilisationKM
    }
  }
`;
const GET_LASTUTILISATION = gql`
  query Get_Lastutilisation($_id: ID!) {
    getLastUtilisation(_id: $_id) {
      date
      utilisation
    }
  }
`;
const ADD_GEOFENCE = gql`
  mutation CreateGeofence(
    $name: String
    $coordinates: [[Float!]!]
    $radius: Float
    $vehicles: ID
    $type: String
  ) {
    createGeofence(
      name: $name
      coordinates: $coordinates
      vehicles: $vehicles
      type: $type
      radius: $radius
    ) {
      _id
      name
      type
    }
  }
`;
export {
  GET_VEHICLES,
  GET_VEHICLE,
  ADD_GEOFENCE,
  GET_HISTORY,
  GET_LASTUTILISATION,
  GET_UTILISATION,
  GET_HISTORYJSON,
  GET_VEHICLES_FOR_ADMINISTRATION_PAGE,
  GET_VEHICLES_FOR_Maintenance_PAGE,
  GET_HISTORYBYPERIOD,
  GET_HISTORYBYDATE,
  GET_VEHICLESBYZONE,
  GET_DURATION
};
