import { gql } from "@apollo/client";

const ADD_WORKSPACE = gql`
  mutation CreateWorkspace(
    $name: String!
    $company: ID!
    $user: [ID]
    $vehicle: [ID]
    $role: String
  ) {
    createWorkspace( name: $name company: $company user: $user vehicle: $vehicle role: $role ) {
      _id
      name
    }
  }
`;
const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace(
    $name: String!
    $_id: ID
    $user: [ID]
    $vehicle: [ID]
    $role: String
  ) {
    updateWorkspace( name: $name _id: $_id user: $user vehicle: $vehicle role: $role ) {
      _id
      name
    }
  }
`;
const DELETE_WORKSPACE = gql`
  mutation deleteWorkspace(
    $_id: ID!
    $role: String
  ) {
    deleteWorkspace( _id: $_id  role: $role ) {
      _id
      name
    }
  }
`;

export { ADD_WORKSPACE , UPDATE_WORKSPACE, DELETE_WORKSPACE};
