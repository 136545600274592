import { gql } from "@apollo/client";

const ADD_ALERT = gql`
  mutation createAlert(
    $name: String!
    $limit: Int!
    $period: [String]
    $type: String!
    $user: [ID]
    $vehicle: [ID]
    $role: String
    $option: String
    $company: ID
    $createdBy:ID
  ) {
    createAlert_activity(
      name: $name
      limit: $limit
      period: $period
      type: $type
      user: $user
      vehicle: $vehicle
      role: $role
      option:$option
      company: $company
      createdBy: $createdBy
    ) {
      _id
    }
  }
`;
const ADD_ALERT_IDLE = gql`
  mutation createAlert_idle(
    $name: String!
    $limit: Int!
    $type: String!
    $user: [ID]
    $vehicle: [ID]
    $role: String
    $company: ID
    $createdBy:ID
  ) {
    createAlert_idle(
      name: $name
      limit: $limit
      type: $type
      user: $user
      vehicle: $vehicle
      role: $role
      company: $company
      createdBy: $createdBy
    ) {
      _id
    }
  }
`;
const ADD_ALERT_SPEED = gql`
  mutation createAlert_speed(
    $name: String!
    $speedLimit:Int
    $limit: Int!
    $type: String!
    $user: [ID]
    $vehicle: [ID]
    $role: String
    $company: ID
    $createdBy:ID
  ) {
    createAlert_speed(
      name: $name
      limit: $limit
      speedLimit: $speedLimit
      type: $type
      user: $user
      vehicle: $vehicle
      role: $role
      company: $company
      createdBy: $createdBy
    ) {
      _id
    }
  }
`;
const ADD_ALERT_POWER = gql`
  mutation createAlert_power(
    $name: String!
    $type: String!
    $user: [ID]
    $vehicle: [ID]
    $role: String
    $company: ID
    $createdBy:ID
  ) {
    createAlert_power(
      name: $name
      type: $type
      user: $user
      vehicle: $vehicle
      role: $role
      company: $company
      createdBy: $createdBy
    ) {
      _id
    }
  }
`;
const ADD_ALERT_TOWING = gql`
  mutation createAlert_towing(
    $name: String!
    $type: String!
    $user: [ID]
    $vehicle: [ID]
    $role: String
    $company: ID
    $createdBy:ID
  ) {
    createAlert_towing(
      name: $name
      type: $type
      user: $user
      vehicle: $vehicle
      role: $role
      company: $company
      createdBy: $createdBy
    ) {
      _id
    }
  }
`;
const ADD_ALERT_CRUSH = gql`
  mutation createAlert_crush(
    $name: String!
    $type: String!
    $user: [ID]
    $vehicle: [ID]
    $role: String
    $company: ID
    $createdBy:ID
  ) {
    createAlert_crush(
      name: $name
      type: $type
      user: $user
      vehicle: $vehicle
      role: $role
      company: $company
      createdBy: $createdBy
    ) {
      _id
    }
  }
`;
const UPDATE_ALERT = gql`
  mutation updateAlert(
    $name: String
    $limit: Int
    $period: [String]
    $type: String
    $user: [ID]
    $vehicle: [ID]
    $role: String
  ) {
    updateAlert(
      name: $name
      limit: $limit
      period: $period
      type: $type
      user: $user
      vehicle: $vehicle
      role: $role
    ) {
      _id
      name
    }
  }
`;
const UPDATE_ALERT_IDLE = gql`
  mutation updateAlert_idle(
    $name: String
    $limit: Int
    $type: String
    $user: [ID]
    $vehicle: [ID]
    $role: String
  ) {
    updateAlert_idle(
      name: $name
      limit: $limit
      type: $type
      user: $user
      vehicle: $vehicle
      role: $role
    ) {
      _id
      name
    }
  }
`;
const DELETE_ALERT = gql`
  mutation deleteAlert($_id: ID!, $role: String) {
    deleteAlert(_id: $_id, role: $role) {
      _id
      name
    }
  }
`;
const ACTIVATE_ALERT = gql`
  mutation ActivateAlert($_id: ID!) {
    ActivateAlert(_id: $_id) {
      _id
    }
  }
`;
const DISACTIVATE_ALERT = gql`
  mutation DisactivateAlert($_id: ID!) {
    DisactivateAlert(_id: $_id) {
      _id
    }
  }
`;

export { ADD_ALERT, UPDATE_ALERT, DELETE_ALERT,ACTIVATE_ALERT,DISACTIVATE_ALERT, ADD_ALERT_SPEED, ADD_ALERT_POWER,ADD_ALERT_IDLE,UPDATE_ALERT_IDLE, ADD_ALERT_TOWING,ADD_ALERT_CRUSH };
