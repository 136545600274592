import { React, useState,useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from '@apollo/client';
import { ValueContext } from "../context/Context";
import { Input, Typography, Button, Card, Layout, Space,Row,Col,Tabs,List } from "antd";
import MapLabel from "../components/map/map";
import CustomEmpty from "../components/Multiple/CustomEmpty";
import { useQuery } from "@apollo/client";
import { GET_VEHICLES } from "../queries/mapQuery";
import { SearchOutlined} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../css/Map.css";
import dayjs from "dayjs";
import Loading from "../components/Loading/Loading";
import Status from "../components/Multiple/Status";
import { io } from "socket.io-client";
// import OneSignal from "react-onesignal";

// OneSignal.init({ appId: "43265b18-7603-4d5b-959d-ac1e4e7f412b" });
const { Content } = Layout;
const { Text } = Typography;
function Map(props) {
  let { t } = useTranslation();
  let client = useApolloClient();
  let { isDesktop, isTablet } = useContext(ValueContext);
  let [searchInput, setSearchInput] = useState("");
  let [lat, setLat] = useState("");
  let [long, setLong] = useState("");
  let [model, setmodel] = useState("");
  let [activeTab, setActiveTab] = useState('2');
  let { company, role,workspaces } = props.props;
  let { loading, error, data,refetch } = useQuery(GET_VEHICLES, {
    variables: { _id: company, role: role ,workspace:workspaces },fetchPolicy: "no-cache",
  });
  const handleButtonClick = () => {
    setActiveTab('2'); 
  };
  const Zoom = (lat,long,model) => {
    setLat(lat);
    setLong(long);
    setmodel(model);
    handleButtonClick()
  };
  let socket = io({ path: "/socket.io", transports: ["websocket"] });
  useEffect(() => {
    if (socket) {
      socket.on(company+'updateVehicle', (updatedVehicle) => {
      //   let updatedVehicles = data.getVehicles&&data.getVehicles.map((vehicle) =>
      //   vehicle._id === updatedVehicle._id ? updatedVehicle : vehicle
      // );
      //   // update the data in the Apollo cache
      //   client.writeQuery({
      //     query: GET_VEHICLES,
      //     variables: { _id: company, role: role, workspace: workspaces },
      //     data: { getVehicles: updatedVehicles },
      //   });
      refetch();
      });
    }
    return () => {
      if (socket) {
        socket.off(company+'updateVehicle');
      }
    };
  // eslint-disable-next-line no-undef
  }, [socket, data, client, company, role, workspaces]);
  // useEffect(() => {
  //   // Assuming OneSignal is already initialized
  //   OneSignal.getUserId().then((userId) => {
  //     if (userId) {
  //       console.log(userId);
  //     }
  //   });
  // }, []);
  function requestNotificationPermission() {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // Create a notification
        new Notification("Hello, world!");
      } else {
        console.log("Notification permission denied.");
      }
    });
  }
  // requestNotificationPermission();
  if (loading) return <Loading />;
  if (error) return <></>;
  let filteredData=[]
  if (data.getVehicles.length > 0) {
    filteredData = data.getVehicles.filter((vehicle) => {
     //if no input the return the original
     if (searchInput === "") {
       return vehicle;
     }
     //return the vehicle which contains the user input
     else {
       return vehicle.profile.Model.toLowerCase().includes(searchInput.toLowerCase());
     }
   });

  }

   
  return (
    <Content className="InvContent">
        {isDesktop ? (<Row>
        <Col sm={24} md={8} >
    <List
    header={
      <Row>
    <Col style={{ marginLeft:10,marginTop:10 }}>
    <Input
                className="SearchBar" 
                size="default"
                placeholder={t("SearchInput")+" ("+filteredData.length+t("elements")+")"}
                prefix={<SearchOutlined />}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
    </Col>            
    </Row>
    }
    itemLayout="horizontal"
    className="MapList"
    dataSource={filteredData}
    renderItem={(item, index) => (
      <List.Item>
       <div
              key={
                item.profile.serialNumber
              }
              className="mydivouter"
            >
              <Card
                style={{ width: "100% ", height: "100%" }}
                onClick={async () => {
                  await Zoom(item.latitude, item.longitude, item.profile.Model);
                      setLat("");
                      setLong("");
                      setmodel("")
                }}
              >
                <Space size="small">
                  <Text strong>{item.profile.Categorie}</Text>
                  <Text>{item.profile.serialNumber}</Text>
                </Space>
                <div>{item.status!==""&&<Status status={item.status} />}</div>
                <Space size="small">
                  <Text>{item.profile.Marque}</Text>
                  <Text>{item.profile.Model}</Text>
                </Space>
                <div>
                {item.address!==""&&<Text type="secondary" style={{ width:"70%" }} ellipsis={{ rows:1 }}>
                  {item.address.length>40?item.address:item.address}
                </Text>}
                </div>
                  <Link
                    to={{ pathname: "/profile" }}
                  state={{ vehicle: item, path: "/map" }}
                  onContextMenu={(e) => {e.preventDefault(); }}
                  >
                <Button>
                    {t("Details")}
                </Button>
                  </Link>
                {item.address!==""&&<div style={{ 
                  position: "absolute",
                  zIndex: 2,
                  bottom: "0px",
                  right: "10px",
                 }}>
                  <Text type="secondary">
                    {dayjs(
                      Math.floor(item.updatedAt / 1000) * 1000
                    ).format("DD/MM/YYYY HH:mm")}
                  </Text>
                </div>}
              </Card>{" "}
            </div>
      </List.Item>
    )}
  />
        </Col>
        <Col sm={24} md={16}>
        <MapLabel
            data={data}
            error={error}
            loading={loading}
            lat={lat}
            long={long}
            model={model}
            isDesktop={isDesktop}
            isTablet={isTablet}
          />
        </Col>
    </Row>):(<>
              <MapLabel
                      data={data}
                      error={error}
                      loading={loading}
                      lat={lat}
                      long={long}
                      model={model}
                      isDesktop={isDesktop}
                      isTablet={isTablet}
                    />
            </>)}
    </Content>
  );
}
export default Map;
