import { gql } from "@apollo/client";

const GET_NOTIFICATIONS = gql`
  query Get_Notifications($_id: ID, $role: String!, $user: ID) {
    getNotifications(_id: $_id, role: $role, user: $user) {
      _id
      name
      categorie
      marque
      status
      source
      nameAlert
      typeAlert
      model
      speed
      createdAt
      alert{
        type
        name
        geofence{
          _id
        }
      }
    }
  }
`;
const GET_NOTREEDNOTIFICATIONS = gql`
  query Get_NotReedNotifications($_id: ID, $role: String!, $user: ID) {
    getNotReedNotifications(_id: $_id, role: $role, user: $user) 
  }
`;
const GET_NOTREEDMAINTNOTIFICATIONS = gql`
  query Get_NotReedMaintNotifications($_id: ID, $role: String!, $user: ID) {
    getNotReedMaintNotifications(_id: $_id, role: $role, user: $user)
  }
`;
const GET_NOTIFICATION = gql`
  query Get_Notification($_id: ID!, $role: String!) {
    getNotification(_id: $_id, role: $role) {
      _id
      name
      categorie
      marque
      status
      createdAt
    }
  }
`;
export { GET_NOTIFICATIONS, GET_NOTIFICATION, GET_NOTREEDNOTIFICATIONS,GET_NOTREEDMAINTNOTIFICATIONS };
