import { React } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Input, Layout } from "antd";
import { Card, Divider, Select,notification,Form,Button,Space,Row,Col,Avatar } from "antd";
import "../css/Profile.css";
import { UPDATE_USER } from "../mutations/userMutations";
import { GET_USER } from "../queries/UserQuery";
import Loading from "../components/Loading/Loading";
import UpdatePasswordForm from "../components/Forms/UpdatePasswordForm";
import { setDefaultConfig } from "antd-mobile";
import frLan from "../constants/fr-Lan";
import enUS from "antd-mobile/es/locales/en-US";
const { Content } = Layout;

function Profile(props) {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { _id } = props.props;
  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("language", value);
    dayjs.locale(value);
    if (value==='fr') {
      setDefaultConfig({
        locale: frLan,
      })
    } else {
      setDefaultConfig({
        locale: enUS,
      })
    }
  };
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    notification.open({
      description: t("Notification.8"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const submit = async () => {
    form
            .validateFields()
            .then((values) => {
              updateuser({
                variables: {
                  _id:  data.getUser._id,
                  email: data.getUser.email,
                  fname: values.fname,
                  lname: values.lname,
                  role: data.getUser.role._id,
                  company: data.getUser.company._id,
                  workspace: data.getUser.workspace.map((workspace) => {
                    return workspace._id;
                  }),
                },
              });
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
   };
  const { loading, data } = useQuery(GET_USER,{
    variables: { _id: _id },
    onError: (error) => {
      errorNotification(error.message);
    },
  });
  const [updateuser] = useMutation(UPDATE_USER, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_USER,
        variables: { _id: _id },
      },
    ],
  });
  if (loading) return <Loading/>
  return (
    <Content className="ProfContent">
            <Card bordered={true} style={{ 
                width: '100%',
                
                maxWidth: '800px',
                margin: '0 auto',
             }}>
              <Divider orientation="left">{t("Profile.UserInfo")}</Divider>
              <Row gutter={[16, 16]}>
              <Col style={{width:"100%"}} sm={24} md={24}>
              <Avatar
              size={{
                    xs:50,
                    lg: 80,
                    xl: 80,
                    xxl: 100,
                  }}
              style={{
                backgroundColor: 'white',
                borderColor:'#D8D8D8',
                color: 'black',
              }}
            >
              {data.getUser.fname[0].toUpperCase()+data.getUser.lname[0].toUpperCase()}
            </Avatar> 
                        </Col>
              <Col style={{width:"100%"}} sm={24} md={24}>
              <Form
                    form={form}
                    onFinish={async()=> {await submit()}}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                      email: data.getUser.email,
                      fname: data.getUser.fname,
                      lname: data.getUser.lname,
                    }}
               >
          <Form.Item
            name="fname"
            label={t("FormeAddUser.fname")}
            className="ProfileInput"
            rules={[
              {
                required: true,
                message: t("FormValidations.38"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lname"
            label={t("FormeAddUser.lname")}
            className="ProfileInput"
            rules={[
              {
                required: true,
                message: t("FormValidations.39"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("FormeAddUser.lableforemail")}
            className="ProfileInput"
            rules={[
              {
                type: "email",
                message: t("FormValidations.16"),
              },
              {
                required: true,
                message: t("FormValidations.15"),
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
                <Form.Item label=" ">
              <Space direction="horizontal">
                  <Button type="primary" ghost htmlType="submit">
                    {t("Alerts.10")}
                  </Button>
                  <Button onClick={()=>{form.resetFields();}}>{t("Alerts.9")}</Button>
              </Space>
                </Form.Item>
        </Form>
        </Col>
        </Row>
              <Divider orientation="left">{t("Password")}</Divider>
              <UpdatePasswordForm id={_id}/>
              <Divider orientation="left">{t("Profile.Language")}</Divider>
              <Select
                defaultValue={localStorage.getItem("language") ? localStorage.getItem("language"):"fr"}
                placement={"topLeft"}
                style={{
                  width: "200px",
                }}
                onChange={changeLanguage}
                options={[
                  {
                    value: "en",
                    label: "EN",
                  },
                  {
                    value: "fr",
                    label: "FR",
                  },
                ]}
              />
            </Card>
    </Content>
  );
}
export default Profile;
