import React, { createContext, useState } from "react";
import { useMediaQuery } from 'react-responsive';

export const ValueContext = createContext({
  value: null,
  setValue: () => {},
  valuemaint: null,
  setValuemaint: () => {},
  totalconso: 0,
  settotalconso: () => {},
  subscription: null,
  setSubscription: () => {},
});
export const ValueProvider = ({ children }) => {
  const [value, setValue] = useState(null);
  const [valuemaint, setValuemaint] = useState(null);
  const [totalconso, settotalconso] = useState(0);
  const [subscription, setSubscription] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return (
    <ValueContext.Provider value={{ value, setValue,valuemaint,setValuemaint,isDesktop,isMobile,isTablet,totalconso,settotalconso,subscription,setSubscription }}>
      {children}
    </ValueContext.Provider>
  );
};
