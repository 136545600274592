import React from 'react';
import { Empty } from "antd";


function CustomEmpty() {
    return (<Empty 
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <span>
         {localStorage.getItem('language')==='en' ? "No data available":"Aucune donnée disponible"}
        </span>
      }
    />)
}

export default CustomEmpty;