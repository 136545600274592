/* eslint-disable array-callback-return */
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button, Form, Modal, notification } from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_USERPASSWORD } from "../../mutations/userMutations";
function UpdatePasswordForm(props) {
  const { t } = useTranslation();
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    notification.open({
      description: t("Notification.9"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [updatePassword] = useMutation(UPDATE_USERPASSWORD, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
  });
 
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
       {t("ChangePassword")}
      </Button>
      <Modal
        open={open}
        title={t("ChangePassword")}
        okText={t("Alerts.10")}
        cancelText={t("Alerts.9")}
        style={{
          top: 20,
        }}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
                updatePassword({
                variables: {
                    _id:props.id,
                    current: values.CurrentPassword,
                    new: values.password,
                },
              });

              setOpen(false);

              form.resetFields();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="CurrentPassword"
            label={t("CurrentPassword")}
            rules={[
              {
                required: true,
                message: t("FormValidations.17"),
              },
            ]}
          >
            <Input.Password style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="password"
            label={t("NewPassword")}
            rules={[
              {
                required: true,
                message: t("FormValidations.17"),
              },
            ]}
            hasFeedback
          >
            <Input.Password style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={t("ConfirmPassword")}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: t("FormValidations.18"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("FormValidations.19")));
                },
              }),
            ]}
          >
            <Input.Password style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default UpdatePasswordForm;
