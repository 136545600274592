/* eslint-disable array-callback-return */
import { React, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Form,
  Modal,
  Select,
  notification,
  Switch,
  Space,
} from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { GET_VEHICLES_FOR_Maintenance_PAGE } from "../../queries/mapQuery";
import { GET_USERS_FOR_ADMINISTRATION,GET_ADMINUSER } from "../../queries/UserQuery";
import { CREATE_MAINTENANCE } from "../../mutations/maintenanceMutations";
import { GET_MAINTENANCES } from "../../queries/maintenanceQuery";
import { convertToHoursMins } from "../../constants/ConvertHora";
import Loading from "../Loading/Loading";
import { ValueContext } from "../../context/Context";
function CreateMaintenanceForm(props) {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ValueContext);
  const [Mainvehicle, setMainvehicle] = useState(null);
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    notification.open({
      description: t("Notification.6"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const { company, role, _id, workspaces } = props.props;
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const Echeance = Form.useWatch("echeance", form);
  const Echeance2 = Form.useWatch("echeance2", form);
  const [option, setOption] = useState("");
  const [showKlm, setShowKlm] = useState(false);
  const [showHr, setShowHr] = useState(false);
  const [user, setUser] = useState([_id]);
  const handleOptionChange = (option) => {
    setOption(option);
    setShowKlm(option === "klm");
    setShowHr(option === "hr");
  };
  const [getvehicles, { loading: loadingv, data: datav }] =
    useLazyQuery(GET_VEHICLES_FOR_Maintenance_PAGE, {
      variables: { _id: company, role: role,workspace:workspaces },fetchPolicy: "no-cache",
      onError: (error) => {
        errorNotification(error.message);
      },
    });
  const [getusers, { loading: loadingu, data: datau }] =
    useLazyQuery(GET_USERS_FOR_ADMINISTRATION, {
      variables: { _id: company, role: role, workspace:workspaces },fetchPolicy: "no-cache",
      onError: (error) => {
        errorNotification(error.message);
      },
    });
  const [getadmins, { loading: loadinga, data: dataa}] =
    useLazyQuery(GET_ADMINUSER, {
      variables: { _id: company, _iduser: _id },fetchPolicy: "no-cache",
    });
  const [createMaintenance] = useMutation(CREATE_MAINTENANCE, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_MAINTENANCES,
        variables:{user:_id,role:role},
      },
    ],
  });
  const vehicleoptions = [];
  datav &&
    datav.getVehicles.map((ele) => {
      vehicleoptions.push({
        value: ele._id,
        label: ele.profile.Model + " / " + ele.profile.serialNumber,
      });
    });
  const useroptions = [];
  datau &&
    datau.getUsers.map((ele) => {
      useroptions.push({
        value: ele._id,
        label: ele.email,
      });
    });
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
          getvehicles();
          getusers();
          getadmins();
        }}
      >
        {isDesktop? <div><PlusOutlined /> {t("Add")}</div>:<div><PlusOutlined /></div>}
      </Button>
      <Modal
        open={open}
        title={t("Maintenance.16")}
        okText={t("Alerts.10")}
        cancelText={t("Alerts.9")}
        style={{
          top: 20,
        }}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
          setMainvehicle(null);
          setOption("");
          setShowKlm(false);
          setShowHr(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              setUser(values.members);
              dataa.getAdminUser.map((element) => {
                if (!user.includes(element)) {
                  user.push(element);
                }
              });
              if (!user.includes(_id)) {
                user.push(_id);
              }
              createMaintenance({
                variables: {
                  name: values.name,
                  operations: values.operations,
                  echeance: option==="hr" ? values.echeance+"hr":option==="klm" ? values.echeance2+"km":"",
                  seuil: option==="hr" ? values.seuil:option==="klm" ? values.seuil2:"",
                  user: user,
                  vehicle: values.equipment,
                  createdBy: _id
                },
              });
              setOpen(false);
              form.resetFields();
              setOpen(false);
              setMainvehicle(null);
              setOption("");
              setShowKlm(false);
              setShowHr(false);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {loadingu || loadingv || loadinga ? <Loading/>:(<Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            operations: [""],
            members:[_id]
          }}
        >
          <Form.Item
            name="name"
            label={t("Formeworkspace.lableforname")}
            rules={[
              {
                required: true,
                message: t("Maintenance.8"),
              },
            ]}
          >
            <Input placeholder={t("Formeworkspace.lableforname")} />
          </Form.Item>
          <Form.List name="operations">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? t("Maintenance.6"): ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: t("Maintenance.9")
                    }
                  ]}
                  noStyle
                >
                  <Input
                    placeholder={t("Maintenance.7")+" n°"+(index+1)}
                    style={{
                      width: "85%",
                      marginBottom: "10px",
                    }}
                  />
                </Form.Item>
                {fields.length > 1 && index !== 0 ? (
                  <Button onClick={() => remove(field.name)}
                  style={{
                    width: '10%',
                    marginLeft : "10px",
  
                  }}
                  icon={<MinusOutlined />}></Button>
                ) : null}
                {index === 0 ? (
                  <Button
                    onClick={() => add()}
                    style={{
                      width: "10%",
                      marginLeft: "10px"
                    }}
                    icon={<PlusOutlined />}
                  ></Button>
                ) : null}
              </Form.Item>
            ))}
              <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
          <Form.Item
            name="equipment"
            label={t("Formeworkspace.lableforequipment")}
            rules={[
              {
                required: true,
                message: t("FormValidations.29"),
              },
            ]}
          >
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              placeholder={t("SelectSearch")}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={vehicleoptions}
              onSelect={async (value) => {
                let vehicle = await datav.getVehicles.filter(
                  (vehicle) => vehicle._id === value
                );
                setMainvehicle(vehicle[0]);
              }}
            />
          </Form.Item>
          <Form.Item
            name="members"
            label={t("Formeworkspace.lableformembers")}
            rules={[
              {
                required: true,
                message: t("FormValidations.30"),
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{
                width: "100%",
              }}
              placeholder={t("SelectSearch")}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={useroptions}
              onChange= {(newValue) => {setUser(newValue);}}
            />
          </Form.Item>
          <Form.Item 
          name="switch"
          label={t("Maintenance.21")}
            rules={[
              {
            validator: async () => {
              if ( option === "") {
                return Promise.reject(new Error(t("Maintenance.10")));
              }
            },
          },
            ]}
          >
            <div style={{ marginBottom:"10px" }}>
              <Space size={"small"}>
                <Switch
                  size="small"
                  checked={option === "hr"}
                  onChange={() => handleOptionChange("hr")}
                />
                <label htmlFor="hr">Temps d'usage:</label>
              </Space>
            </div>
            <div
              className="form-row"
              style={{ display: showHr ? "block" : "none" }}
            >
            <Form.Item>
              <Form.Item
                name="echeance"
                label={t("Maintenance.1")}
                rules={[
                  option==="hr"&&{
                    required: true,
                    message: t("Maintenance.11")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input type="number" addonAfter="hr" placeholder={t("Maintenance.1")} />
              </Form.Item>
              <Form.Item
                name="hactuel"
                label={t("Maintenance.2")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input
                  placeholder={Mainvehicle ? convertToHoursMins(Mainvehicle.hourMeter): "-"}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="seuil"
                label={t("Maintenance.3")}
                rules={[
                  option==="hr"&&{
                    required: true,
                    message: t("Maintenance.12")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input type="number" addonAfter="hr" placeholder={t("Maintenance.3")} />
              </Form.Item>
              <Form.Item
                name="restant"
                label={t("Maintenance.4")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input
                  placeholder={Mainvehicle ? Echeance ? convertToHoursMins(Echeance * 60 - Mainvehicle.hourMeter): "-": "-"}
                  disabled
                />
              </Form.Item>
            </Form.Item>
            </div>
            <div style={{ marginBottom:"10px" }}>
          <Space size={"small"}>
            <Switch
              size="small"
              checked={option === "klm"}
              onChange={() => handleOptionChange("klm")}
            />
            <label htmlFor="klm">Kilométrage:</label>
          </Space>
          </div>
          <div
            className="form-row"
            style={{ display: showKlm ? "block" : "none" }}
          >
            <Form.Item>
              <Form.Item
                name="echeance2"
                label={t("Maintenance.1")}
                rules={[
                  option==="klm"&&{
                    required: true,
                    message: t("Maintenance.11")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input type="number" addonAfter="km" placeholder={t("Maintenance.1")} />
              </Form.Item>
              <Form.Item
                name="kactuel"
                label={t("Maintenance.5")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input
                  placeholder={Mainvehicle ? Mainvehicle.odometer.toFixed(2)+" km": "-"}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="seuil2"
                label={t("Maintenance.3")}
                rules={[
                  option==="klm"&&{
                    required: true,
                    message: t("Maintenance.12")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input type="number" addonAfter="km" placeholder={t("Maintenance.3")} />
              </Form.Item>
              <Form.Item
                name="restant2"
                label={t("Maintenance.4")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input
                  placeholder={Mainvehicle&&Echeance2 ? ((Echeance2 - Mainvehicle.odometer)>0 ? (Echeance2 - Mainvehicle.odometer).toFixed(2):0)+" km": "-"}
                  disabled
                />
              </Form.Item>
            </Form.Item>
          </div>
          </Form.Item>
        </Form>)}
      </Modal>
    </>
  );
}
export default CreateMaintenanceForm;
