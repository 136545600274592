import { React,useContext } from "react";
import { Layout } from "antd";
import { Tabs, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../css/Alerts.css";
import Notification from "../../components/alerts/notifications";
import { ValueContext } from "../../context/Context";
const { Content } = Layout;
const { Title } = Typography;

function NotificationsTab(props) {
  const { t } = useTranslation();
  const { isDesktop} = useContext(ValueContext);
  const items = [
    {
      key: "1",
      label: (
        <Link to={{ pathname: "/alerts" }}>
          <div className="TabLink">{t("AlertTab.alert")}</div>
        </Link>
      ),
    },
    {
      key: "2",
      label: <div className="TabLink">{t("AlertTab.notification")}</div>,
      children: <Notification props={props.props} />,
    },
  ];
  return (
    <Content className="AlertContent">
    {isDesktop ? <><Title level={3}>{t("AlertTab.notification")}</Title><Tabs defaultActiveKey="2" items={items}  /></>:<Notification props={props.props} />}
    </Content>
  );
}
export default NotificationsTab;
