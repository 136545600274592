/* eslint-disable array-callback-return */
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Form,
  Modal,
  notification,
  Switch,
  Space,
  Descriptions,
  Col,
  Row,
} from "antd";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { CREATE_MAINTENANCE,VALIDATE_MAINTENANCE } from "../../mutations/maintenanceMutations";
import { GET_MAINTENANCES } from "../../queries/maintenanceQuery";
import { convertToHoursMins } from "../../constants/ConvertHora";
import MaintenanceStatus from "../Multiple/MaintenanceStatus";
function UpdateMaintenanceForm(props) {
  const { t } = useTranslation();
  const Maintenance= props.maintenance;
  const Mainvehicle=Maintenance.vehicle;
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    notification.open({
      description: t("Notification.6"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
    if (checked) {window.location.reload();}
  };
  const {  role,_id } = props.props;
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const Echeance = Form.useWatch("echeance", form);
  const Echeance2 = Form.useWatch("echeance2", form);
  const option = Maintenance.echeance.includes("hr") ? "hr" : "klm";
  const [checked, setChecked] = useState(null);
  const [checked2, setChecked2] = useState(null);
  const [showKlm, setShowKlm] = useState(false);
  const [showHr, setShowHr] = useState(false);
  const [showEtal, setShowEtal] = useState(false);
  const [odoEtal, setOdoEtal] = useState(null);
  const [horaEtal, setHoraEtal] = useState(null);
  const handleRecurrence = (checked) => {
    if(checked){
        setShowKlm(option === "klm");
        setShowHr(option === "hr");
        setChecked(checked);
    }else{
        setShowKlm(false);
        setShowHr(false);
        setChecked(null);
    }
  };
  const handleEtallonage = (checked) => {
    if(checked){
        setShowEtal(true);
        setChecked2(checked);
    }else{
        setShowEtal(false);
        setChecked2(null);
        setHoraEtal(null);
        setOdoEtal(null);
        form.resetFields(["odometer","hourMeter"])
    }
  };
  const [createMaintenance] = useMutation(CREATE_MAINTENANCE, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_MAINTENANCES,
        variables:{user:_id,role:role},
      },
    ],
  });
  const [validateMaintenance] = useMutation(VALIDATE_MAINTENANCE, {
    refetchQueries: [
      {
        query: GET_MAINTENANCES,
        variables:{user:_id,role:role},
      },
    ],
  });
  console.log(odoEtal);
  console.log(horaEtal);
  return (
    <>
      <Button
        type="text"
        block
        onClick={async() => {
          setOpen(true)
        }}
      >
        {t("Details")}
      </Button>
      <Modal
        open={open}
        okText={Maintenance.status!=="Validated"?t("Maintenance.19"):"Ok"}
        cancelText={t("Alerts.9")}
        okButtonProps={{
            style: {
                backgroundColor: "#38c172",
                borderColor: "#38c172",
                    },
                }}
        style={{
          top: 20,
        }}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
          handleRecurrence(false);
          handleEtallonage(false);
        }}
        onOk={() => {
         
            form
            .validateFields()
            .then(async (values) => {
              setOpen(false);
              if(Maintenance.status!=="Validated"){
                await validateMaintenance({ variables: { _id:Maintenance._id,hourMeter: parseInt(values.hourMeter),odometer:parseFloat(values.odometer),validatedby:_id } })
                }
                 if(checked){
                  await createMaintenance({
                variables: {
                  name: Maintenance.name,
                  operations: Maintenance.operations,
                  echeance: option==="hr" ? values.echeance+"hr":option==="klm" ? values.echeance2+"km":"",
                  seuil: option==="hr" ? values.seuil:option==="klm" ? values.seuil2:"",
                  user: Maintenance.user.map((ele)=>  ele._id),
                  vehicle: Maintenance.vehicle._id,
                  createdBy: _id
                },
              });}
              form.resetFields();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Descriptions labelStyle={{ color:"black" }} title={t("Description")} column={1}>
        <Row>
            <Col span={10}>{t("Formeworkspace.lableforname")}</Col>
            <Col>{Maintenance.name}</Col>
        </Row>
        <Row>
            <Col span={10}>{t("InventoryTable.Model")}</Col>
            <Col>{Mainvehicle.profile.Model}</Col>
        </Row>
        <Row>
            <Col span={10}>{t("InventoryTable.Category")}</Col>
            <Col>{Mainvehicle.profile.Categorie}</Col>
        </Row>
        <Row>
            <Col span={10}>{t("InventoryTable.SerialNumber")}</Col>
            <Col>{Mainvehicle.profile.serialNumber}</Col>
        </Row>
        <Row>
            <Col span={10}>{t("InventoryTable.Status")}</Col>
            <Col><MaintenanceStatus status={Maintenance.status}/></Col>
        </Row>
        <Row>
            <Col span={10}>{t("Maintenance.6")}</Col>
            <Col>{Maintenance.operations.map((operations,index) => {
                return operations+((index<(Maintenance.operations.length-1)) ? " , ":"")
            })}</Col>
        </Row>
        <Row>
            <Col span={10}>Date</Col>
            <Col>{dayjs(Math.floor(Maintenance.updatedAt / 1000) * 1000).format("DD MMM YYYY HH:mm")}</Col>
        </Row>
        </Descriptions>
        <Descriptions labelStyle={{ color:"black" }} title={t("Maintenance.21")} column={1}>
        <Row>
            <Col span={10}>{t("Maintenance.2")}</Col>
            <Col>{convertToHoursMins(Maintenance.vehicle.hourMeter)!=="0 h 0 min"?convertToHoursMins(Maintenance.vehicle.hourMeter):"-"}</Col>
        </Row>
        <Row>
            <Col span={10}>{t("Maintenance.5")}</Col>
            <Col> {parseFloat(Maintenance.vehicle.odometer)>0?parseFloat(Maintenance.vehicle.odometer).toFixed(2)+" Km":"-"}</Col>
        </Row>
        <Row>
            <Col span={10}>{t("Maintenance.1")}</Col>
            <Col>{Maintenance.echeance.includes("km") ? parseInt(Maintenance.echeance)+" km":parseInt(Maintenance.echeance)+" hr"}</Col>
        </Row>
        <Row>
            <Col span={10}>{t("Maintenance.3")}</Col>
            <Col>{option==="hr" ? Maintenance.seuil+" hr":option==="klm" ? Maintenance.seuil+" km":""}</Col>
        </Row>
        </Descriptions>

        {Maintenance.status!=="Validated"&&<Form
          form={form}
          layout="vertical"
          name="form_in_modal"
        >
        <Form.Item >
            <div style={{ marginBottom:"10px" }}>
            <Space size={"small"}>
                <Switch
                  size="small"
                  checked={checked2}
                  onChange={(checked) =>{
                    handleEtallonage(checked);
                  }}
                />
                <label htmlFor="klm">{t("Etallonage")}</label>
                </Space>
            </div>
            {showEtal&&(<>
              {option === "hr"&&(<Form.Item
            name="hourMeter"
            label={t("InventoryTable.Horametre")}
          >
            <Input type="number" addonAfter={t("FormValidations.10")} onChange={(e)=>{setHoraEtal(e.target.value*60)}} pattern="^[0-9]+$" />
          </Form.Item>)}
          {option === "klm"&&(<Form.Item
            name="odometer"
            label={t("InventoryTable.Odometre")}
          >
            <Input type="number" onChange={(e)=>{setOdoEtal(e.target.value)}} />
          </Form.Item>)}
            </>)}

          </Form.Item>
          <Form.Item >
            <div style={{ marginBottom:"10px" }}>
            <Space size={"small"}>
                <Switch
                  size="small"
                  checked={checked}
                  onChange={(checked) =>{
                    handleRecurrence(checked);
                  }}
                />
                <label htmlFor="klm">{t("Maintenance.20")}</label>
                </Space>
            </div>
            <div
              className="form-row"
              style={{ display: showHr ? "block" : "none" }}
            >
            <Form.Item>
              <Form.Item
                name="echeance"
                label={t("Maintenance.1")}
                rules={[
                  option==="hr"&&checked&&{
                    required: true,
                    message: t("Maintenance.11")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input addonAfter="hr" placeholder={t("Maintenance.1")} />
              </Form.Item>
              <Form.Item
                name="hactuel"
                label={t("Maintenance.2")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input
                  placeholder={!horaEtal ? convertToHoursMins(Mainvehicle.hourMeter) : convertToHoursMins(horaEtal)}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="seuil"
                label={t("Maintenance.3")}
                rules={[
                  option==="hr"&&checked&&{
                    required: true,
                    message: t("Maintenance.12")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input addonAfter="hr" placeholder={t("Maintenance.3")} />
              </Form.Item>
              <Form.Item
                name="restant"
                label={t("Maintenance.4")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input
                  placeholder={Echeance ? (!horaEtal ? (convertToHoursMins(Echeance * 60 - Mainvehicle.hourMeter)):convertToHoursMins(Echeance * 60 - horaEtal)): "-"}
                  disabled
                />
              </Form.Item>
            </Form.Item>
            </div>
          <div
            className="form-row"
            style={{ display: showKlm ? "block" : "none" }}
          >
            <Form.Item>
              <Form.Item
                name="echeance2"
                label={t("Maintenance.1")}
                rules={[
                  option==="klm"&&checked&&{
                    required: true,
                    message: t("Maintenance.11")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input addonAfter="km" placeholder={t("Maintenance.1")} />
              </Form.Item>
              <Form.Item
                name="kactuel"
                label={t("Maintenance.5")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input  placeholder={!odoEtal ? Mainvehicle.odometer.toFixed(2)+" km" : odoEtal+" km"} disabled />
              </Form.Item>
              <Form.Item
                name="seuil2"
                label={t("Maintenance.3")}
                rules={[
                  option==="klm"&&checked&&{
                    required: true,
                    message: t("Maintenance.12")
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input addonAfter="km" placeholder={t("Maintenance.3")} />
              </Form.Item>
              <Form.Item
                name="restant2"
                label={t("Maintenance.4")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input
                  placeholder={Echeance2 ? (!odoEtal ? ((Echeance2 - Mainvehicle.odometer)>0&&parseFloat(Echeance2 - Mainvehicle.odometer).toFixed(2)+" km"):(Echeance2 - odoEtal)>0&&(Echeance2 - odoEtal).toFixed(2)+" km"): "-"}
                  disabled
                />
              </Form.Item>
            </Form.Item>
          </div>
          </Form.Item>
        </Form>}
      </Modal>
    </>
  );
}
export default UpdateMaintenanceForm;
