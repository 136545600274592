/* eslint-disable array-callback-return */
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button, Form, Modal, Select, notification } from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";
import { UPDATE_USER } from "../../mutations/userMutations";
import { GET_USERS } from "../../queries/UserQuery";
import { GET_ROLES } from "../../queries/roleQuery";
import { GET_COMPANYS } from "../../queries/companyQuery";
import { GET_WORKSPACES } from "../../queries/workspaceQuery";
import Loading from "../Loading/Loading";
function UpdateMemberForm(props) {
  const { t } = useTranslation();
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    notification.open({
      description: t("Notification.4"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const { company, role, workspaces,companyname } = props.props;
  const User = props.user;
  const [open, setOpen] = useState(false);
  const [Work, setWork] = useState();
  const [extra_id, setextra_id] = useState([]);
  const [form] = Form.useForm();
  const [getroles, { loading: loadingR , data: dataR }] = useLazyQuery(GET_ROLES, {
    variables: { role: role },
  });
  const [getworkspace, { loading: loadingw , data: dataw }] = useLazyQuery(GET_WORKSPACES,{
    onError: (error) => {
      errorNotification(error.message);
    },
  });
  const [getCompanys, { loading, data }] = useLazyQuery(GET_COMPANYS,{
    onError: (error) => {
      errorNotification(error.message);
    },
  });
  const [updateuser] = useMutation(UPDATE_USER, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_USERS,
        variables: { _id: company, role: role, workspace: workspaces },
      },
    ],
  });
  const roleoptions = [];
  const roleoptions2 = [];
  dataR &&
    dataR.getRoles.map((ele) => {
      role === "Admin" || role === "Super Admin"
        ? ele.name !== "Super Admin" &&
          roleoptions.push({
            value: ele._id,
            label: t(ele.name),
          })
        : ele.name !== "Super Admin" &&
          ele.name !== "Admin" &&
          roleoptions.push({
            value: ele._id,
            label: t(ele.name),
          });
    });
    dataR &&
    dataR.getRoles.map((ele) => {
      ele.name === "Admin" &&
          roleoptions2.push({
            value: ele._id,
            label: t(ele.name),
          })
    });
  const companyoptions = [];
  role === "Super Admin" &&
    data &&
    data.getCompanys.map((ele) => {
      companyoptions.push({
        value: ele._id,
        label: ele.name,
      });
    });
    const workspaceoptions = [];
  dataw &&
    dataw.getWorkspaces.map((ele) => {
      workspaceoptions.push({
        value: ele._id,
        label: ele.name,
      });
    });
  return (
    <>
      <Button
        type="text"
        block
        onClick={async () => {
          setOpen(true);
          getroles();
          if (role === "Super Admin") {
            getCompanys({ variables: { role: role } });
          } else {
            company &&
              getworkspace({
                variables: { _id: company, role: role, workspace: workspaces },
              });
          }
        }}
      >
        {t("EditMember")}
      </Button>
      <Modal
        open={open}
        title={t("EditMember")}
        okText={t("Alerts.10")}
        cancelText={t("Alerts.9")}
        style={{
          top: 100,
        }}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
          console.log(extra_id);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              updateuser({
                variables: {
                  _id: User._id,
                  email: values.email,
                  fname: values.fname,
                  lname: values.lname,
                  role: values.role,
                  company: values.company ? values.company : company,
                  workspace: values.workspace ? (values.workspace.includes("All") ? ([...workspaceoptions.map(option => option.value), ...extra_id]):[...values.workspace, ...extra_id]): [],
                },
              });

              setOpen(false);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {loading || loadingR || loadingw ? (
          <Loading />
        ) : (
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              email: User.email,
              fname: User.fname,
              lname: User.lname,
              role: User.role._id,
              company: User.company._id,
              workspace:
                role === "AdminWorkspace"
                  ? User.workspace
                      .map((workspace) => {
                        if (workspaces.includes(workspace._id)) {
                          return workspace._id;
                        } else {
                          !extra_id.includes(workspace._id) &&
                            extra_id.push(workspace._id);
                        }
                      })
                      .filter((workspaceId) => workspaceId)
                  : User.workspace.map((workspace) => workspace._id),
            }}
          >
            <Form.Item
              name="email"
              label={t("FormeAddUser.lableforemail")}
              rules={[
                {
                  type: "email",
                  message: t("FormValidations.16"),
                },
                {
                  required: true,
                  message: t("FormValidations.15"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="fname"
              label={t("FormeAddUser.fname")}
              rules={[
                {
                  required: true,
                  message: t("FormValidations.38"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lname"
              label={t("FormeAddUser.lname")}
              rules={[
                {
                  required: true,
                  message: t("FormValidations.39"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            
            {role !== "Super Admin" && (
              <Form.Item
                name="workspace"
                label={t("FormeAddUser.lableforworkspace")}
                rules={[
              {
                required: true,
                message: t("FormValidations.41"),
              },
            ]}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e)=>{
                  setWork(e);
                  form.resetFields(['role'])
                  }}
                  options={[{value: "All", label: companyname+" ("+t("Allspace")+") "},...workspaceoptions]}
                />
              </Form.Item>
            )}
            <Form.Item
              name="role"
              label={t("FormeAddUser.lableforrole")}
              rules={[
                {
                  required: true,
                  message: t("FormValidations.2"),
                },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                options={Work&&Work[0]==="All" ? roleoptions2:roleoptions}
              />
            </Form.Item>
            {role === "Super Admin" && (
              <Form.Item
                name="company"
                label={t("FormeAddUser.lableforcompany")}
                rules={[
                  {
                    required: true,
                    message: t("FormValidations.2"),
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  options={companyoptions}
                />
              </Form.Item>
            )}
            
          </Form>
        )}
      </Modal>
    </>
  );
}
export default UpdateMemberForm;
